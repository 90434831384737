import {
  IonButton,
  IonItem,
  IonIcon,
  IonReorderGroup,
  IonReorder,
} from "@ionic/react";
import { trashOutline } from "ionicons/icons";

const AppNodeSelector = ({ value = [], onChange = (e) => {} }) => {
  const doReorder = (event) => {
    const newValue = event.detail.complete(value);
    onChange(
      new CustomEvent("nodeSelectorChange", { detail: { value: newValue } })
    );
  };

  const doRemove = (index) => {
    // Copy value and remove item.
    const newValue = [...value];
    newValue.splice(index, 1);
    onChange(
      new CustomEvent("nodeSelectorChange", { detail: { value: newValue } })
    );
  };

  return (
    <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
      {value.map((item, index) => (
        <IonItem key={index}>
          <IonReorder slot="start" />
          {item.title}
          <IonButton slot="end" fill="clear" onClick={() => doRemove(index)}>
            <IonIcon slot="icon-only" icon={trashOutline} />
          </IonButton>
        </IonItem>
      ))}
    </IonReorderGroup>
  );
};

export default AppNodeSelector;

import { Fragment, useEffect, useRef } from "react";
import { useParams } from "react-router";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonContent,
  IonTitle,
  useIonRouter
} from "@ionic/react";
import dayjs from "dayjs";

import { useMessagesProvider } from "../../providers/messages-provider";
import {
  AppPageWithSheet,
  AppSheet,
} from "../../compositions/AppPageWithSheet";
import MessageBox from "../../components/AppMessageBox";
import MessageBoxExercise from "../../components/AppMessageBoxExercise";
import MessageBoxGallery from "../../components/AppMessageBoxGallery";
import BottomSpacer from "../../components/BottomSpacer";
import AppTimestamp from "../../components/AppTimestamp";

const ClientMessageThread = () => {
  const { client_id, id } = useParams();
  const { Provider, messages } = useMessagesProvider(client_id, id, "coach");
  const content = useRef(null);
  const router = useIonRouter();

  useEffect(() => {
    content.current?.scrollToBottom();
  }, [messages]);

  return (
    <IonPage>
      <IonHeader className="app-header" translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref={`/c/menu/manage/client/${client_id}/messages`}
            ></IonBackButton>
          </IonButtons>
          <IonTitle>Meddelanden</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true} ref={content}>
        <AppPageWithSheet>
          <AppSheet>
            {Object.values(messages || {}).map((message, i, array) => {
              const posts = [];
              if (i === 0 || array[i].posted - array[i - 1].posted > 3600000) {
                posts.push(
                  <AppTimestamp key={`${message.key}-timestamp`}>
                    {dayjs(message.posted).format("D MMMM YYYY - HH:mm")}
                  </AppTimestamp>
                );
              }
              if (message.type === "message_workout") {
                posts.push(<MessageBoxExercise onClick={() => router.push(message.ref_link)} key={`${message.key}-workout`}
                  modifiers={message.is_author ? "sending" : "recieving"}
                  title={message.ref_text}
                ></MessageBoxExercise>)
              } else if (message.type === "message_gallery") {
                posts.push(<MessageBoxGallery onClick={() => router.push(message.ref_link)} key={`${message.key}-workout`}
                  modifiers={message.is_author ? "sending" : "recieving"}
                  id={message.ref_id}
                ></MessageBoxGallery>)
              }
              const Message = () => <MessageBox
                modifiers={message.is_author ? "sending" : "recieving"}
              >{message.message}</MessageBox>;
              posts.push(<Message key={message.key} />);
              return <Fragment key={message.key}>{posts}</Fragment>;
            })}
            <BottomSpacer />
          </AppSheet>
        </AppPageWithSheet>
      </IonContent>
      <Provider />
    </IonPage>
  );
};

export default ClientMessageThread;

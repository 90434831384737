import AppContent from "../components/AppContent";
import AppHeading from "../components/AppHeading";
import AppIconBlock from "../components/AppIconBlock";
import AppMedia from "../components/AppMedia";

import "@understandit/lofsangruppen-storybook-22173948/compositions/exercise-card/exercise-card.scss";

const AppExerciseCard = (props) => {
  return (
    <div className="pepalexa-exercise-card">
      <AppHeading level="h2">{props.exercise_title}</AppHeading>

      <AppMedia modifiers="sharp">{props.exercise_media}</AppMedia>

      <AppContent
        modifiers="padding"
        innerContent={props.exercise_description}
      />
      {props.exercise_tools && (
        <AppContent modifiers="padding">
          Redskap: {props.exercise_tools}
        </AppContent>
      )}

      {props.showButtons && (
        <div className="three-icon-blocks">
          <div
            className="three-icon-blocks__block"
            onClick={props.onMessageClick}
          >
            <AppIconBlock icon="assets/icons/message.svg">
              Meddela coach
            </AppIconBlock>
          </div>
          <div
            className="three-icon-blocks__block"
            onClick={props.onMediaClick}
          >
            <AppIconBlock icon="assets/icons/images.svg">
              Ladda upp media
            </AppIconBlock>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppExerciseCard;

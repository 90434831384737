import React from "react";
import ReactDOM from "react-dom";
import { setupIonicReact } from "@ionic/react";
import { SplashScreen } from "@capacitor/splash-screen";
import dayjs from "dayjs";
import "dayjs/locale/sv";

import App from "./App";
import { loadSettings } from "./settings";

// Register zeus dynamic data custom elements.
import "@understandit/dynamic-data";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme css */
// import "@understandit/lofsangruppen-storybook-22173948/theme.scss";
import "./theme/theme.scss";

dayjs.locale("sv");

loadSettings().then(() => {
  setupIonicReact();

  setTimeout(() => {
    SplashScreen.hide();
  }, 500);

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );
});

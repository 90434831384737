import { useMemo } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

export function useClientCoachesProvider(
  clientId,
  includeGroupContent = false
) {
  const providerId = `client-coaches-provider-${clientId}`;
  const url = `${getSetting(
    "BACKEND"
  )}/api/v3/group_content/client-subgroup-coach?filter[gid.id]=${clientId}&include=entity_id.field_avatar`;

  const { data, isLoading } = useZeusDataProvider(providerId, []);

  const Provider = useMemo(() => {
    const Component = () => {
      if (!clientId) {
        return null;
      }
      return (
        <zeus-data-provider id={providerId} listen-tags="client-coaches">
          <zeus-data-source-jsonapi url={url}></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
          {!includeGroupContent && (
            <zeus-data-processor-jmespath filter="[*].entity_id"></zeus-data-processor-jmespath>
          )}
        </zeus-data-provider>
      );
    };
    return Component;
  }, [clientId, includeGroupContent, providerId, url]);

  return {
    Provider,
    coaches: data,
    isLoading,
  };
}

import { useMutation, useQuery, useQueryClient, useQueries } from "@tanstack/react-query";

import { getSetting } from "../settings";
import { httpClient, deserializeJsonApi } from "./client";
import { createMessage } from "../create-message";
import { privateFileUrl } from "../util";

export class MessageThread {
  id = "";
  title = "";
  image = "";
  clientId = "";
  coachId = "";
  unread = 0;
  closed = false;

  /**
   * @param {*} doc
   * @param {"coach" | "client"} mode
   * @param {"jsonapi" | "view"} format
   */
  constructor(doc, mode, format = "view") {
    if (mode === "coach" && format === "view") {
      this.id = doc.uuid;
      this.clientId = doc.client_id;
      this.title = doc.field_first_name + " " + doc.field_last_name;
      this.image = doc.field_avatar ? privateFileUrl(doc.field_avatar) : null;
      this.closed = doc.field_message_thread_closed !== "0";
    } else if (mode === "coach" && format === "jsonapi") {
      this.id = doc.id;
      this.clientId = doc.field_message_thread_client?.id;
      this.coachId = doc.field_message_thread_coach?.id;
      this.title =
        doc.field_message_thread_client?.field_first_name + " " + doc.field_message_thread_client?.field_last_name;
      this.image = doc.field_message_thread_client?.field_avatar?.image_style_uri?.medium;
      this.closed = doc.field_message_thread_closed;
    } else {
      this.id = doc.id;
      this.clientId = doc.field_message_thread_client?.id;
      this.coachId = doc.field_message_thread_coach?.id;
      this.title =
        doc.field_message_thread_coach?.field_first_name + " " + doc.field_message_thread_coach?.field_last_name;
      this.image = doc.field_message_thread_coach?.field_avatar?.image_style_uri?.medium;
      this.closed = doc.field_message_thread_closed;
    }
  }
}

export class Message {
  id = "";
  type = "message";
  thread = "";
  /** @type {Date}  */
  posted;
  /** @type {Date | null}  */
  read_at;
  is_author = false;
  message = "";
  /** @type {{type: string, id: string, link: string, text: string} | null} */
  reference = null;
  in_transit = false;

  /**
   *  @param {*} doc
   * @param {"coach" | "client"} mode
   */
  constructor(doc, mode) {
    this.thread = doc.entity_id.field_messsage_thread.id;
    this.id = doc.id;
    this.posted = new Date(doc.entity_id.created);
    this.is_author =
      (mode === "client" && doc.entity_id.field_message_from_client) ||
      (mode !== "client" && !doc.entity_id.field_message_from_client);

    this.message = doc.entity_id.field_message_message;
    this.read_at = doc.field_message_read ? new Date(doc.field_message_read) : null;

    const reference_type = doc.entity_id?.field_message_reference?.node_type?.drupal_internal__type;
    if (reference_type === "workout") {
      this.reference = {
        type: "workout",
        text: doc.entity_id?.field_message_reference?.title,
        id: doc.entity_id?.field_message_reference?.id,
        link: `/c/messages/preview/workout/${doc.entity_id?.field_message_reference?.id}/block/${doc.entity_id?.field_message_reference_index}`,
      };
    } else if (reference_type === "gallery") {
      this.reference = {
        type: "gallery",
        text: doc.entity_id?.field_message_reference?.title,
        id: doc.entity_id?.field_message_reference?.id,
        link: `/c/messages/preview/gallery/${doc.entity_id?.field_message_reference?.id}`,
      };
    }
  }
}

/**
 * @param {Message[]} first
 * @param {Message[]} second
 */
function mergeMessages(first, second) {
  const merged = [...first];

  second.forEach((msg) => {
    const xIdx = merged.findIndex((x) => x.id === msg.id);
    if (xIdx !== -1) {
      merged[xIdx] = msg;
    } else {
      merged.push(msg);
    }
  });

  return merged.sort((a, b) => a.posted - b.posted);
}

// /**
//  * @param {"client" | "coach"} mode
//  * @param {string} groupId
// */
// async function getUnreadMessages(mode, groupId) {
//   const field = mode === "coach" ? "field_message_thread_coach" : "field_message_thread_client" ;
//   const BACKEND = getSetting("BACKEND");
//   const params = new URLSearchParams({
//     [`filter[entity_id.field_messsage_thread.${field}.id]`]: groupId,
//     "filter[read_is_null][condition][path]": "field_message_read",
//     "filter[read_is_null][condition][operator]": "IS NULL",
//     include: "entity_id.field_message_reference.node_type,entity_id.field_messsage_thread",
//     sort: "-entity_id.created",
//   });
//   const url = `${BACKEND}/api/v3/group_content/client-group_node-message?${params}`;
//   const response = await httpClient(url);
//   return deserializeJsonApi(await response.json());
// }

/**
 * @param {"client" | "coach"} mode
 * @param {string} groupId
 * @returns {Promise<MessageThread[]>}
 */
async function getMessageThreads(mode, groupId) {
  const BACKEND = getSetting("BACKEND");

  if (mode === "coach") {
    const url = `${BACKEND}/coach-threads/${groupId}/${groupId}?_format=json`;
    const response = await httpClient(url);
    return (await response.json()).map((i) => new MessageThread(i, mode));
  } else {
    const params = new URLSearchParams({
      include: "field_message_thread_coach.field_avatar,field_message_thread_client.field_avatar",
      "filter[field_message_thread_client.id]": groupId,
      "page[limit]": "100",
    });
    const url = `${BACKEND}/api/v3/node/message_thread?${params}`;
    const response = await httpClient(url);
    const data = await deserializeJsonApi(await response.json());
    return data.map((i) => new MessageThread(i, mode));
  }
}

/**
 * @param {"client" | "coach"} mode
 * @param {string} groupId
 */
export function useMessageThreads(mode, groupId) {
  return useQuery({
    queryKey: ["message-threads"],
    queryFn: () => getMessageThreads(mode, groupId),
    enabled: !!mode && !!groupId,
  });
}

/**
 * @param {"client" | "coach"} mode
 * @param {string} threadId
 * @returns {Promise<MessageThread>}
 */
async function getMessageThread(mode, threadId) {
  const BACKEND = getSetting("BACKEND");
  const params = new URLSearchParams({
    include: "field_message_thread_coach.field_avatar,field_message_thread_client.field_avatar",
  });
  const url = `${BACKEND}/api/v3/node/message_thread/${threadId}?${params}`;
  const response = await httpClient(url);
  const data = await deserializeJsonApi(await response.json());
  return new MessageThread(data, mode);
}

/**
 * @param {"client" | "coach"} mode
 * @param {string} threadId
 */
export function useMessageThread(mode, threadId) {
  return useQuery({
    queryKey: ["message-threads", threadId],
    queryFn: () => getMessageThread(mode, threadId),
    enabled: !!mode && !!threadId,
  });
}

/**
 * @param {"client" | "coach"} mode
 * @param {string} threadId
 * @return {Promise<any[]>}
 */
async function getMessages(threadId) {
  const BACKEND = getSetting("BACKEND");
  const params = new URLSearchParams({
    "filter[entity_id.field_messsage_thread.id]": threadId,
    include: "entity_id.field_message_reference.node_type,entity_id.field_messsage_thread",
    sort: "-entity_id.created",
  });
  const url = `${BACKEND}/api/v3/group_content/client-group_node-message?${params}`;
  const response = await httpClient(url);
  return deserializeJsonApi(await response.json());
}

/**
 * @param {"client" | "coach"} mode
 * @param {string} threadId
 * @param {*} queryClient
 * @returns {Promise<Message[]>}
 */
async function getMessagesHelper(mode, threadId, queryClient) {
  const messages = await getMessages(threadId);
  const oldData = queryClient.getQueryData(["messages", threadId]) ?? [];
  return mergeMessages(
    oldData,
    messages.map((i) => new Message(i, mode))
  );
}

/**
 * @param {"client" | "coach"} mode
 * @param {string} threadId
 */
export function useMessages(mode, threadId) {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["messages", threadId],
    queryFn: () => getMessagesHelper(mode, threadId, queryClient),
  });
}

export function useAllMessages(mode, threads) {
  const queryClient = useQueryClient();
  return useQueries({
    queries: threads
      ? threads.map((thread) => {
          return {
            queryKey: ["messages", thread.id],
            queryFn: () => getMessagesHelper(mode, thread.id, queryClient),
            refetchInterval: 20000,
          };
        })
      : [],
  });
}

/**
 * @param {"client" | "coach"} mode
 */
export function useCreateMessageMutation(mode) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables) => {
      return createMessage(variables.id, variables.thread, variables.message, variables.richContent, mode);
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: ["messages", variables.thread.id] });
      const optimisticMessage = {
        id: variables.id,
        type: "message",
        thread: variables.thread.id,
        posted: new Date(),
        is_author: true,
        message: variables.message,
        in_transit: true,
      };
      queryClient.setQueryData(["messages", variables.thread.id], (old) => [...(old ?? []), optimisticMessage]);
      return { optimisticMessage };
    },
    onSuccess: (_result, _vars, context) => {
      /** @type {Message} */
      const message = context.optimisticMessage;
      queryClient.setQueryData(["messages", message.thread], (old) => {
        return old.map((x) => {
          if (x.id === message.id) {
            return {
              ...x,
              in_transit: false,
            };
          }
          return x;
        });
      });
      queryClient.invalidateQueries(["messages", message.thread]);
    },
  });
}

/**
 * @param {string} messageId
 */
async function markMessageAsRead(messageId) {
  const readAt = new Date().toISOString().replace(/\.[0-9][0-9][0-9]/, "");
  const body = {
    data: {
      type: "group_content--client-group_node-message",
      id: messageId,
      attributes: {
        field_message_read: readAt,
      },
    },
  };

  const BACKEND = getSetting("BACKEND");
  const url = `${BACKEND}/api/v3/group_content/client-group_node-message/${messageId}`;

  const response = await httpClient(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/vnd.api+json",
    },
    body: JSON.stringify(body),
  });

  if (response.status !== 200) {
    throw new Error("Could not mark message as read.");
  }

  return response.json();
}

export function useMarkAsReadMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (message) => {
      return markMessageAsRead(message.id);
    },
    /**
     * @param {Message} message
     */
    onMutate: async (message) => {
      await queryClient.cancelQueries({ queryKey: ["messages", message.thread] });
      const optimisticMessage = {
        id: message.id,
        type: "message",
        thread: message.thread,
        posted: message.posted,
        is_author: message.is_author,
        message: message.message,
        in_transit: false,
        read_at: new Date(),
      };
      queryClient.setQueryData(["messages", message.thread], (old) => mergeMessages(old, [optimisticMessage]));
      return { optimisticMessage };
    },
  });
}

/**
 *
 * @param {string} clientId
 * @param {string} coachId
 */
async function createThread(clientId, coachId) {
  const body = {
    data: {
      type: "node--message_thread",
      attributes: {
        title: `${clientId} <-> ${coachId}`,
      },
      relationships: {
        field_message_thread_client: {
          data: {
            id: clientId,
            type: "group--client",
          },
        },
        field_message_thread_coach: {
          data: {
            id: coachId,
            type: "group--coach",
          },
        },
      },
    },
  };

  const BACKEND = getSetting("BACKEND");
  const url = `${BACKEND}/api/v3/node/message_thread?include=field_message_thread_client,field_message_thread_coach`;

  const response = await httpClient(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/vnd.api+json",
    },
    body: JSON.stringify(body),
  });

  if (response.status !== 201) {
    throw new Error(response.statusText);
  }

  return deserializeJsonApi(await response.json());
}

export function useCreateThreadMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ clientId, coachId }) => {
      return createThread(clientId, coachId);
    },
    /**
     * @param {Message} message
     */
    onSuccess: async (result) => {
      await queryClient.cancelQueries({ queryKey: ["message-threads"] });
      const newThread = new MessageThread(result, "coach", "jsonapi");
      queryClient.setQueryData(["message-threads"], (old) => [newThread, ...old]);
      queryClient.invalidateQueries(["message-threads"]);
    },
  });
}

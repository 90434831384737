import { useMemo } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

export function useMessageThreadsProvider(groupId, mode, clients) {
  const providerId = `message-threads-${groupId}`;
  const filter =
    mode === "coach"
      ? `filter[field_message_thread_coach.id]=${groupId}`
      : `filter[field_message_thread_client.id]=${groupId}`;
  const url = `${getSetting(
    "BACKEND"
  )}/api/v3/node/message_thread?${filter}&include=field_message_thread_coach.field_avatar,field_message_thread_client.field_avatar`;

  const { data, isLoading } = useZeusDataProvider(providerId, []);

  const threads = useMemo(() => {
    const temp = data.map((thread) => ({
      key: thread?.id,
      clientId: thread.field_message_thread_client?.id,
      mode: "thread",
      title:
        mode === "coach"
          ? thread.field_message_thread_client?.label
          : thread.field_message_thread_coach?.label,
      image:
        mode === "coach"
          ? thread.field_message_thread_client?.field_avatar?.image_style_uri
              ?.medium
          : thread.field_message_thread_coach?.field_avatar?.image_style_uri
              ?.medium,
      link: `/c/messages/${thread?.id}`,
    }));

    clients
      .filter((client) => !temp.some((x) => x.clientId === client.id))
      .forEach((client) =>
        temp.push({
          key: client.id,
          clientId: client.id,
          coachId: groupId,
          mode: "client",
          title: `${client.field_first_name} ${client.field_last_name}`,
          image: client.field_avatar?.image_style_uri?.medium,
          link: null,
        })
      );
    return temp;
  }, [clients, data, groupId, mode]);

  const Provider = useMemo(() => {
    const Component = () => {
      if (!groupId) {
        return null;
      }
      return (
        <zeus-data-provider id={providerId} listen-tags="message_thread">
          <zeus-data-source-jsonapi url={url}></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      );
    };
    return Component;
  }, [groupId, providerId, url]);

  return {
    Provider,
    threads,
    isLoading,
  };
}

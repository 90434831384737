import { useState, useEffect } from "react";
import { IonSpinner } from "@ionic/react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { fileSelectPlaceholderImage } from "../util";
import { getSetting } from "../settings";
import Heading from "../components/AppHeading";
import Content from "../components/AppContent";
import HorizontalCard from "../components/AppHorizontalCard";
import AppPageWithHideableHeader from "../compositions/AppPageWithHideableHeader";
import BottomSpacer from "../components/BottomSpacer";
import AppSearchbar from "../components/AppSearchbar";

function buildSearchUrl(
  entityType,
  collectionId,
  searchString,
  searchTags = {}
) {
  if (!collectionId) {
    return null;
  }

  const path = `${getSetting(
    "BACKEND"
  )}/api/v3/group_content/client-group_node-${entityType}`;

  const params = {
    "filter[gid.id]": collectionId,
    "filter[field_status]": "published",
    include: "entity_id.field_diet_tags,entity_id.field_media.thumbnail",
    sort: "-entity_id.created",
  };

  if (searchString) {
    params["filter[title-filter][condition][path]"] = "entity_id.title";
    params["filter[title-filter][condition][operator]"] = "CONTAINS";
    params["filter[title-filter][condition][value]"] = searchString;
  }

  Object.keys(searchTags).forEach((key) => {
    if (searchTags[key]) {
      params[`filter[${key}][condition][path]`] = `entity_id.${key}.id`;
      params[`filter[${key}][condition][value]`] = searchTags[key];
    }
  });

  return `${path}?${new URLSearchParams(params)}`;
}

const Diet = ({ groupId }) => {
  const { data: diets, isLoading } = useZeusDataProvider(
    "client-diet-list",
    []
  );
  const type = "diet";

  const [searchValue, setSearchValue] = useState("");
  const [searchUrl, setSearchUrl] = useState("");
  const [searchString, setSearchString] = useState("");

  const doSearch = (e) => {
    e?.preventDefault();
    setSearchString(searchValue);
  };

  useEffect(() => {
    setSearchUrl(buildSearchUrl(type, groupId, searchString));
  }, [type, searchString, groupId]);

  return (
    <AppPageWithHideableHeader title="Kostråd" defaultHref="/c/menu" backButton>
      <Content modifiers="padding margin-bottom">
        <Heading modifiers="center">Kostråd</Heading>
        <form onSubmit={doSearch} action="/">
          <AppSearchbar
            placeholder="Sök"
            inputmode="search"
            value={searchValue}
            debounce={0}
            onIonChange={(e) => setSearchValue(e.target.value)}
          />
        </form>
      </Content>

      <Content modifiers="padding">
        {isLoading && (
          <div className="ion-text-center">
            <IonSpinner color="light" />
          </div>
        )}
        {diets.map((diet) => (
          <HorizontalCard
            key={diet.id}
            title={diet.field_public_title ?? diet.title}
            media={
              diet.field_media?.thumbnail?.image_style_uri?.large ??
              fileSelectPlaceholderImage(diet.drupal_internal__nid)
            }
            routerLink={`/c/menu/diet/${diet.id}`}
          />
        ))}
      </Content>
      <BottomSpacer />
      <zeus-data-provider id="client-diet-list">
        <zeus-data-source-jsonapi url={searchUrl}></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        <zeus-data-processor-jmespath filter="[*].entity_id"></zeus-data-processor-jmespath>
      </zeus-data-provider>
    </AppPageWithHideableHeader>
  );
};

export default Diet;

import { useMemo, useState, useEffect } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

export function useMessagesProvider(clientId, threadId, mode) {
  const providerId = `messages-${threadId}`;
  const url = `${getSetting(
    "BACKEND"
  )}/api/v3/group_content/client-group_node-message?filter[gid.id]=${clientId}&filter[entity_id.field_messsage_thread.id]=${threadId}&include=entity_id.field_message_reference.node_type&sort=-entity_id.created`;

  const { data: rawMessages, isLoading } = useZeusDataProvider(providerId, []);

  const [parsedMessages, setParsedMessages] = useState({});

  useEffect(() => {
    const newParsedMessages = { ...parsedMessages };
    let updatedParsedMessages = false;
    rawMessages
      .map((message) => {
        const m = {
          type: "message",
          id: message.id,
          key: message.id,
          posted: new Date(message.entity_id.created),
          is_author:
            (mode === "client" &&
              message.entity_id.field_message_from_client) ||
            (mode !== "client" && !message.entity_id.field_message_from_client),
          read_at: message.field_message_read,
          message: message.entity_id.field_message_message,
        };
        const reference_type = message.entity_id?.field_message_reference?.node_type?.drupal_internal__type;
        if(reference_type === "workout") {
          m.type = "message_workout";
          m.ref_text = message.entity_id?.field_message_reference?.title;
          m.ref_link = `/c/menu/workout/${message.entity_id?.field_message_reference?.id}/block/${message.entity_id?.field_message_reference_index}`
          /*
          m.ref_link = mode === "client" ? 
            `/c/menu/workout/${message.entity_id?.field_message_reference?.id}/block/${message.entity_id?.field_message_reference_index}` : 
            `/c/menu/manage/client/${clientId}/workout/${message.entity_id?.field_message_reference?.id}/block/${message.entity_id?.field_message_reference_index}`
          */
        } else if(reference_type === "gallery") {
          m.type = "message_gallery";
          m.ref_id = message.entity_id?.field_message_reference?.id;
          m.ref_link = mode === "client" ?
            `/c/menu/gallery/${message.entity_id?.field_message_reference?.id}` :
            `/c/menu/manage/client/${clientId}/gallery/${message.entity_id?.field_message_reference?.id}`
        }
        return m;
      })
      .forEach((message) => {
        if (!(message.key in newParsedMessages)) {
          newParsedMessages[message.key] = message;
          updatedParsedMessages = true;
        }
      });
    if (updatedParsedMessages) {
      setParsedMessages(Object.values(newParsedMessages)
        .sort((a, b) => a.posted - b.posted)
        .reduce(function (acc, cur) {
          acc[cur.key] = cur;
          return acc;
        }, {}));
    }
  }, [rawMessages, parsedMessages, mode, clientId])

  const Provider = useMemo(() => {
    const Component = () => {
      if (!threadId) {
        return null;
      }
      return (
        <zeus-data-provider id={providerId}>
          <zeus-data-source-jsonapi url={url}></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      );
    };
    return Component;
  }, [providerId, threadId, url]);

  return {
    Provider,
    messages: Object.values(parsedMessages),
    isLoading,
  };
}

import { processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-message-box-exercise/pepalexa-message-box-exercise.scss";

const AppMessageBoxExercise = ({ title, type, image, modifiers, ...props }) => (
  <div
    {...props}
    className={processModifiers("pepalexa-message-box-exercise", modifiers)}
    style={{cursor:"pointer"}}
  >
    <div className="pepalexa-message-box-exercise__content">
      <h2 className="pepalexa-message-box-exercise__title">{title}</h2>
      <p className="pepalexa-message-box-exercise__type">{type}</p>
    </div>
    <div
      className="pepalexa-message-box-exercise__image"
      style={{ backgroundImage: `url(${image})` }}
    ></div>
  </div>
);

export default AppMessageBoxExercise;

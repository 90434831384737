import { useState, useEffect, useRef } from "react";
import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonSpinner,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";
import { addCircleOutline } from "ionicons/icons";

import { getSetting, pageConfig } from "../../settings";
import { buildSearchUrl } from "../../form/helpers";
import { useProfileProvider } from "../../providers/profile-provider";
import { AppTabsButton, AppTabsHeader } from "../../components/AppTabs";
import {
  AppPageWithSheet,
  AppTopOfSheet,
  AppSheet,
} from "../../compositions/AppPageWithSheet";
import AppContent from "../../components/AppContent";
import AppHeading from "../../components/AppHeading";
import AppSearchbar from "../../components/AppSearchbar";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import AppTagFilter from "../../form/AppTagFilter";
import BottomSpacer from "../../components/BottomSpacer";

const EntityList = ({
  backButton = true,
  defaultHref = "/c/menu",
  type,
  isAdmin,
}) => {
  const providerId = `coach-entity-list-${type}`;
  const { data: entities, isLoading } = useZeusDataProvider(providerId, []);
  const { data: collection } = useZeusDataProvider("collection");
  const { Provider, profiles } = useProfileProvider("coach");
  const [searchString, setSearchString] = useState("");
  const [searchUrl, setSearchUrl] = useState("");
  const [searchTags, setSearchTags] = useState({});
  const [selectedCollection, setSelectedCollection] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const inCommonCollection = selectedCollection !== collection?.id;
  const providerRef = useRef();

  const hasMore = Boolean(providerRef.current?.source?.hasNextPage);

  const doSearch = (e) => {
    e?.preventDefault();
    setSearchString(searchValue);
  };

  const doLoadMore = () => {
    document.dispatchEvent(
      new CustomEvent(`zeusDataOperation:${providerId}`, {
        detail: { name: "next-page" },
      })
    );
  };

  const resetFilter = () => {
    setSearchString("");
    setSearchValue("");
    setSearchTags({});
  };

  useEffect(() => {
    setSearchUrl(
      buildSearchUrl(type, selectedCollection, searchString, searchTags)
    );
  }, [type, selectedCollection, searchString, searchTags]);

  useEffect(() => {
    if (collection?.id && selectedCollection === "") {
      setSelectedCollection(collection.id);
    }
  }, [collection, selectedCollection]);

  return (
    <AppPageWithHideableHeader
      title={pageConfig[type].listTitle}
      backButton={backButton}
      defaultHref={defaultHref}
      toolbarButtons={
        <IonButton routerLink={`/c/menu/manage/${type}/add`}>
          <IonIcon slot="icon-only" icon={addCircleOutline} />
        </IonButton>
      }
    >
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppContent modifiers="padding margin-bottom">
            <AppHeading modifiers="center">
              {pageConfig[type].listTitle}
            </AppHeading>

            <AppTabsHeader>
              <AppTabsButton
                onClick={() =>
                  setSelectedCollection(getSetting("COMMON_COLLECTION"))
                }
                selected={inCommonCollection}
              >
                {isAdmin ? "Alla" : "Gemensamma"}
              </AppTabsButton>
              <AppTabsButton
                onClick={() =>
                  collection?.id && setSelectedCollection(collection.id)
                }
                selected={selectedCollection === collection?.id}
              >
                Mina
              </AppTabsButton>
            </AppTabsHeader>

            <form onSubmit={doSearch} action="/">
              <AppSearchbar
                placeholder="Sök"
                inputmode="search"
                value={searchValue}
                debounce={0}
                onIonChange={(e) => setSearchValue(e.target.value)}
              />
            </form>

            <IonList>
              {isAdmin && inCommonCollection && (
                <IonItem lines="full">
                  <IonLabel>Katalog</IonLabel>
                  <IonSelect
                    interface="popover"
                    value={selectedCollection}
                    onIonChange={(e) => setSelectedCollection(e.detail.value)}
                  >
                    {profiles?.map((coach) => (
                      <IonSelectOption key={coach.id} value={coach.id}>
                        {coach.label}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              )}

              {pageConfig[type].searchTags.map((tag) => (
                <AppTagFilter
                  key={tag.field}
                  label={tag.label}
                  provider={tag.provider}
                  value={searchTags[tag.field] ?? ""}
                  onChange={(value) =>
                    setSearchTags((current) => ({
                      ...current,
                      [tag.field]: value,
                    }))
                  }
                />
              ))}
            </IonList>

            <div className="ion-padding-top ion-text-right">
              <IonButton
                onClick={resetFilter}
                fill="outline"
                color="secondary"
                size="small"
              >
                Återställ
              </IonButton>
            </div>
          </AppContent>
        </AppTopOfSheet>
        <AppSheet>
          <IonList className="light ion-no-padding">
            {entities.map((item) => (
              <IonItem
                key={item.id}
                routerLink={`/c/menu/manage/${
                  inCommonCollection ? "common" : "my"
                }/${type}/${item.id}`}
              >
                <IonLabel>{item.title}</IonLabel>
              </IonItem>
            ))}
          </IonList>
          {isLoading && (
            <div className="ion-text-center ion-padding">
              <IonSpinner color="dark" />
            </div>
          )}

          {hasMore && (
            <div className="ion-text-center ion-padding">
              <IonButton
                color="secondary"
                fill="outline"
                size="small"
                onClick={doLoadMore}
              >
                Ladda fler
              </IonButton>
            </div>
          )}
          <BottomSpacer />
        </AppSheet>
      </AppPageWithSheet>

      <zeus-data-provider id={providerId} listen-tags={type} ref={providerRef}>
        <zeus-data-source-jsonapi url={searchUrl}></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        <zeus-data-processor-jmespath filter="[*].entity_id"></zeus-data-processor-jmespath>
      </zeus-data-provider>
      <Provider />
    </AppPageWithHideableHeader>
  );
};

export default EntityList;

import {
  IonList,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";
import { closeCircleOutline } from "ionicons/icons";

import { useFormState } from "../../form/hooks";
import { getSetting, pageConfig } from "../../settings";
import { generateIncludeString } from "../../form/helpers";
import { FormValue } from "../../form/FormItem";
import {
  AppPageWithSheet,
  AppSheet,
} from "../../compositions/AppPageWithSheet";
import BottomSpacer from "../../components/BottomSpacer";
import AppSmallTitle from "../../components/AppSmallTitle";

const EntityPreview = ({ id, type, dismiss }) => {
  const entityConfig = pageConfig[type];
  const providerId = `coach-entity-preview-${type}-${id}`;
  const { data: entity } = useZeusDataProvider(providerId);
  const [formState] = useFormState(entityConfig.fields, entity, true);

  const normalFields = pageConfig[type].fields.filter(
    (i) => i.type !== "workout-blocks"
  );
  const connectorFields = pageConfig[type].fields.filter(
    (i) => i.type === "workout-blocks"
  );

  return (
    <>
      <IonHeader className="app-header">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={dismiss}>
              <IonIcon icon={closeCircleOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>{entity?.title ?? "Laddar..."}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <AppPageWithSheet>
          <AppSheet>
            <IonList class="form">
              {normalFields.map((field, i, fields) => (
                <FormValue
                  key={field.field}
                  label={field.label}
                  type={field.type}
                  config={field}
                  value={formState[field.field]}
                  last={i === fields.length - 1}
                />
              ))}
            </IonList>

            {connectorFields?.length > 0 && (
              <>
                <div className="ion-margin-vertical">
                  <AppSmallTitle>Övningar</AppSmallTitle>
                </div>
                {connectorFields.map((field) => (
                  <FormValue
                    key={field.field}
                    type={field.type}
                    value={formState[field.field]}
                  />
                ))}
              </>
            )}

            <BottomSpacer />
          </AppSheet>
          <zeus-data-provider id={providerId}>
            <zeus-data-source-jsonapi
              url={`${getSetting(
                "BACKEND"
              )}/api/v3/node/${type}/${id}?include=${generateIncludeString(
                pageConfig[type].fields
              )}`}
            ></zeus-data-source-jsonapi>
            <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
          </zeus-data-provider>
        </AppPageWithSheet>
      </IonContent>
    </>
  );
};

export default EntityPreview;

import { IonItem, IonLabel } from "@ionic/react";

import AppAvatar from "../components/AppAvatar";
import AppIcon from "../components/AppIcon";

import "@understandit/lofsangruppen-storybook-22173948/compositions/list-item/list-item.scss";

const AppListItem = (props) => {
  return (
    <IonItem
      className="ion-item--padding ion-item--dark-text ion-item--highlighted-subtitle ion-item--no-padding"
      routerLink={props.routerLink}
      onClick={props.onClick}
    >
      {props.avatar && <AppAvatar src={props.avatar} modifiers="list" />}
      <IonLabel>{props.title}</IonLabel>
      {props.icon && <AppIcon icon={props.icon} modifiers="centered" />}
    </IonItem>
  );
};

export default AppListItem;

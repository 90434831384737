import { useState } from "react";
import {
  IonLoading,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  IonButton,
} from "@ionic/react";
import {
  useZeusDataProvider,
  useZeusDataStatusSubscriber,
} from "@understandit/dynamic-data-react";
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";
import dayjs from "dayjs";

import { getSetting } from "../settings";
import { useError } from "../hooks";
import AppContent from "../components/AppContent";
import AppRangeWithLabel from "../compositions/AppRangeWithLabel";
import AppTextareaWithTitle from "../compositions/AppTextareaWithTitle";
import AppInput from "../components/AppInput";
import AppLabel from "../components/AppLabel";

const WorkoutLogAdd = ({ workoutId, clientId, onClose, text, date }) => {
  const providerId = `create-workout-log-${workoutId ?? "new"}`;
  const { setData, isLoading } = useZeusDataProvider(providerId);
  const setError = useError();
  const [formState, setFormState] = useState({
    title: workoutId ? `Workout Log for ${workoutId}` : "",
    field_workout_log_challenge: 0,
    field_workout_log_effort: 0,
    field_workout_log_enjoyment: 0,
    field_workout_log_summary: "",
  });

  useZeusDataStatusSubscriber(providerId, (status) => {
    if (status === "created") {
      onClose();
    }
  });

  const doUpdateFormState = (field, value) => {
    setFormState((state) => ({
      ...state,
      [field]: value,
    }));
  };

  const doSave = () => {
    if (!formState.title) {
      setError("Du måste fylla i titel.");
      return;
    }

    const data = {
      ...formState,
      _group: clientId,
      _postAddData: {
        created: dayjs(date).unix(),
      },
    };

    if (workoutId) {
      data._relationships = [
        {
          field: "field_workout_log_workout",
          data: {
            id: workoutId,
            type: "node--workout",
          },
        },
      ];
    }

    setData(data);
  };

  return (
    <>
      <IonLoading isOpen={isLoading} />
      <IonHeader className="app-header">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onClose}>
              <IonIcon slot="icon-only" icon={closeCircleOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Logga pass</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={doSave}>
              <IonIcon slot="icon-only" icon={checkmarkCircleOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {text && <AppContent modifiers="padding margin-top">{text}</AppContent>}

        {!workoutId && (
          <AppContent modifiers="padding">
            <div className="textarea-with-title">
              <AppLabel>Titel</AppLabel>
              <AppInput
                onChange={(e) => doUpdateFormState("title", e.target.value)}
                value={formState.title}
              />
            </div>
          </AppContent>
        )}

        <AppRangeWithLabel
          label="Hur ansträngande kändes passet?"
          from_text="0"
          to_text="100%"
          onChange={(e) =>
            doUpdateFormState("field_workout_log_effort", e.detail.value)
          }
          value={formState.field_workout_log_effort}
        />
        <AppRangeWithLabel
          label="Hur utmanande kändes passet?"
          from_text="0"
          to_text="100%"
          onChange={(e) =>
            doUpdateFormState("field_workout_log_challenge", e.detail.value)
          }
          value={formState.field_workout_log_challenge}
        />
        <AppRangeWithLabel
          label="Hur roligt kändes passet?"
          from_text="0"
          to_text="100%"
          onChange={(e) =>
            doUpdateFormState("field_workout_log_enjoyment", e.detail.value)
          }
          value={formState.field_workout_log_enjoyment}
        />
        <AppTextareaWithTitle
          title="Anteckningar"
          placeholder="Skriv en anteckning"
          onChange={(e) =>
            doUpdateFormState("field_workout_log_summary", e.detail.value)
          }
          value={formState.field_workout_log_summary}
        />
      </IonContent>
      <zeus-data-provider id={providerId} send-tags="workout_log">
        <zeus-data-source-drupal-group-member-content-create
          base-url={getSetting("BACKEND")}
          jsonapi-path="/api/v3"
          content-bundle="workout_log"
          group-bundle="client"
          token-provider="auth"
        ></zeus-data-source-drupal-group-member-content-create>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

export default WorkoutLogAdd;

import { useRef, useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonInput,
  IonButtons,
} from "@ionic/react";
import { useParams } from "react-router";
import dayjs from "dayjs";
import {
  checkmarkCircleOutline,
  closeCircleOutline,
  ellipsisHorizontalCircle,
  trashOutline,
} from "ionicons/icons";

import { useContentProvider } from "../providers/content-provider";
import AppPageWithHideableHeader from "../compositions/AppPageWithHideableHeader";
import AppContent from "../components/AppContent";
import AppHeading from "../components/AppHeading";
import {
  AppPageWithSheet,
  AppSheet,
  AppTopOfSheet,
} from "../compositions/AppPageWithSheet";
import { formatMediumDate } from "../util";

const EditModal = ({ item, onDismiss, isOpen, unit }) => {
  const [formState, setFormState] = useState({
    value: "",
    date: "",
  });

  useEffect(() => {
    if (item) {
      setFormState({
        value: item.field_progress_value_text,
        date: dayjs(item.field_progress_timestamp).format("YYYY-MM-DD"),
      });
    }
  }, [item]);

  const doSave = () => {
    onDismiss({
      action: "update",
      item: {
        id: item.id,
        field_progress_timestamp: dayjs(formState.date).format(),
        field_progress_value_text: formState.value,
      },
    });
  };

  const doDelete = () => {
    onDismiss({
      action: "delete",
      item: { id: item.id },
    });
  };

  return (
    <IonModal isOpen={isOpen}>
      <IonHeader className="app-header">
        <IonToolbar>
          <IonTitle>Redigera</IonTitle>
          <IonButtons slot="primary">
            <IonButton onClick={doDelete}>
              <IonIcon slot="icon-only" icon={trashOutline} />
            </IonButton>

            <IonButton onClick={doSave}>
              <IonIcon slot="icon-only" icon={checkmarkCircleOutline} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="secondary">
            <IonButton onClick={() => onDismiss({})}>
              <IonIcon slot="icon-only" icon={closeCircleOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <AppContent modifiers="padding margin-top">
          <IonList class="form">
            <IonItem lines="full">
              <IonLabel position="stacked">Värde ({unit})</IonLabel>
              <IonInput
                type="text"
                value={formState.value}
                onIonChange={(e) =>
                  setFormState((current) => ({
                    ...current,
                    value: e.detail.value,
                  }))
                }
              />
            </IonItem>
            <IonItem lines="full">
              <IonLabel position="stacked">Datum</IonLabel>
              <IonInput
                type="date"
                value={formState.date}
                onIonChange={(e) =>
                  setFormState((current) => ({
                    ...current,
                    date: e.detail.value,
                  }))
                }
              />
            </IonItem>
          </IonList>
        </AppContent>
      </IonContent>
    </IonModal>
  );
};

const AddModal = ({ trigger, text, unit, onSave }) => {
  const modal = useRef(null);
  const [formState, setFormState] = useState({
    value: "",
    date: dayjs().format("YYYY-MM-DD"),
  });

  const doSave = () => {
    onSave?.(formState);
    modal.current?.dismiss();
  };

  return (
    <IonModal trigger={trigger} ref={modal}>
      <IonHeader className="app-header">
        <IonToolbar>
          <IonTitle>Lägg till</IonTitle>
          <IonButtons slot="primary">
            <IonButton onClick={doSave}>
              <IonIcon slot="icon-only" icon={checkmarkCircleOutline} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="secondary">
            <IonButton onClick={() => modal.current?.dismiss()}>
              <IonIcon slot="icon-only" icon={closeCircleOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <AppContent modifiers="padding margin-top">
          <AppHeading>{text}</AppHeading>
          <IonList class="form">
            <IonItem lines="full">
              <IonLabel position="stacked">Värde ({unit})</IonLabel>
              <IonInput
                type="text"
                value={formState.value}
                onIonChange={(e) =>
                  setFormState((current) => ({
                    ...current,
                    value: e.detail.value,
                  }))
                }
              />
            </IonItem>
            <IonItem lines="full">
              <IonLabel position="stacked">Datum</IonLabel>
              <IonInput
                type="date"
                value={formState.date}
                onIonChange={(e) =>
                  setFormState((current) => ({
                    ...current,
                    date: e.detail.value,
                  }))
                }
              />
            </IonItem>
          </IonList>
        </AppContent>
      </IonContent>
    </IonModal>
  );
};

const ProgressDetail = ({ defaultHref = "/c/menu/progress" }) => {
  const { id } = useParams();
  const { Provider, entity, setData } = useContentProvider(id, "progress", {
    include: "field_progress_values",
    attributes: "field_progress_values",
    relationships: "field_progress_values:paragraph--progress_default",
  });
  const [editItem, setEditItem] = useState(null);

  const values =
    entity?.field_progress_values?.sort(
      (a, b) =>
        dayjs(b.field_progress_timestamp).unix() -
        dayjs(a.field_progress_timestamp).unix()
    ) ?? [];

  const doUpdateItem = ({ action, item }) => {
    setEditItem(null);

    let newValues = entity.field_progress_values.map((row) => ({
      ...row,
      action: null,
    }));

    if (action === "update") {
      const index = newValues.findIndex((row) => row.id === item.id);
      newValues[index] = {
        action: "create",
        ...item,
      };
    } else if (action === "delete") {
      newValues = newValues.filter((row) => row.id !== item.id);
    }

    const data = {
      id: entity.id,
      _paragraphs: [
        {
          field: "field_progress_values",
          type: "paragraph--progress_default",
          attributes: ["field_progress_timestamp", "field_progress_value_text"],
          values: newValues,
        },
      ],
    };
    setData(data);
  };

  const doSave = (values) => {
    const newValues = entity.field_progress_values.map((item) => ({
      ...item,
      action: null,
    }));
    newValues.push({
      action: "create",
      field_progress_timestamp: `${values.date}T12:00:00Z`,
      field_progress_value_text: values.value,
    });
    const data = {
      id,
      _paragraphs: [
        {
          field: "field_progress_values",
          type: "paragraph--progress_default",
          attributes: ["field_progress_timestamp", "field_progress_value_text"],
          values: newValues,
        },
      ],
    };

    setData(data);
  };

  return (
    <AppPageWithHideableHeader
      title={entity?.title}
      defaultHref={defaultHref}
      backButton
      toolbarButtons={
        <IonButton id="open-modal">
          <IonIcon slot="icon-only" src="assets/icons/add.svg" />
        </IonButton>
      }
    >
      <EditModal
        unit={entity?.field_progress_unit}
        isOpen={editItem !== null}
        item={editItem}
        onDismiss={doUpdateItem}
      />
      <AddModal
        trigger="open-modal"
        text={entity?.title}
        unit={entity?.field_progress_unit}
        onSave={doSave}
      />
      <Provider />
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppContent modifiers="padding margin-bottom">
            <AppHeading modifiers="center">{entity?.title}</AppHeading>
          </AppContent>
        </AppTopOfSheet>
        <AppSheet>
          <IonList className="light">
            {values.map((item) => (
              <IonItem key={item.id}>
                <IonLabel>
                  <h2>
                    {item.field_progress_value_text}{" "}
                    {entity?.field_progress_unit}
                  </h2>
                  <p>{formatMediumDate(item.field_progress_timestamp)}</p>
                </IonLabel>
                <IonButton
                  slot="end"
                  fill="clear"
                  onClick={() => setEditItem(item)}
                >
                  <IonIcon slot="icon-only" icon={ellipsisHorizontalCircle} />
                </IonButton>
              </IonItem>
            ))}
          </IonList>
        </AppSheet>
      </AppPageWithSheet>
    </AppPageWithHideableHeader>
  );
};

export default ProgressDetail;

import {
  IonButton,
  IonIcon,
  IonList,
  IonLoading,
  useIonRouter,
  IonAlert,
} from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";

import { useCreateProfileProvider } from "../../providers/create-profile-provider";
import { useFormState } from "../../form/hooks";
import { useError } from "../../hooks";
import { FormItem } from "../../form/FormItem";
import {
  AppPageWithSheet,
  AppSheet,
  AppTopOfSheet,
} from "../../compositions/AppPageWithSheet";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import BottomSpacer from "../../components/BottomSpacer";
import AppContent from "../../components/AppContent";
import AppHeading from "../../components/AppHeading";
import { useZeusDataStatusSubscriber } from "@understandit/dynamic-data-react";

const fields = [
  {
    field: "field_first_name",
    label: "Förnamn",
    type: "text",
    required: true,
  },
  {
    field: "field_last_name",
    label: "Efternamn",
    type: "text",
    required: true,
  },
  {
    field: "mail",
    label: "E-post",
    type: "text",
    required: true,
  },
];

const ProfileClientAdd = () => {
  const setValidationError = useError();
  const { Provider, setData, isLoading, error, providerId } =
    useCreateProfileProvider("client");
  const [formState, updateFormState, validateFormState] = useFormState(fields);
  const router = useIonRouter();
  useZeusDataStatusSubscriber(providerId, (status) => {
    if (status === "updated") {
      router.push("/c/menu/manage/profile", "back");
    }
  });

  const title = "Lägg till klient";

  const doSave = () => {
    const errors = validateFormState();
    if (errors.length) {
      setValidationError(errors[0]);
      return;
    }
    setData({
      type: "client",
      ...formState,
    });
  };

  return (
    <AppPageWithHideableHeader
      title={title}
      defaultHref={`/c/menu/manage/profile`}
      backButton
      toolbarButtons={
        <IonButton onClick={doSave}>
          <IonIcon icon={checkmarkCircleOutline} slot="icon-only" />
        </IonButton>
      }
    >
      <IonLoading isOpen={isLoading} />
      <IonAlert
        isOpen={error?.status === 400}
        buttons={["Ok"]}
        header="Lofsan"
        message={error.data}
      />
      <Provider />
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppContent modifiers="padding margin-bottom">
            <AppHeading modifiers="center">{title}</AppHeading>
          </AppContent>
        </AppTopOfSheet>
        <AppSheet>
          <IonList class="form">
            {fields.map((field, i, fields) => (
              <FormItem
                key={field.field}
                label={field.label}
                type={field.type}
                config={field}
                value={formState[field.field]}
                onChange={(e) => updateFormState(field, e.detail.value)}
                last={i === fields.length - 1}
              />
            ))}
          </IonList>

          <BottomSpacer />
        </AppSheet>
      </AppPageWithSheet>
    </AppPageWithHideableHeader>
  );
};

export default ProfileClientAdd;

import { processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-content/pepalexa-content.scss";

const AppContent = ({ modifiers, children, innerContent, ...props }) =>
  innerContent ? (
    <div
      className={processModifiers("pepalexa-content", modifiers)}
      dangerouslySetInnerHTML={{ __html: innerContent }}
    />
  ) : (
    <div className={processModifiers("pepalexa-content", modifiers)}>
      {children}
    </div>
  );

export default AppContent;

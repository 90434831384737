import { useMemo } from "react";

import { useClientContentProvider } from "./client-content-provider.js";

export function useClientGalleryProvider(clientId) {
  const { Provider, entities, isLoading, providerId, ref } =
    useClientContentProvider(clientId, "gallery", {
      includeGroupContent: true,
      include:
        "entity_id.field_gallery_thumbnail,entity_id.field_gallery_tags,entity_id.field_gallery_file",
    });

  const galleryItems = useMemo(
    () => entities.map((i) => i.entity_id),
    [entities]
  );

  const loadMoreGalleryItems = () => {
    document.dispatchEvent(
      new CustomEvent(`zeusDataOperation:${providerId}`, {
        detail: { name: "next-page" },
      })
    );
  };

  return {
    GalleryProvider: Provider,
    galleryItems,
    hasMoreGalleryItems: Boolean(ref.current?.source?.hasNextPage),
    loadMoreGalleryItems,
    galleryIsLoading: isLoading,
  };
}

import { IonItem, IonLabel, IonModal } from "@ionic/react";
import { useState } from "react";

import EntityPreview from "../pages/manage/EntityPreview";

const BlockPreview = ({ value }) => {
  const [previewId, setPreviewId] = useState("");

  const doBlockPreview = () => {};

  return (
    <div className="app-block-selector">
      <IonModal isOpen={previewId !== ""} onDidDismiss={() => setPreviewId("")}>
        <EntityPreview id={previewId} type="exercise" dismiss={() => setPreviewId("")} />
      </IonModal>
      {value.map((block, blockIndex) => (
        <div key={blockIndex}>
          <IonItem className="level-0" onClick={() => doBlockPreview(block)}>
            <IonLabel>
              <h2>{block.field_title ?? "Block"}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: block.field_description?.value,
                }}
              />
            </IonLabel>
          </IonItem>
          {block.field_workout_block_exercises.map((exercise, exerciseIndex) => (
            <IonItem button detail key={exerciseIndex} className="level-1" onClick={() => setPreviewId(exercise.id)}>
              <IonLabel>{exercise?.title}</IonLabel>
            </IonItem>
          ))}
        </div>
      ))}
    </div>
  );
};

export default BlockPreview;

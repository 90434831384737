import { IonItem, IonRange } from "@ionic/react";

import { processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-range/pepalexa-range.scss";

const AppRange = ({
  modifiers,
  fromText,
  toText,
  value,
  onChange,
  ...props
}) => (
  <IonItem
    {...props}
    className={processModifiers("pepalexa-range", modifiers)}
    lines="none"
  >
    <IonRange
      min={0}
      max={100}
      pin={true}
      onIonChange={onChange}
      value={value}
    ></IonRange>
    <span className="pepalexa-range__from-text">{fromText}</span>
    <span className="pepalexa-range__to-text">{toText}</span>
  </IonItem>
);

export default AppRange;

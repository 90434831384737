import { useMemo } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

export function useAdminFeedProvider(activate, ts = 0) {
  const providerId = `admin-feed-${ts}`;
  const url = `${getSetting(
    "BACKEND"
  )}/api/v3/message/user_questions_completed?sort=-created&page[limit]=10&include=field_client`;

  const { data, isLoading } = useZeusDataProvider(providerId, []);

  const Provider = useMemo(() => {
    const Component = () => {
      if (!activate) {
        return null;
      }
      return (
        <zeus-data-provider id={providerId} listen-tags="feed">
          <zeus-data-source-jsonapi url={url}></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      );
    };
    return Component;
  }, [activate, providerId, url]);

  return {
    Provider,
    entities: data,
    isLoading,
  };
}

import { useState } from "react";
import {
  IonAlert,
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  useIonRouter,
} from "@ionic/react";

import { getSetting } from "../settings";
import AppContent from "../components/AppContent";
import AppHeading from "../components/AppHeading";
import {
  AppPageWithSheet,
  AppSheet,
  AppTopOfSheet,
} from "../compositions/AppPageWithSheet";
import AppPageWithHideableHeader from "../compositions/AppPageWithHideableHeader";
import AppText from "../components/AppText";
import BottomSpacer from "../components/BottomSpacer";

export const PasswordReset = () => {
  const [currentEmail, setCurrentEmail] = useState("");
  const [formState, setFormState] = useState({ code: "", newPassword: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const router = useIonRouter();

  const doSubmitEmail = async (e) => {
    e?.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(
        `${getSetting("BACKEND")}/user/change-password-code?_format=json`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "omit",
          body: JSON.stringify({ mail: currentEmail }),
        }
      );

      if (response.status !== 200) {
        throw new Error("request error");
      }

      setMessage(`Ett e-post har skickats till ${currentEmail}.`);
    } catch (error) {
      setMessage(
        "Ett fel uppstod i kommunikationen med servern. Var god försök senare."
      );
    }

    setIsLoading(false);
  };

  const doSubmitPassword = async (e) => {
    e?.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(
        `${getSetting("BACKEND")}/user/change-password?_format=json`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "omit",
          body: JSON.stringify({
            mail: currentEmail,
            code: formState.code,
            password: formState.newPassword,
          }),
        }
      );

      if (response.status === 200) {
        setShowSuccess(true);
      } else {
        setMessage(
          "Kunde inte uppdatera ditt lösenord. Kontrollera att e-postadress och engångskod stämmer."
        );
      }
    } catch (error) {
      setMessage(
        "Ett fel uppstod i kommunikationen med servervn. Var god försök senare."
      );
    }

    setIsLoading(false);
  };

  return (
    <AppPageWithHideableHeader
      title="Återställ lösenord"
      defaultHref="/login"
      backButton
    >
      <IonLoading isOpen={isLoading} />
      <IonAlert
        isOpen={message !== ""}
        message={message}
        buttons={["OK"]}
        onDidDismiss={() => setMessage("")}
        header={getSetting("APP_NAME")}
      />
      <IonAlert
        isOpen={showSuccess}
        message="Ditt lösenord har uppdaterats. Du kan nu logga in med ditt nya lösenord"
        buttons={["OK"]}
        onDidDismiss={() => {
          setShowSuccess(false);
          router.canGoBack() && router.goBack();
        }}
        header={getSetting("APP_NAME")}
      />

      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppContent modifiers="pepalexa-content--padding pepalexa-content--margin-bottom">
            <AppHeading modifiers="center">Återställ lösenord</AppHeading>
          </AppContent>
        </AppTopOfSheet>
        <AppSheet>
          <div style={{ marginBottom: "30px" }}>
            <IonList class="form">
              <IonItem lines="none">
                <IonLabel position="stacked">Registrerad e-postadress</IonLabel>
                <IonInput
                  value={currentEmail}
                  onIonChange={(event) => setCurrentEmail(event.target.value)}
                  type="email"
                />
              </IonItem>
            </IonList>

            <AppText modifiers="small-and-black">
              Fyll i din e-postadress i fältet ovanför så skickar vi en
              engångskod till din e-post som du kan använda för att återställa
              ditt lösenord.
            </AppText>
            <AppText modifiers="small-and-black">
              Glöm inte att kolla i spam-mappen om du inte hittar koden i din
              inkorg. Engångskoden är giltig i 15 minuter.
            </AppText>
            <IonButton expand="block" onClick={doSubmitEmail} color="secondary">
              Begär kod
            </IonButton>
          </div>
          <div style={{ marginBottom: "14px" }}>
            <IonList class="form">
              <IonItem lines="full">
                <IonLabel position="stacked">Kod från e-post</IonLabel>
                <IonInput
                  value={formState?.code}
                  onIonChange={(event) =>
                    setFormState({ ...formState, code: event.target.value })
                  }
                />
              </IonItem>
              <IonItem lines="none">
                <IonLabel position="stacked">Nytt lösenord</IonLabel>
                <IonInput
                  value={formState?.newPassword}
                  onIonChange={(event) =>
                    setFormState({
                      ...formState,
                      newPassword: event.target.value,
                    })
                  }
                  type="password"
                />
              </IonItem>
            </IonList>
          </div>
          <IonButton
            expand="block"
            color="secondary"
            onClick={doSubmitPassword}
          >
            Uppdatera lösenord
          </IonButton>
          <BottomSpacer />
        </AppSheet>
      </AppPageWithSheet>
    </AppPageWithHideableHeader>
  );
};

export default PasswordReset;

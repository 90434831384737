import { useParams } from "react-router";
import {
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonAvatar,
  IonLabel,
  IonItemDivider,
} from "@ionic/react";
import { settingsOutline } from "ionicons/icons";

import { useCoachProvider } from "../../providers/coach-provider";
import { useCoachClientsProvider } from "../../providers/coach-clients-provider";
import {
  AppPageWithSheet,
  AppSheet,
  AppTopOfSheet,
} from "../../compositions/AppPageWithSheet";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import BottomSpacer from "../../components/BottomSpacer";
import AppContent from "../../components/AppContent";
import AppHeading from "../../components/AppHeading";
import PrivateImage from "../../components/PrivateImage";
import { useCoachAccountsProvider } from "../../providers/coach-accounts-provider";

const ProfileCoach = () => {
  const { id } = useParams();
  const { Provider, coach } = useCoachProvider(id);
  const { Provider: ClientsProvider, clients } = useCoachClientsProvider(id);
  const { Provider: AccountProvider, accounts } = useCoachAccountsProvider(id);

  const title = `${coach?.field_first_name} ${coach?.field_last_name}`;

  return (
    <AppPageWithHideableHeader
      title={title}
      defaultHref={"/c/menu/manage/profile"}
      backButton
      toolbarButtons={
        <IonButton routerLink={`/c/menu/manage/profile/coach/${id}/edit`}>
          <IonIcon icon={settingsOutline} slot="icon-only" />
        </IonButton>
      }
    >
      <ClientsProvider />
      <AccountProvider />
      <Provider />
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppContent modifiers="pepalexa-content--padding pepalexa-content--margin-bottom">
            <AppHeading modifiers="center">{title}</AppHeading>
          </AppContent>
        </AppTopOfSheet>
        <AppSheet>
          <IonList className="light">
            <IonItem>
              <IonAvatar slot="start">
                <PrivateImage
                  defaultImage="assets/images/default-avatar.png"
                  auth="auth"
                  src={coach?.field_avatar?.image_style_uri?.medium}
                />
              </IonAvatar>
              <IonLabel>{title}</IonLabel>
            </IonItem>

            <IonItemDivider>Inloggning</IonItemDivider>
            {accounts.map((user) => (
              <IonItem key={user.id}>
                <IonLabel>{user.mail}</IonLabel>
              </IonItem>
            ))}

            <IonItemDivider>Klienter</IonItemDivider>

            {clients.map((client) => (
              <IonItem
                key={client.id}
                routerLink={`/c/menu/manage/profile/client/${client.id}`}
              >
                <IonAvatar slot="start">
                  <PrivateImage
                    defaultImage="assets/images/default-avatar.png"
                    auth="auth"
                    src={client?.field_avatar?.image_style_uri?.medium}
                  />
                </IonAvatar>

                <IonLabel>
                  {client.field_first_name} {client.field_last_name}
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
          <BottomSpacer />
        </AppSheet>
      </AppPageWithSheet>
    </AppPageWithHideableHeader>
  );
};

export default ProfileCoach;

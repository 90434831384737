import { IonTextarea } from "@ionic/react";

import { processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-textarea/pepalexa-textarea.scss";

const AppTextarea = ({ value, onChange, modifiers, ...props }) => (
  <div className={processModifiers("pepalexa-textarea", modifiers)}>
    <IonTextarea
      {...props}
      className="pepalexa-textarea__textarea"
      onIonChange={onChange}
      value={value}
    />
  </div>
);

export default AppTextarea;

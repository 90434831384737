import { useParams } from "react-router";
import {
  IonItem,
  IonList,
  IonLabel,
  IonSpinner,
  IonAvatar,
} from "@ionic/react";

import { useMessageThreadsProvider } from "../../providers/message-threads-provider";
import {
  AppPageWithSheet,
  AppSheet,
  AppTopOfSheet,
} from "../../compositions/AppPageWithSheet";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import AppContent from "../../components/AppContent";
import AppHeading from "../../components/AppHeading";
import PrivateImage from "../../components/PrivateImage";

const ClientMessages = () => {
  const { id } = useParams();

  const { Provider, threads, isLoading } = useMessageThreadsProvider(
    id,
    "client",
    []
  );

  return (
    <AppPageWithHideableHeader
      title="Alla konversationer"
      defaultHref={`/c/menu/manage/client/${id}`}
      backButton
    >
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppContent modifiers="pepalexa-content--padding pepalexa-content--margin-bottom">
            <AppHeading modifiers="center">Alla konversationer</AppHeading>
          </AppContent>
        </AppTopOfSheet>
        <AppSheet>
          <IonList className="light ion-no-padding">
            {isLoading && (
              <div className="ion-text-center">
                <IonSpinner color="dark" />
              </div>
            )}
            {threads.map((thread) => (
              <IonItem
                key={thread.key}
                routerLink={`/c/menu/manage/client/${id}/messages/${thread.key}`}
              >
                <IonAvatar slot="start">
                  <PrivateImage src={thread.image} />
                </IonAvatar>
                <IonLabel>{thread.title}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        </AppSheet>
      </AppPageWithSheet>
      <Provider />
    </AppPageWithHideableHeader>
  );
};

export default ClientMessages;

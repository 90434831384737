import { IonItem, IonLabel } from "@ionic/react";

import AppIcon from "../components/AppIcon";

import "@understandit/lofsangruppen-storybook-22173948/compositions/subscription-item/subscription-item.scss";

const AppSubscriptionItem = (props) => {
  return (
    <IonItem
      className={`subscription-item ion-item--highlighted-subtitle ${props.modifiers?.join(
        " "
      )}`}
    >
      <IonLabel>
        <p className="subscription-item__type">
          {props.type_title}
          {props.type_text && <span>{props.type_text}</span>}
        </p>
        <p className="subscription-item__period">
          {props.period_title}
          <span>{props.period_text}</span>
        </p>
      </IonLabel>
      {props.icon && <AppIcon icon={props.icon} modifiers="centered" />}
    </IonItem>
  );
};

export default AppSubscriptionItem;

const PRIVATE_FILE_CACHE = {};

function getToken(providerId) {
  const q = new Promise((resolve, reject) => {
    document.addEventListener(
      `zeusDataAvailable:${providerId}`,
      (event) => {
        if (event.detail && event.detail.tokens) {
          resolve(event.detail.tokens);
        } else {
          reject("No token");
        }
      },
      { once: true }
    );
  });
  document.dispatchEvent(new Event(`zeusDataListener:${providerId}`));
  return q;
}

export function getPrivateFile(url, tokenProviderId, signal) {
  if (PRIVATE_FILE_CACHE[url]) {
    return Promise.resolve(PRIVATE_FILE_CACHE[url]);
  }

  return getToken(tokenProviderId)
    .then((tokens) => {
      return fetch(url, {
        headers: { authorization: `Bearer ${tokens.access_token}` },
        signal,
      });
    })
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      PRIVATE_FILE_CACHE[url] = blobUrl;
      return blobUrl;
    });
}

import { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  IonButton,
  IonIcon,
  IonItem,
  IonList,
  IonLabel,
  IonSpinner,
  useIonViewWillLeave,
  useIonRouter,
  useIonViewDidEnter,
  IonMenuButton,
  IonButtons,
  IonItemDivider,
  IonReorderGroup,
  IonReorder,
} from "@ionic/react";
import { checkmarkCircleOutline, ellipsisHorizontal, reorderThreeOutline } from "ionicons/icons";

import { pageConfig } from "../../settings";
import { useActionSheet, useEntityAction, useOptionDialog } from "../../hooks";
import { useDialogs } from "../../form/hooks";
import { useConnector } from "../../form/connector-context";
import { useClientContentProvider } from "../../providers/client-content-provider";
import { AppPageWithSheet, AppSheet, AppTopOfSheet } from "../../compositions/AppPageWithSheet";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import AppContent from "../../components/AppContent";
import AppHeading from "../../components/AppHeading";
import AppSearchbar from "../../components/AppSearchbar";
import BottomSpacer from "../../components/BottomSpacer";

const ClientEntityList = ({ type }) => {
  const { id } = useParams();
  const [searchValue, setSearchValue] = useState("");
  const [searchString, setSearchString] = useState("");
  const [sortEnabled, setSortEnabled] = useState(false);
  const [published, setPublished] = useState([]);
  const config = pageConfig[type];
  const { Provider, entities, isLoading } = useClientContentProvider(id, type, {
    searchString,
    includeGroupContent: true,
    sort: type === "workout" ? "field_sorting_weight,-entity_id.created" : "-entity_id.created",
  });
  const { push } = useIonRouter();
  const { confirmDangerousAction } = useDialogs();
  const doEntityAction = useEntityAction();
  const { setConnectorIsOpen, setConnectorTargets, setConnectorCallback } = useConnector();
  const showOptionDialog = useOptionDialog();
  const showActionSheet = useActionSheet();

  const categories = {
    draft: entities.filter((x) => x.field_status === "draft"),
    published: entities.filter((x) => x.field_status === "published"),
    archived: entities.filter((x) => x.field_status === "archived"),
  };

  useEffect(() => {
    setPublished(entities.filter((x) => x.field_status === "published"));
  }, [entities]);

  const connectorCallback = async ({ entity }) => {
    const status = await showOptionDialog({
      message: "Vilken status",
      options: [
        { value: "published", label: "Publicerad" },
        { value: "draft", label: "Utkast" },
        { value: "archived", label: "Arkiverad" },
      ],
    });
    if (status) {
      doEntityAction("assign", {
        type,
        target: id,
        id: entity.id,
        status,
      });
    }
  };

  useIonViewDidEnter(() => {
    setConnectorIsOpen(true);
    setConnectorTargets([
      {
        field: `assign_${type}`,
        bundle: type,
        label: `Tilldela ${config.listTitle}`,
      },
    ]);
    setConnectorCallback({ cb: connectorCallback });
  }, [config.listTitle, id, push, setConnectorCallback, setConnectorIsOpen, setConnectorTargets, type]);

  useIonViewWillLeave(() => {
    setConnectorIsOpen(false);
    setConnectorTargets([]);
    setConnectorCallback({ cb: null });
  });

  const doShowActions = async (id, gcid, category) => {
    const buttons = [];

    if (category === "draft") {
      buttons.push({ text: "Publicera", data: "publish" });
      buttons.push({ text: "Arkivera", data: "archive" });
    }

    if (category === "published") {
      buttons.push({ text: "Flytta till utkast", data: "draft" });
      buttons.push({ text: "Arkivera", data: "archive" });
    }

    if (category === "archived") {
      buttons.push({ text: "Publicera", data: "publish" });
      buttons.push({ text: "Flytta till utkast", data: "draft" });
    }

    buttons.push({ text: "Radera", role: "destructive", data: "delete" });
    buttons.push({ text: "Avbryt", role: "cancel", data: "cancel" });

    const action = await showActionSheet({ buttons });

    if (action === "delete") {
      confirmDangerousAction({
        message: "Är du säker på att du vill ta bort innehållet?",
        button: "Ta bort",
        handler: () => {
          doEntityAction("delete", { type, id });
        },
      });
    }

    if (action === "publish") {
      doEntityAction("entity_status", { id: gcid, status: "published", type });
    }

    if (action === "archive") {
      doEntityAction("entity_status", { id: gcid, status: "archived", type });
    }

    if (action === "draft") {
      doEntityAction("entity_status", { id: gcid, status: "draft", type });
    }
  };

  const doSearch = (e) => {
    e?.preventDefault();
    setSearchString(searchValue);
  };

  const handleReorder = (event) => {
    const newOrder = event.detail.complete(published);
    setPublished(newOrder);
  };

  const doSaveOrder = () => {
    setSortEnabled(false);
    const entities = published.map((x) => x.id);
    doEntityAction("sort_group_content", { type, entities });
  };

  return (
    <AppPageWithHideableHeader
      title={config.listTitle}
      defaultHref={`/c/menu/manage/client/${id}`}
      backButton
      toolbarButtons={
        <IonButtons>
          {type === "workout" && !sortEnabled && (
            <IonButton onClick={() => setSortEnabled(true)}>
              <IonIcon icon={reorderThreeOutline} slot="icon-only" />
            </IonButton>
          )}

          {sortEnabled && (
            <IonButton onClick={doSaveOrder}>
              <IonIcon icon={checkmarkCircleOutline} slot="icon-only" />
            </IonButton>
          )}

          <IonMenuButton>
            <IonIcon icon={ellipsisHorizontal} />
          </IonMenuButton>
        </IonButtons>
      }
    >
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppContent modifiers="pepalexa-content--padding pepalexa-content--margin-bottom">
            <AppHeading modifiers="center">{config.listTitle}</AppHeading>
            <form onSubmit={doSearch} action="/">
              <AppSearchbar
                placeholder="Sök"
                inputmode="search"
                value={searchValue}
                debounce={0}
                onIonChange={(e) => setSearchValue(e.target.value)}
              />
            </form>
          </AppContent>
        </AppTopOfSheet>
        <AppSheet>
          <IonList className="light ion-no-padding">
            {isLoading && (
              <div className="ion-text-center">
                <IonSpinner color="dark" />
              </div>
            )}
            {categories.draft.length > 0 && <IonItemDivider color="light">Utkast</IonItemDivider>}
            {categories.draft.map((item) => (
              <IonItem key={item.id} routerLink={`/c/menu/manage/client/${id}/${type}/${item.entity_id.id}`}>
                <IonLabel>{item.entity_id.title}</IonLabel>
                <IonButton
                  slot="end"
                  fill="clear"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    doShowActions(item.entity_id.id, item.id, "draft");
                  }}
                >
                  <IonIcon slot="icon-only" icon={ellipsisHorizontal} />
                </IonButton>
              </IonItem>
            ))}

            {published.length > 0 && <IonItemDivider color="light">Publicerad</IonItemDivider>}
            <IonReorderGroup disabled={!sortEnabled} onIonItemReorder={handleReorder}>
              {published.map((item) => (
                <IonItem
                  key={item.id}
                  routerLink={!sortEnabled ? `/c/menu/manage/client/${id}/${type}/${item.entity_id.id}` : undefined}
                >
                  <IonReorder slot="start"></IonReorder>
                  <IonLabel>{item.entity_id.title}</IonLabel>
                  {!sortEnabled && (
                    <IonButton
                      slot="end"
                      fill="clear"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        doShowActions(item.entity_id.id, item.id, "published");
                      }}
                    >
                      <IonIcon slot="icon-only" icon={ellipsisHorizontal} />
                    </IonButton>
                  )}
                </IonItem>
              ))}
            </IonReorderGroup>

            {categories.archived.length > 0 && <IonItemDivider color="light">Arkiv</IonItemDivider>}
            {categories.archived.map((item) => (
              <IonItem key={item.id} routerLink={`/c/menu/manage/client/${id}/${type}/${item.entity_id.id}`}>
                <IonLabel>{item.entity_id.title}</IonLabel>
                <IonButton
                  slot="end"
                  fill="clear"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    doShowActions(item.entity_id.id, item.id, "archived");
                  }}
                >
                  <IonIcon slot="icon-only" icon={ellipsisHorizontal} />
                </IonButton>
              </IonItem>
            ))}
          </IonList>
          <BottomSpacer />
        </AppSheet>
      </AppPageWithSheet>
      <Provider />
    </AppPageWithHideableHeader>
  );
};

export default ClientEntityList;

import AppLabel from "../components/AppLabel";
import AppRange from "../components/AppRange";

import "@understandit/lofsangruppen-storybook-22173948/compositions/range-with-label/range-with-label.scss";

const AppRangeWithLabel = ({ value, onChange, ...props }) => {
  return (
    <div className="range-with-label">
      <AppLabel modifiers="padding">{props.label}</AppLabel>
      <AppRange
        toText={props.to_text}
        fromText={props.from_text}
        value={value}
        onChange={onChange}
      ></AppRange>
    </div>
  );
};

export default AppRangeWithLabel;

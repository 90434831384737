import { useMemo, useState } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

export function useClientProvider(clientId) {
  const [randomProviderId] = useState(Math.random);
  const providerId = `client-provider-${clientId}-${randomProviderId}`;
  const url = `${getSetting(
    "BACKEND"
  )}/api/v3/group/client/${clientId}?include=field_avatar,field_profile_tags,field_subscriptions`;
  const { data, isLoading, setData } = useZeusDataProvider(providerId);

  const Provider = useMemo(() => {
    const Component = () => (
      <zeus-data-provider
        id={providerId}
        listen-tags="client"
        send-tags="client"
      >
        <zeus-data-source-jsonapi
          type="group--client"
          attributes="label,field_first_name,field_last_name,field_avatar,field_street,field_postal,field_city,field_country,field_phone,field_profile_tags,field_archived,field_subscriptions"
          relationships="field_avatar:file--file,field_profile_tags:taxonomy_term--profile_tags,field_subscriptions:paragraph--subscription_manual"
          url={url}
          api-root={`${getSetting("BACKEND")}/api/v3`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    );
    return Component;
  }, [providerId, url]);

  return {
    Provider,
    client: data,
    isLoading,
    setData,
    providerId,
  };
}

import { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonContent,
  IonList,
  IonLoading,
} from "@ionic/react";
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";
import { useCreateMessageMutation } from "../api/messages";

const AddRichMessage = ({ actionParams, dismiss, threads, mode }) => {
  const { id, type, entity, index, threadId, hideThreadSelector } = actionParams;
  const { mutateAsync } = useCreateMessageMutation(mode);

  const [message, setMessage] = useState("");
  const [messageThread, setMessageThread] = useState(threadId ?? "");
  const [isLoading, setIsLoading] = useState(false);

  const exerciseTitle = entity?.field_public_title ?? entity?.title ?? "";

  const onMessageSend = async (message) => {
    const thread = threads.find((i) => i.id === messageThread);
    const richMessage = type === "workout" ? "Övning: " + exerciseTitle + "\n\n" + message : message;

    setIsLoading(true);

    try {
      await mutateAsync({
        id,
        message: richMessage,
        thread,
        richContent: {
          type,
          id,
          index,
        },
      });
    } catch (error) {}

    setIsLoading(false);
    dismiss();
  };

  return (
    <>
      <IonLoading isOpen={isLoading} />
      <IonHeader className="app-header">
        <IonToolbar>
          <IonTitle>Skicka meddelande</IonTitle>
          <IonButtons slot="secondary">
            <IonButton onClick={dismiss}>
              <IonIcon slot="icon-only" icon={closeCircleOutline} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton disabled={message === "" || messageThread === null} onClick={(e) => onMessageSend(message)}>
              <IonIcon icon={checkmarkCircleOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {!hideThreadSelector && (
            <IonItem>
              <IonLabel>Till Coach</IonLabel>
              <IonSelect
                interface="popover"
                value={messageThread}
                onIonChange={(e) => setMessageThread(e.detail.value)}
                placeholder="Välj coach från listan"
              >
                {threads?.map((thread) => (
                  <IonSelectOption key={thread.id} value={thread.id}>
                    {thread.title}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          )}
          {type === "workout" && (
            <IonItem>
              <IonLabel>Övning: {exerciseTitle}</IonLabel>
            </IonItem>
          )}
          <IonItem lines="none">
            <textarea
              style={{
                border: 0,
                width: "100%",
                resize: "none",
                focus: "none",
              }}
              placeholder="Meddelande"
              value={message}
              rows={8}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </IonItem>
        </IonList>
      </IonContent>
    </>
  );
};

export default AddRichMessage;

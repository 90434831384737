import { useParams } from "react-router";
import { IonItem, IonList, IonLabel, IonSpinner } from "@ionic/react";

import {
  AppPageWithSheet,
  AppSheet,
  AppTopOfSheet,
} from "../../compositions/AppPageWithSheet";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import AppContent from "../../components/AppContent";
import AppHeading from "../../components/AppHeading";
import { useClientProvider } from "../../providers/client-provider";
import { useClientGroupContentProvider } from "../../providers/client-group_content-provider";

const ClientProfile = ({ defaultHref }) => {
  const { id } = useParams();
  const { Provider, client, isLoading } = useClientProvider(id);
  const { Provider: QuestionsProvider, entities: questions } =
    useClientGroupContentProvider(client?.id, "question", {sort: "created"});

  return (
    <AppPageWithHideableHeader
      title="Profil"
      defaultHref={defaultHref ?? `/c/manage/client/${id}`}
      backButton
    >
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppContent modifiers="pepalexa-content--padding pepalexa-content--margin-bottom">
            <AppHeading modifiers="center">Profil</AppHeading>
          </AppContent>
        </AppTopOfSheet>
        <AppSheet>
          <IonList className="light ion-no-padding">
            {isLoading && (
              <div className="ion-text-center">
                <IonSpinner color="dark" />
              </div>
            )}
            <IonItem>
              <IonLabel position="stacked">Förnamn</IonLabel>
              {client?.field_first_name}
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Efternamn</IonLabel>
              {client?.field_last_name}
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Adress</IonLabel>
              {client?.field_street}
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Postnummer</IonLabel>
              {client?.field_postal}
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Ort</IonLabel>
              {client?.field_city}
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Telefon</IonLabel>
              {client?.field_phone}
            </IonItem>
            {questions?.map((question) => (
              <IonItem key={question.id}>
                <IonLabel position="stacked">
                  {question.entity_id.title}
                </IonLabel>
                <div className="profile-answer">
                  {question?.field_question_answer}
                </div>
              </IonItem>
            ))}
          </IonList>
        </AppSheet>
      </AppPageWithSheet>
      <Provider />
      <QuestionsProvider />
    </AppPageWithHideableHeader>
  );
};

export default ClientProfile;

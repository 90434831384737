import { useMemo, useRef } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

export function useClientContentProvider(clientId, type, settings) {
  const providerId = `client-${type}-provider-${clientId}`;

  const params = {
    "filter[gid.id]": clientId,
    include: settings?.include ?? "entity_id",
    sort: settings?.sort ?? "-entity_id.created",
    "page[offset]": settings?.offset ?? 0,
  };

  if (settings?.searchString) {
    params["filter[title][condition][path]"] = "entity_id.title";
    params["filter[title][condition][operator]"] = "CONTAINS";
    params["filter[title][condition][value]"] = settings?.searchString;
  }

  const includeGroupContent = settings?.includeGroupContent ?? false;
  const includeMeta = settings?.includeMeta ?? null;

  const url = `${getSetting(
    "BACKEND"
  )}/api/v3/group_content/client-group_node-${type}?${new URLSearchParams(
    params
  )}`;

  const { data, isLoading } = useZeusDataProvider(providerId, []);
  const ref = useRef();

  const Provider = useMemo(() => {
    const Component = () => {
      if (!clientId) {
        return null;
      }
      return (
        <zeus-data-provider ref={ref} id={providerId} listen-tags={type}>
          <zeus-data-source-jsonapi
            url={url}
            include-meta={includeMeta}
          ></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
          {!includeGroupContent && (
            <zeus-data-processor-jmespath filter="[*].entity_id"></zeus-data-processor-jmespath>
          )}
        </zeus-data-provider>
      );
    };
    return Component;
  }, [clientId, includeGroupContent, includeMeta, providerId, type, url]);

  return {
    ref,
    providerId,
    Provider,
    entities: data,
    isLoading,
  };
}

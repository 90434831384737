import { useState, useEffect, useCallback } from "react";
import {
  IonLoading,
  IonList,
  IonSpinner,
  IonItem,
  IonAvatar,
  IonCheckbox,
  IonButton,
  IonIcon,
  IonRow,
  IonCol,
  IonLabel,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
  useIonViewWillLeave,
  useIonViewDidEnter,
} from "@ionic/react";
import { useZeusDataProvider, useZeusDataStatusSubscriber } from "@understandit/dynamic-data-react";
import { checkboxOutline as selectIcon, ellipsisHorizontal } from "ionicons/icons";

import { useEntityAction, useOptionDialog } from "../../hooks";
import { useConnector } from "../../form/connector-context";
import { getSetting } from "../../settings";
import { AppPageWithSheet, AppTopOfSheet, AppSheet } from "../../compositions/AppPageWithSheet";
import AppContent from "../../components/AppContent";
import AppHeading from "../../components/AppHeading";
import AppSearchbar from "../../components/AppSearchbar";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import PrivateImage from "../../components/PrivateImage";
import BottomSpacer from "../../components/BottomSpacer";

function buildSearchUrl(searchString, coachId) {
  const path = `${getSetting("BACKEND")}/api/v3/group_content/client-subgroup-coach`;

  const params = {
    "filter[entity_id.id]": coachId,
    include: "gid.field_avatar",
    sort: "gid.field_last_name,gid.field_first_name",
  };

  if (searchString) {
    params["filter[title-filter][condition][path]"] = "gid.label";
    params["filter[title-filter][condition][operator]"] = "CONTAINS";
    params["filter[title-filter][condition][value]"] = searchString;
  }

  return `${path}?${new URLSearchParams(params)}`;
}

const ClientList = ({ backButton = true, defaultHref = "/c/menu" }) => {
  const providerId = `coach-client-list`;
  const [searchValue, setSearchValue] = useState("");
  const [searchString, setSearchString] = useState("");
  const [searchUrl, setSearchUrl] = useState("");
  const { data: coach } = useZeusDataProvider("collection");
  const { data: clients, isLoading } = useZeusDataProvider(providerId, []);
  const { setConnectorIsOpen, setConnectorTargets, setConnectorCallback } = useConnector();
  const [selectMode, setSelectMode] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [showSendMessage, setShowSendMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [closeMessageThreads, setCloseMessageThreads] = useState(false);
  const [messageInTransit, setMessageInTransit] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const { setData: sendMessages } = useZeusDataProvider("send-messages");
  const showOptionDialog = useOptionDialog();
  const doEntityAction = useEntityAction();

  const doSearch = (e) => {
    e?.preventDefault();
    setSearchString(searchValue);
  };

  const doToggleSelectMode = () => {
    const value = !selectMode;
    setSelectMode(value);
  };

  const connectorCallback = useCallback(
    async ({ entity, field }) => {
      const status = await showOptionDialog({
        message: "Vilken status",
        options: [
          { value: "published", label: "Publicerad" },
          { value: "draft", label: "Utkast" },
          { value: "archived", label: "Arkiverad" },
        ],
      });
      if (status) {
        console.log(entity);
        console.log(selectedRows);
        doEntityAction("multi-assign", {
          type: field.bundle,
          targets: Object.keys(selectedRows),
          id: entity.id,
          status,
        });
      }
    },
    [doEntityAction, selectedRows, showOptionDialog]
  );

  useIonViewDidEnter(() => {
    setConnectorTargets([
      {
        field: "assign_workout",
        bundle: "workout",
        label: "Tilldela träningspass",
      },
      {
        field: "assign_diet",
        bundle: "diet",
        label: "Tilldela kostråd",
      },
    ]);
    setConnectorCallback({ cb: connectorCallback });
  }, [setConnectorTargets, setConnectorCallback, connectorCallback]);

  useIonViewWillLeave(() => {
    setConnectorIsOpen(false);
    setConnectorTargets([]);
    setConnectorCallback({ cb: null });
  });

  useEffect(() => {
    setSearchUrl(buildSearchUrl(searchString, coach?.id));
  }, [searchString, coach]);

  useEffect(() => {
    const callback = async ({ entity, field }) => {
      const status = await showOptionDialog({
        message: "Vilken status",
        options: [
          { value: "published", label: "Publicerad" },
          { value: "draft", label: "Utkast" },
          { value: "archived", label: "Arkiverad" },
        ],
      });
      if (status) {
        doEntityAction("multi-assign", {
          type: field.bundle,
          targets: Object.keys(selectedRows),
          id: entity.id,
          status,
        });
      }
    };

    setConnectorCallback({ cb: callback });
  }, [selectedRows, setConnectorCallback]);

  useEffect(() => {
    if (!selectMode) {
      setSelectedRows({});
      setConnectorIsOpen(false);
    }
  }, [selectMode, setConnectorIsOpen]);

  const onRowSelectChange = (item) => {
    const newSelectedRows = { ...selectedRows };
    if (item.id in newSelectedRows) {
      delete newSelectedRows[item.id];
    } else {
      newSelectedRows[item.id] = item.id;
    }
    setSelectedRows(newSelectedRows);
    setConnectorIsOpen(Object.keys(newSelectedRows).length > 0);
  };

  const onMessageChange = (e) => {
    setMessage(e.target.value);
    e.target.style.height = "inherit";
    e.target.style.height = `${e.target.scrollHeight}px`;
    e.target.style.height = `${Math.min(e.target.scrollHeight, 160)}px`;
  };

  const onMessageSend = (message) => {
    console.log("onMessageSend", message);
    setMessageInTransit(true);
    sendMessages({
      coach: coach.id,
      clients: Object.values(selectedRows),
      message: message,
      close_threads: closeMessageThreads,
    });
  };

  useZeusDataStatusSubscriber("send-messages", (status) => {
    console.log(status);
    if (status === "updated") {
      setMessageInTransit(false);
      setMessage("");
      setMessageSent(true);
    }
  });

  return (
    <AppPageWithHideableHeader
      title="Klienter"
      backButton={backButton}
      defaultHref={defaultHref}
      toolbarButtons={
        <IonButtons>
          <IonButton onClick={doToggleSelectMode}>
            <IonIcon slot="icon-only" icon={selectIcon} />
          </IonButton>

          <IonMenuButton>
            <IonIcon icon={ellipsisHorizontal} />
          </IonMenuButton>
        </IonButtons>
      }
    >
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppContent modifiers="pepalexa-content--padding pepalexa-content--margin-bottom">
            <AppHeading modifiers="center">Klienter</AppHeading>

            <form onSubmit={doSearch} action="/">
              <AppSearchbar
                placeholder="Sök"
                inputmode="search"
                value={searchValue}
                debounce={0}
                onIonChange={(e) => setSearchValue(e.target.value)}
              />
            </form>
          </AppContent>
        </AppTopOfSheet>
        <AppSheet>
          <IonList class="light ion-no-padding">
            {isLoading && (
              <div className="ion-text-center">
                <IonSpinner color="dark" />
              </div>
            )}
            {clients.map((item) => (
              <IonItem key={item.id} routerLink={selectMode ? null : `/c/menu/manage/client/${item.id}`}>
                {selectMode && (
                  <IonCheckbox
                    slot="end"
                    checked={selectedRows[item.id] ? true : false}
                    onClick={() => onRowSelectChange(item)}
                  ></IonCheckbox>
                )}
                <IonAvatar slot="start">
                  <PrivateImage
                    auth="auth"
                    src={item.field_avatar?.image_style_uri?.medium}
                    defaultImage="assets/images/default-avatar.png"
                  />
                </IonAvatar>
                {item.field_first_name} {item.field_last_name}
              </IonItem>
            ))}
          </IonList>
          {Object.values(selectedRows).length && (
            <IonRow>
              <IonCol>
                <IonButton onClick={() => setShowSendMessage(true)}>Skicka meddelande</IonButton>
              </IonCol>
            </IonRow>
          )}
          <BottomSpacer />
        </AppSheet>
      </AppPageWithSheet>
      <IonModal isOpen={showSendMessage} backdropDismiss={false}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Skicka meddelande</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowSendMessage(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonRow className="message_row">
          <IonCol className="message_columns">
            <IonItem lines="none">
              <textarea
                style={{
                  border: 0,
                  width: "100%",
                  resize: "none",
                  focus: "none",
                }}
                placeholder="Meddelande"
                value={message}
                rows={1}
                onChange={onMessageChange}
              ></textarea>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol class="message_columns" size="6">
            <IonItem>
              <IonCheckbox
                onClick={() => setCloseMessageThreads(!closeMessageThreads)}
                checked={closeMessageThreads}
                slot="start"
                color="light"
                style={{ "--border-color": "white" }}
              />
              <IonLabel>Stäng trådar</IonLabel>
            </IonItem>
          </IonCol>
          <IonCol class="message_columns" size="6">
            <IonButton
              clear
              color="primary"
              disabled={message === "" || messageInTransit}
              onClick={(e) => onMessageSend(message)}
            >
              Skicka
            </IonButton>
          </IonCol>
        </IonRow>
        <IonLoading
          isOpen={messageInTransit}
          message={"Skickar..."}
          duration={30000}
          onDidDismiss={() => {
            setMessageInTransit(false);
          }}
        />
        <IonLoading
          isOpen={messageSent}
          message={"Skickat!"}
          duration={2000}
          onDidDismiss={() => setMessageSent(false)}
          spinner={""}
        />
      </IonModal>
      <zeus-data-provider id="send-messages" write-only={true}>
        <zeus-data-source-json
          url={`${getSetting("BACKEND")}/api/send-messages`}
          data-action="create"
        ></zeus-data-source-json>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
      {searchUrl && (
        <zeus-data-provider id={providerId}>
          <zeus-data-source-jsonapi url={searchUrl}></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
          <zeus-data-processor-jmespath filter="[*].gid"></zeus-data-processor-jmespath>
        </zeus-data-provider>
      )}
    </AppPageWithHideableHeader>
  );
};

export default ClientList;

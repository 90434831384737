import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonTitle,
  IonList,
  IonLabel,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonLoading,
} from "@ionic/react";
import { closeCircleOutline, checkmarkCircleOutline } from "ionicons/icons";
import {
  useZeusDataProvider,
  useZeusDataStatusSubscriber,
} from "@understandit/dynamic-data-react";

import { useError } from "../hooks";
import { useClientContentCreateProvider } from "../providers/client-content-create-provider";
import AppContent from "../components/AppContent";

const GalleryAdd = ({ formState, setFormState, dismiss, groupId }) => {
  const { data: galleryTags } = useZeusDataProvider("gallery-tags", []);
  const { setData, Provider, providerId, isLoading } =
    useClientContentCreateProvider("gallery");
  const showError = useError();
  useZeusDataStatusSubscriber(providerId, (status) => {
    if (status === "created") {
      dismiss();
    }
  });

  const doSave = () => {
    if (!formState.field_gallery_tags) {
      showError("Du måste välja kategori.");
      return;
    }
    const data = {
      title: `Gallery post for ${groupId} ${new Date()}`,
      field_gallery_text: formState.field_gallery_text,
      _group: groupId,
      _relationships: [
        {
          field: "field_gallery_tags",
          data: [
            {
              type: "taxonomy_term--gallery_tags",
              id: formState.field_gallery_tags,
            },
          ],
        },
      ],
      _files: [
        {
          field: "field_gallery_file",
          path: formState.field_gallery_file.path,
          type: "file--file",
          filename: formState.field_gallery_file.filename,
        },
        {
          field: "field_gallery_thumbnail",
          path: formState.field_gallery_thumbnail.path,
          type: "file--file",
          filename: formState.field_gallery_thumbnail.filename,
        },
      ],
    };
    setData(data);
  };

  return (
    <IonPage>
      <IonLoading isOpen={isLoading} />
      <IonHeader className="app-header">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={dismiss}>
              <IonIcon icon={closeCircleOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>Nytt inlägg</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={doSave}>
              <IonIcon icon={checkmarkCircleOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="image-preview-container">
          <img src={formState.field_gallery_thumbnail.preview} alt="" />
        </div>
        <AppContent modifiers="padding">
          <IonList className="form">
            <IonItem lines="full">
              <IonLabel position="stacked">Kategori</IonLabel>
              <IonSelect
                placeholder="Välj kategori"
                interface="popover"
                value={formState.field_gallery_tags}
                onIonChange={(e) =>
                  setFormState({
                    ...formState,
                    field_gallery_tags: e.detail.value,
                  })
                }
              >
                {galleryTags.map((tag) => (
                  <IonSelectOption key={tag.id} value={tag.id}>
                    {tag.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem lines="none">
              <IonLabel position="stacked">Beskrivning</IonLabel>
              <IonTextarea
                rows={5}
                value={formState.field_gallery_text}
                onIonChange={(e) =>
                  setFormState({
                    ...formState,
                    field_gallery_text: e.detail.value,
                  })
                }
              />
            </IonItem>
          </IonList>
        </AppContent>
      </IonContent>
      <Provider />
    </IonPage>
  );
};

export default GalleryAdd;

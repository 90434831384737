import { IonSearchbar } from "@ionic/react";

import { processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-searchbar/pepalexa-searchbar.scss";

const AppSearchbar = ({ modifiers, ...props }) => (
  <IonSearchbar
    {...props}
    className={processModifiers("pepalexa-searchbar", modifiers)}
  />
);

export default AppSearchbar;

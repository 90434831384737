import { Link } from "react-router-dom";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-horizontal-card/pepalexa-horizontal-card.scss";

const AppHorizontalCard = ({ title, tag, routerLink, media, ...props }) => {
  const inner = (
    <>
      <div className="pepalexa-horizontal-card__content">
        <h2 className="pepalexa-horizontal-card__title">{title}</h2>
        <span className="pepalexa-horizontal-card__tag">{tag}</span>
      </div>
      <div className="pepalexa-horizontal-card__media">
        <img src={media} alt="" />
      </div>
    </>
  );
  return routerLink ? (
    <Link className="pepalexa-horizontal-card" to={routerLink}>
      {inner}
    </Link>
  ) : (
    <div className="pepalexa-horizontal-card">{inner}</div>
  );
};

export default AppHorizontalCard;

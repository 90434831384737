import { IonIcon } from "@ionic/react";

import { processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-icon-block/pepalexa-icon-block.scss";

const AppIconBlock = ({ modifiers, icon, children, onClick, ...props }) => {
  return (
    <div
      onClick={onClick}
      className={processModifiers("pepalexa-icon-block", modifiers)}
      {...props}
    >
      <IonIcon className="pepalexa-icon-block__icon" src={icon} alt="icon" />
      <p className="pepalexa-icon-block__text">{children}</p>
    </div>
  );
};

export default AppIconBlock;

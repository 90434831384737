import { useCallback, useMemo, useState } from "react";
import { IonGrid, IonRow, IonList, IonButton, IonIcon } from "@ionic/react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";
import { settingsOutline } from "ionicons/icons";

import { useAppState } from "../app-context";
import { formatMediumDateTime } from "../util";
import Content from "../components/AppContent";
import MediaWithOverlap from "../components/AppMediaWithOverlap";
import Heading from "../components/AppHeading";
import IconWithOverlap from "../compositions/AppIconWithOverlap";
import NotificationListItem from "../compositions/AppNotificationListItem";
import AppPageWithHideableHeader from "../compositions/AppPageWithHideableHeader";
import {
  AppPageWithSheet,
  AppSheet,
  AppTopOfSheet,
} from "../compositions/AppPageWithSheet";
import PrivateImage from "../components/PrivateImage";
import BottomSpacer from "../components/BottomSpacer";

const NotificationItem = ({ item, context }) => {
  const messageSender = (item) => {
    if (!item.field_content) {
      return "Borttaget";
    }
    if (context === "client") {
      return (
        item.field_content?.field_messsage_thread?.field_message_thread_coach
          .field_first_name +
        " " +
        item.field_content?.field_messsage_thread?.field_message_thread_coach
          .field_last_name
      );
    }
    if (context === "coach") {
      return (
        item.field_content?.field_messsage_thread?.field_message_thread_client
          .field_first_name +
        " " +
        item.field_content?.field_messsage_thread?.field_message_thread_client
          .field_last_name
      );
    }
  };

  if (item.type === "diet") {
    return (
      <NotificationListItem
        link={`/c/dashboard/diet/${item.field_group_content?.entity_id.id}`}
        key={item.id}
        title={
          item.field_group_content?.entity_id?.field_public_title ??
          item.field_group_content?.entity_id?.title
        }
        subtitle={`Kostråd - ${formatMediumDateTime(item.created)}`}
        icon="assets/icons/diet.svg"
      />
    );
  }
  if (item.type === "workout") {
    return (
      <NotificationListItem
        link={`/c/dashboard/workout/${item.field_group_content?.entity_id.id}`}
        key={item.id}
        title={item.field_group_content?.label}
        subtitle={`Träningsprogram  - ${formatMediumDateTime(item.created)}`}
        icon="assets/icons/timer.svg"
      />
    );
  }
  if (item.type === "message") {
    const threadId = item.field_content?.field_messsage_thread?.id;
    if (!threadId) {
      return null;
    }
    return (
      <NotificationListItem
        link={threadId ? `/c/messages/${threadId}` : undefined}
        key={item.id}
        title={item.field_group_content?.label ?? "Borttaget"}
        subtitle={`${messageSender(item)} - ${formatMediumDateTime(
          item.created
        )}`}
        icon="assets/icons/message.svg"
      />
    );
  }

  if (item.type === "workout_log_created") {
    return (
      <NotificationListItem
        link={`/c/dashboard/workout-log/${item.field_group_content?.gid.id}/${item.field_group_content?.entity_id.id}`}
        key={item.id}
        title="Träningspass loggat"
        subtitle={`${item.field_group_content?.gid.field_first_name} ${
          item.field_group_content?.gid.field_last_name
        } - ${formatMediumDateTime(item.created)}`}
        icon="assets/icons/activity.svg"
      />
    );
  }
  if (item.type === "gallery_post_created") {
    return (
      <NotificationListItem
        link={`/c/dashboard/gallery/${item.field_group_content?.gid.id}/${item.field_group_content?.entity_id.id}`}
        key={item.id}
        title="Inlägg i galleriet"
        subtitle={`${item.field_group_content?.gid.field_first_name} ${
          item.field_group_content?.gid.field_last_name
        } - ${formatMediumDateTime(item.created)}`}
        icon="assets/icons/images.svg"
      />
    );
  }
  if (item.type === "questions_answered") {
    return (
      <NotificationListItem
        key={item.id}
        link={`/c/dashboard/profile/${item.field_client?.id}`}
        title="Frågor ifyllda"
        subtitle={`${item.field_client?.field_first_name} ${
          item.field_client?.field_last_name
        } - ${formatMediumDateTime(item.created)}`}
        icon="assets/icons/profiles.svg"
      />
    );
  }

  return null;
};

const Client = ({ client }) => {
  const [filter, setFilter] = useState("");
  const { clientFeed, coaches } = useAppState();
  const toggleFilter = (value) => {
    setFilter((curr) => (curr !== value ? value : ""));
  };

  return (
    <AppPageWithSheet>
      <AppTopOfSheet>
        <MediaWithOverlap
          media={
            <PrivateImage
              auth="auth"
              src={client?.field_avatar?.image_style_uri?.medium}
              defaultImage="assets/images/default-avatar.png"
            />
          }
          mediaOverlap={
            coaches.length > 0
              ? coaches.map((coach) => (
                  <PrivateImage
                    key={coach.id}
                    auth="auth"
                    src={coach?.field_avatar?.image_style_uri?.medium}
                    defaultImage="assets/images/default-avatar.png"
                  />
                ))
              : null
          }
        />
        <Content modifiers="text-center">
          <Heading modifiers="highlighted">
            {client?.field_first_name} {client?.field_last_name}
          </Heading>
          {coaches.length > 0 && (
            <p>
              Coach{" "}
              {coaches.map((coach, index, arr) => (
                <span key={coach.id} className="color-secondary">
                  {coach.field_first_name} {coach.field_last_name}
                  {index < arr.length - 1 && " / "}
                </span>
              ))}
            </p>
          )}
        </Content>
        <IonGrid>
          <IonRow>
            <IconWithOverlap
              pointer={true}
              selected={filter === "workout"}
              onClick={() => toggleFilter("workout")}
              icon="assets/icons/timer.svg"
              text="Träning"
            />
            <IconWithOverlap
              pointer={true}
              selected={filter === "diet"}
              onClick={() => toggleFilter("diet")}
              icon="assets/icons/diet.svg"
              text="Kostråd"
            />
            <IconWithOverlap
              pointer={true}
              selected={filter === "message"}
              onClick={() => toggleFilter("message")}
              icon="assets/icons/message.svg"
              text="Meddelanden"
            />
          </IonRow>
        </IonGrid>
      </AppTopOfSheet>
      <AppSheet>
        <IonList className="notification-list">
          {clientFeed
            .filter((i) => filter === "" || i.type === filter)
            .map((item) => (
              <NotificationItem key={item.id} item={item} context="client" />
            ))}
        </IonList>
        <BottomSpacer />
      </AppSheet>
    </AppPageWithSheet>
  );
};

const Coach = ({ coach }) => {
  const [filter, setFilter] = useState("");
  const toggleFilter = (value) => {
    setFilter((curr) => (curr !== value ? value : ""));
  };

  const { coachFeed, isAdmin, adminFeed } = useAppState();

  const feed = useMemo(() => {
    if (isAdmin) {
      const withType = adminFeed.map((i) => ({
        ...i,
        type: "questions_answered",
      }));
      return [...withType, ...coachFeed];
    }

    return coachFeed;
  }, [isAdmin, adminFeed, coachFeed]);

  const filterFeed = useCallback(
    (item) => {
      if (filter === "") {
        return true;
      }
      if (filter === "admin") {
        return item.type === "questions_answered";
      }
      if (filter === "message") {
        return item.type === "message";
      }
      if (filter === "clients") {
        return item.type !== "message" && item.type !== "questions_answered";
      }
    },
    [filter]
  );

  return (
    <AppPageWithSheet>
      <AppTopOfSheet>
        <MediaWithOverlap
          media={
            <PrivateImage
              auth="auth"
              src={coach?.field_avatar?.image_style_uri?.medium}
              defaultImage="assets/images/default-avatar.png"
            />
          }
        />
        <Content modifiers="text-center">
          <Heading modifiers="highlighted">
            {coach?.field_first_name} {coach?.field_last_name}
          </Heading>
        </Content>
        <IonGrid>
          <IonRow>
            {isAdmin && (
              <IconWithOverlap
                pointer={true}
                selected={filter === "admin"}
                onClick={() => toggleFilter("admin")}
                icon="assets/icons/profiles.svg"
                text="Klienter"
              />
            )}

            <IconWithOverlap
              pointer={true}
              selected={filter === "clients"}
              onClick={() => toggleFilter("clients")}
              icon="assets/icons/clients.svg"
              text="Aktivitetslogg"
            />
            <IconWithOverlap
              pointer={true}
              selected={filter === "message"}
              onClick={() => toggleFilter("message")}
              icon="assets/icons/message.svg"
              text="Meddelanden"
            />
          </IonRow>
        </IonGrid>
      </AppTopOfSheet>
      <AppSheet>
        <IonList>
          {feed.filter(filterFeed).map((item) => (
            <NotificationItem key={item.id} item={item} context="coach" />
          ))}
        </IonList>
        <BottomSpacer />
      </AppSheet>
    </AppPageWithSheet>
  );
};

const Dashboard = ({ mode }) => {
  const { data: client } = useZeusDataProvider("client");
  const { data: coach } = useZeusDataProvider("collection");

  return (
    <AppPageWithHideableHeader
      title="Hem"
      toolbarButtons={
        <IonButton routerLink="/c/dashboard/profile">
          <IonIcon slot="icon-only" icon={settingsOutline} />
        </IonButton>
      }
    >
      {mode === "client" && <Client client={client} />}
      {mode === "coach" && <Coach coach={coach} />}
    </AppPageWithHideableHeader>
  );
};

export default Dashboard;

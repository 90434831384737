import { useEffect, useState } from "react";

import { getPrivateFile } from "../private-files";

const PrivateImage = ({
  src,
  auth,
  alt,
  className,
  defaultImage = "",
  width,
  height,
  style,
}) => {
  const [imageSrc, setImageSrc] = useState(defaultImage);

  useEffect(() => {
    if (src) {
      const controller = new AbortController();
      const signal = controller.signal;
      getPrivateFile(src, auth, signal).then((iSrc) => setImageSrc(iSrc));
      return () => controller.abort();
    }
  }, [auth, src]);

  return (
    <img
      width={width}
      height={height}
      src={imageSrc}
      alt={alt}
      className={className}
      style={style}
    />
  );
};

export default PrivateImage;

import { useEffect } from 'react';
import OneSignal from 'onesignal-cordova-plugin';
import { getSetting } from "./settings";

let isInitialized = false;

export function useOneSignal(cb) {

  const onesignalApp = getSetting('ONESIGNAL_APP');

  if (!isInitialized && window.cordova && onesignalApp) {
    OneSignal.setAppId(onesignalApp);
    OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
      console.log("User accepted notifications: " + accepted);
    });
    isInitialized = true;
  }

  useEffect(() => {
    if (window.cordova) {
      OneSignal.setNotificationOpenedHandler(cb);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

}

export function setExternalUserId(externalUserId) {
  if (window.cordova) {
    OneSignal.setExternalUserId(externalUserId);
  }
}

export function removeExternalUserId() {
  if (window.cordova) {
    OneSignal.removeExternalUserId();
  }
}

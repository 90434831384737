import { useMemo } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

export function useContentProvider(id, type, settings) {
  const providerId = `node-${type}-provider-${id}`;
  const params = {};

  if (settings?.include) {
    params.include = settings.include;
  }

  const apiRoot = `${getSetting("BACKEND")}/api/v3`;
  const url = `${apiRoot}/node/${type}/${id}?${new URLSearchParams(params)}`;

  const { data, isLoading, setData } = useZeusDataProvider(providerId);

  const Provider = useMemo(() => {
    const Component = () => {
      if (!id) {
        return null;
      }
      return (
        <zeus-data-provider id={providerId} listen-tags={type} send-tags={type}>
          <zeus-data-source-jsonapi
            url={url}
            type={`node--${type}`}
            data-action={settings?.action}
            attributes={settings?.attributes}
            relationships={settings?.relationships}
            api-root={apiRoot}
          ></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      );
    };
    return Component;
  }, [
    id,
    providerId,
    type,
    url,
    settings?.action,
    settings?.attributes,
    settings?.relationships,
    apiRoot,
  ]);

  return {
    Provider,
    providerId,
    entity: data,
    isLoading,
    setData,
  };
}

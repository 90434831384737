import { processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-container/pepalexa-container.scss";

const AppContainer = ({ modifiers, children, ...props }) => (
  <div className={processModifiers("pepalexa-container", modifiers)} {...props}>
    {children}
  </div>
);

export default AppContainer;

import { useEffect, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonTitle,
  IonList,
  IonLabel,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonTextarea,
} from "@ionic/react";
import { closeCircleOutline, checkmarkCircleOutline } from "ionicons/icons";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import AppContent from "../components/AppContent";
import PrivateImage from "../components/PrivateImage";

const GalleryEdit = ({ onDismiss, galleryItem, setData }) => {
  const { data: galleryTags } = useZeusDataProvider("gallery-tags", []);
  const [formState, setFormState] = useState({
    field_gallery_text: "",
    field_gallery_tags: "",
  });

  useEffect(() => {
    if (galleryItem) {
      setFormState({
        field_gallery_text: galleryItem.field_gallery_text,
        field_gallery_tags: galleryItem.field_gallery_tags?.id ?? "",
      });
    }
  }, [galleryItem]);

  const doSave = () => {
    setData({
      id: galleryItem.id,
      field_gallery_text: formState.field_gallery_text,
      field_gallery_tags: {
        id: formState.field_gallery_tags,
        type: "taxonomy_term--gallery_tags",
      },
    });
    onDismiss();
  };

  return (
    <>
      <IonHeader className="app-header">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismiss}>
              <IonIcon icon={closeCircleOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>Redigera inlägg</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={doSave}>
              <IonIcon icon={checkmarkCircleOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="image-preview-container">
          <PrivateImage
            auth="auth"
            src={galleryItem?.field_gallery_thumbnail?.image_style_uri.large}
          />
        </div>
        <AppContent modifiers="padding">
          <IonList className="form">
            <IonItem lines="full">
              <IonLabel position="stacked">Kategori</IonLabel>
              <IonSelect
                placeholder="Välj kategori"
                interface="popover"
                value={formState.field_gallery_tags}
                onIonChange={(e) =>
                  setFormState({
                    ...formState,
                    field_gallery_tags: e.detail.value,
                  })
                }
              >
                {galleryTags.map((tag) => (
                  <IonSelectOption key={tag.id} value={tag.id}>
                    {tag.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem lines="none">
              <IonLabel position="stacked">Beskrivning</IonLabel>
              <IonTextarea
                rows={5}
                value={formState.field_gallery_text}
                onIonChange={(e) =>
                  setFormState({
                    ...formState,
                    field_gallery_text: e.detail.value,
                  })
                }
              />
            </IonItem>
          </IonList>
        </AppContent>
      </IonContent>
    </>
  );
};

export default GalleryEdit;

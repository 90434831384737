import { processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-heading/pepalexa-heading.scss";

const AppHeading = ({ level, children, modifiers, ...props }) => {
  const Element = level ?? "h1";
  return (
    <Element
      className={processModifiers("pepalexa-heading", modifiers)}
      {...props}
    >
      {children}
    </Element>
  );
};

export default AppHeading;

import { processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-media/pepalexa-media.scss";

const AppMedia = ({ modifiers, children, props }) => (
  <div {...props} className={processModifiers("pepalexa-media", modifiers)}>
    {children}
  </div>
);

export default AppMedia;

import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { IonButton, IonCol, IonGrid, IonIcon, IonLoading, IonModal, IonRow, useIonRouter } from "@ionic/react";
import { arrowBack, arrowForward } from "ionicons/icons";

import { getSetting } from "../settings";
import { formatMediumDateTime } from "../util";
import { useAppState } from "../app-context";
import { useContentProvider } from "../providers/content-provider";
import { useEntityAction, useRichMessage } from "../hooks";
import { useDialogs } from "../form/hooks";
import AppContent from "../components/AppContent";
import AppMedia from "../components/AppMedia";
import AppText from "../components/AppText";
import AppInformativeListItem from "../compositions/AppInformativeListItem";
import AppPageWithHideableHeader from "../compositions/AppPageWithHideableHeader";
import PrivateImage from "../components/PrivateImage";
import PrivateVideo from "../components/PrivateVideo";
import BottomSpacer from "../components/BottomSpacer";
import GalleryEdit from "../modals/GalleryEdit";

const GalleryDetail = ({ groupId }) => {
  const { id: initialId } = useParams();
  const [id, setId] = useState(initialId);
  const {
    setData,
    Provider,
    isLoading,
    entity: loadedItem,
  } = useContentProvider(id, "gallery", {
    include: "field_gallery_thumbnail,field_gallery_tags,field_gallery_file",
    relationships: "field_gallery_tags:taxonomy_term--gallery_tags",
    attributes: "field_gallery_tags,field_gallery_text",
  });
  const router = useIonRouter();
  const doEntityAction = useEntityAction(() => {
    router.push("/c/menu/gallery", "back");
  });
  const { confirmDangerousAction } = useDialogs();
  const sendRichMessage = useRichMessage(groupId);

  const { galleryItems, threads } = useAppState();
  const galleryIndex = galleryItems.findIndex((i) => i.id === id);
  const galleryItem = loadedItem ?? galleryItems[galleryIndex];
  const prevId = galleryItems[galleryIndex - 1]?.id;
  const nextId = galleryItems[galleryIndex + 1]?.id;

  const modal = useRef(null);
  const { profile: client } = useAppState();

  const doGoNext = () => {
    setId(nextId);
  };

  const doGoPrev = () => {
    setId(prevId);
  };

  const doDelete = () => {
    confirmDangerousAction({
      message: "Är du säker på att du vill ta bort innehållet?",
      button: "Ta bort",
      handler: () => {
        doEntityAction("delete", { type: "gallery", id });
      },
    });
  };

  return (
    <AppPageWithHideableHeader defaultHref="/c/menu/gallery" backButton>
      <IonLoading isOpen={isLoading} />
      <IonModal trigger="open-edit" ref={modal}>
        <GalleryEdit galleryItem={galleryItem} setData={setData} onDismiss={() => modal?.current.dismiss()} />
      </IonModal>
      <div className="tmp-spacer-2" />
      <AppMedia modifiers="sharp">
        {galleryItem?.field_gallery_file?.filemime.startsWith("image") && (
          <PrivateImage auth="auth" src={galleryItem?.field_gallery_file.image_style_uri.large} />
        )}
        {galleryItem?.field_gallery_file?.filemime.startsWith("video") && (
          <PrivateVideo
            auth="auth"
            poster={galleryItem?.field_gallery_thumbnail.image_style_uri.large}
            src={getSetting("DRUPAL_SERVER") + galleryItem?.field_gallery_file.uri.url}
          />
        )}
      </AppMedia>
      <AppContent modifiers="padding small-margin-top small-margin-bottom">
        <IonGrid>
          <IonRow>
            <IonCol className="ion-padding-end">
              <IonButton
                onClick={doGoPrev}
                disabled={!prevId}
                color="secondary"
                fill="outline"
                size="small"
                expand="block"
              >
                <IonIcon icon={arrowBack} slot="start" />
                Föregående
              </IonButton>
            </IonCol>
            <IonCol className="ion-text-right">
              <IonButton
                color="secondary"
                fill="outline"
                size="small"
                onClick={doGoNext}
                disabled={!nextId}
                expand="block"
              >
                Nästa
                <IonIcon icon={arrowForward} slot="end" />
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </AppContent>
      <AppContent modifiers="padding small-margin-top small-margin-bottom">
        <AppInformativeListItem
          name={client?.label}
          date={formatMediumDateTime(galleryItem?.created)}
          icon="assets/icons/message.svg"
          iconText="Meddela coach"
          onIconClick={() => {
            if (threads.length) {
              sendRichMessage("gallery", id, galleryItem);
            }
          }}
        />
      </AppContent>
      <AppContent modifiers="padding margin-bottom">
        <AppText modifiers="wrapping">{galleryItem?.field_gallery_text}</AppText>
      </AppContent>
      <AppContent modifiers="padding">
        <IonButton fill="outline" color="secondary" size="small" id="open-edit">
          Redigera
        </IonButton>
        <IonButton fill="outline" color="secondary" size="small" onClick={doDelete}>
          Ta bort
        </IonButton>
      </AppContent>
      <BottomSpacer />
      <Provider />
      <zeus-data-provider id="send-message">
        <zeus-data-source-drupal-group-member-content-create
          base-url={getSetting("BACKEND")}
          jsonapi-path="/api/v3"
          content-bundle="message"
          group-bundle="client"
          token-provider="auth"
        ></zeus-data-source-drupal-group-member-content-create>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </AppPageWithHideableHeader>
  );
};

export default GalleryDetail;

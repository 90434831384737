import { useEffect } from "react";
import { useParams } from "react-router";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonIcon,
  IonSpinner,
} from "@ionic/react";
import { cameraOutline } from "ionicons/icons";

import { useClientGalleryProvider } from "../../providers/client-gallery-provider";
import { useUploadMedia } from "../../hooks";
import { useAppState } from "../../app-context";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import AppGalleryCard from "../../compositions/AppGalleryCard";
import AppContent from "../../components/AppContent";
import PrivateImage from "../../components/PrivateImage";
import AppHeading from "../../components/AppHeading";
import BottomSpacer from "../../components/BottomSpacer";

const ClientGallery = () => {
  const { id } = useParams();
  const { setActiveGallery } = useAppState();

  const {
    GalleryProvider,
    galleryItems,
    hasMoreGalleryItems,
    loadMoreGalleryItems,
    galleryIsLoading,
  } = useClientGalleryProvider(id);

  const uploadMedia = useUploadMedia(id);

  useEffect(() => {
    setActiveGallery(galleryItems);
  }, [galleryItems, setActiveGallery]);

  return (
    <AppPageWithHideableHeader
      title="Galleri"
      defaultHref={`/c/menu/manage/client/${id}`}
      backButton
      toolbarButtons={
        <IonButton onClick={uploadMedia}>
          <IonIcon slot="icon-only" icon={cameraOutline} />
        </IonButton>
      }
    >
      <AppContent modifiers="pepalexa-content--padding pepalexa-content--margin-bottom">
        <AppHeading modifiers="center">Galleri</AppHeading>
      </AppContent>
      <AppContent>
        <IonGrid>
          <IonRow>
            {galleryItems?.map((item) => (
              <IonCol size="6" key={item.id} className="gallery-column">
                <AppGalleryCard
                  modifiers="pepalexa-card--short-text"
                  media={
                    <PrivateImage
                      auth="auth"
                      src={
                        item.field_gallery_thumbnail?.image_style_uri?.large ??
                        item.field_gallery_file?.image_style_uri?.large
                      }
                    />
                  }
                  routerLink={`/c/menu/manage/client/${id}/gallery/${item.id}`}
                >
                  {item.field_gallery_text}
                </AppGalleryCard>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>

        <IonGrid>
          <IonRow>
            <IonCol className="ion-text-center">
              {galleryIsLoading && <IonSpinner />}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center">
              {hasMoreGalleryItems && (
                <IonButton
                  color="secondary"
                  fill="outline"
                  onClick={loadMoreGalleryItems}
                >
                  Ladda fler
                </IonButton>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
        <BottomSpacer />
      </AppContent>
      <GalleryProvider />
    </AppPageWithHideableHeader>
  );
};

export default ClientGallery;

import {
  IonButton,
  IonButtons,
  IonIcon,
  IonLabel,
  IonItem,
  IonList,
  IonLoading,
  IonMenuButton,
  useIonRouter,
  useIonViewWillLeave,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import {
  useZeusDataProvider,
  useZeusDataStatusSubscriber,
} from "@understandit/dynamic-data-react";
import { checkmarkCircleOutline } from "ionicons/icons";

import { getSetting, pageConfig } from "../../settings";
import { useError } from "../../hooks";
import { useFormState } from "../../form/hooks";
import { FormItem } from "../../form/FormItem";
import {
  AppPageWithSheet,
  AppSheet,
  AppTopOfSheet,
} from "../../compositions/AppPageWithSheet";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import AppContent from "../../components/AppContent";
import AppHeading from "../../components/AppHeading";
import AppSmallTitle from "../../components/AppSmallTitle";
import { useState } from "react";
import { useAppState } from "../../app-context";
import BottomSpacer from "../../components/BottomSpacer";

const EntityAdd = ({ type, isAdmin }) => {
  const { profile, groupId } = useAppState();
  const providerId = `create-entity-${type}`;
  const router = useIonRouter();
  const [collection, setCollection] = useState(groupId);
  const setValidationError = useError();
  const { setData, isLoading } = useZeusDataProvider(providerId);
  const [formState, updateFormState, validateFormState, onLeave] = useFormState(
    pageConfig[type].fields
  );

  const normalFields = pageConfig[type].fields.filter(
    (i) => i.type !== "workout-blocks"
  );
  const connectorFields = pageConfig[type].fields.filter(
    (i) => i.type === "workout-blocks"
  );

  useZeusDataStatusSubscriber(providerId, (status) => {
    if (status === "created") {
      router.push(`/c/menu/manage/${type}`, "back");
    }
  });

  const doSave = () => {
    const errors = validateFormState();
    if (errors.length) {
      setValidationError(errors[0]);
      return;
    }

    const data = {
      ...formState,
      _group: collection,
      _relationships: [],
      _paragraphs: [],
    };

    pageConfig[type].fields.forEach((field) => {
      if (field.type === "term") {
        data._relationships.push({
          field: field.field,
          data: data[field.field].map((t) => ({
            id: t.id,
            type: `taxonomy_term--${field.vocabulary}`,
          })),
        });
        delete data[field.field];
      }
      if (field.type === "media") {
        if (data[field.field]?.id) {
          data._relationships.push({
            field: field.field,
            data: { id: data[field.field].id, type: "media--image" },
          });
        }
        delete data[field.field];
      }
    });

    connectorFields.forEach((field) =>
      data._paragraphs.push({
        field: "field_workout_blocks",
        type: "paragraph--workout_block",
        attributes: [
          "field_title",
          "field_workout_block_exercises",
          "field_description",
        ],
        relationships: {
          field_workout_block_exercises: {
            type: "node--exercise",
            ref: "id",
          },
        },
        values: formState.field_workout_blocks.map((value) => ({
          ...value,
          action: value.id.startsWith("new__") ? "create" : "update",
        })),
      })
    );

    setData(data);
  };

  useIonViewWillLeave(() => {
    onLeave();
  }, [onLeave]);

  return (
    <AppPageWithHideableHeader
      title={pageConfig[type].addTitle}
      defaultHref={`/c/menu/manage/${type}`}
      backButton
      toolbarButtons={
        <IonButtons>
          <IonButton onClick={doSave}>
            <IonIcon slot="icon-only" icon={checkmarkCircleOutline} />
          </IonButton>
          <IonMenuButton></IonMenuButton>
        </IonButtons>
      }
    >
      <IonLoading isOpen={isLoading} />
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppContent modifiers="pepalexa-content--padding pepalexa-content--margin-bottom">
            <AppHeading modifiers="center">
              {pageConfig[type].addTitle}
            </AppHeading>
          </AppContent>
        </AppTopOfSheet>

        <AppSheet>
          <IonList class="form">
            {isAdmin && (
              <IonItem>
                <IonLabel position="stacked">Katalog</IonLabel>
                <IonSelect
                  interface="popover"
                  value={collection}
                  onIonChange={(e) => setCollection(e.detail.value)}
                >
                  <IonSelectOption value={groupId}>
                    {profile?.field_first_name} {profile?.field_last_name}
                  </IonSelectOption>
                  <IonSelectOption value={getSetting("COMMON_COLLECTION")}>
                    Gemensamma
                  </IonSelectOption>
                </IonSelect>
              </IonItem>
            )}
            {normalFields.map((field, i, fields) => (
              <FormItem
                key={field.field}
                label={field.label}
                type={field.type}
                config={field}
                value={formState[field.field]}
                onChange={(e) => updateFormState(field, e.detail.value)}
                last={i === fields.length - 1}
              />
            ))}
          </IonList>

          {connectorFields?.length > 0 && (
            <>
              <div className="ion-margin-vertical">
                <AppSmallTitle>Övningar</AppSmallTitle>
              </div>
              {connectorFields.map((field, i, fields) => (
                <FormItem
                  key={field.field}
                  label={field.label}
                  type={field.type}
                  config={field}
                  value={formState[field.field]}
                  onChange={(e) => updateFormState(field, e.detail.value)}
                  last={i === fields.length - 1}
                />
              ))}
            </>
          )}
          <BottomSpacer />
        </AppSheet>
      </AppPageWithSheet>
      <zeus-data-provider id={providerId} send-tags={type}>
        <zeus-data-source-drupal-group-member-content-create
          base-url={getSetting("BACKEND")}
          jsonapi-path="/api/v3"
          content-bundle={type}
          group-bundle="coach"
          token-provider="auth"
        ></zeus-data-source-drupal-group-member-content-create>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </AppPageWithHideableHeader>
  );
};

export default EntityAdd;

import { useEffect, useState } from "react";
import { IonFooter, isPlatform } from "@ionic/react";
import { Keyboard } from "@capacitor/keyboard";

const AppResponsiveFooter = ({ children }) => {
  const [keyboardIsOpen, setKeyboardIsOpen] = useState(false);
  useEffect(() => {
    if (!isPlatform("cordova")) {
      return;
    }

    const handle1 = Keyboard.addListener("keyboardWillShow", () =>
      setKeyboardIsOpen(true)
    );
    const handle2 = Keyboard.addListener("keyboardWillHide", () =>
      setKeyboardIsOpen(false)
    );

    return async () => {
      (await handle1).remove();
      (await handle2).remove();
    };
  }, []);
  return (
    <IonFooter
      className={`${keyboardIsOpen && "keyboard-open"} responsive-footer`}
    >
      {children}
    </IonFooter>
  );
};

export default AppResponsiveFooter;

import { IonList } from "@ionic/react";
import dayjs from "dayjs";

import { useClientContentProvider } from "../providers/client-content-provider";
import { formatMediumDate } from "../util";
import AppPageWithHideableHeader from "../compositions/AppPageWithHideableHeader";
import AppContent from "../components/AppContent";
import AppHeading from "../components/AppHeading";
import AppNotificationListItem from "../compositions/AppNotificationListItem";
import {
  AppPageWithSheet,
  AppSheet,
  AppTopOfSheet,
} from "../compositions/AppPageWithSheet";

const Progress = ({ groupId }) => {
  const { Provider, entities } = useClientContentProvider(groupId, "progress", {
    include: "entity_id.field_progress_values",
  });
  const getLatestProgressValue = (item) => {
    if (item.field_progress_values.length < 1) {
      return "Inget värde";
    }
    const unit = item.field_progress_unit;
    const latestValue = item.field_progress_values.sort(
      (a, b) =>
        dayjs(b.field_progress_timestamp).unix() -
        dayjs(a.field_progress_timestamp).unix()
    )[0];
    return `${
      latestValue.field_progress_value_text
    } ${unit} - ${formatMediumDate(latestValue.field_progress_timestamp)}`;
  };
  return (
    <AppPageWithHideableHeader
      title="Framgång"
      defaultHref="/c/menu"
      backButton
    >
      <AppPageWithSheet>
        <Provider />
        <AppTopOfSheet>
          <AppContent modifiers="padding margin-bottom">
            <AppHeading modifiers="center">Framgång</AppHeading>
          </AppContent>
        </AppTopOfSheet>
        <AppSheet>
          <IonList>
            {entities.map((item) => (
              <AppNotificationListItem
                key={item.id}
                title={item.title}
                link={`/c/menu/progress/${item.id}`}
                subtitle={getLatestProgressValue(item)}
              />
            ))}
          </IonList>
        </AppSheet>
      </AppPageWithSheet>
    </AppPageWithHideableHeader>
  );
};

export default Progress;

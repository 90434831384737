import { processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-timestamp/pepalexa-timestamp.scss";

const AppTimestamp = ({ children, modifiers, ...props }) => (
  <p {...props} className={processModifiers("pepalexa-timestamp", modifiers)}>
    {children}
  </p>
);

export default AppTimestamp;

import { processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-input/pepalexa-input.scss";

const AppInput = ({ modifiers, ...props }) => {
  return (
    <input
      className={processModifiers("pepalexa-input", modifiers)}
      {...props}
    />
  );
};

export default AppInput;

import { useState } from "react";
import { useParams } from "react-router";
import { IonButton, IonIcon, IonList, IonLoading } from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";

import { makeid } from "../../util";
import { useCoachProvider } from "../../providers/coach-provider";
import { useFormState } from "../../form/hooks";
import { FormItem } from "../../form/FormItem";
import { useCamera } from "../../hooks";
import {
  AppPageWithSheet,
  AppSheet,
  AppTopOfSheet,
} from "../../compositions/AppPageWithSheet";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import BottomSpacer from "../../components/BottomSpacer";
import AppContent from "../../components/AppContent";
import AppHeading from "../../components/AppHeading";
import AppMediaWithOverlap from "../../components/AppMediaWithOverlap";
import PrivateImage from "../../components/PrivateImage";

const fields = [
  {
    field: "field_first_name",
    label: "Förnamn",
    type: "text",
  },
  {
    field: "field_last_name",
    label: "Efternamn",
    type: "text",
  },
];

const ProfileImage = ({ backendImage, newImage, placeholder }) => {
  if (newImage) {
    return <img src={newImage} alt="" />;
  }
  if (backendImage) {
    return <PrivateImage auth="auth" src={backendImage} />;
  }
  return <img src={placeholder} alt="" />;
};

const ProfileCoachEdit = () => {
  const { id } = useParams();
  const { getPhoto } = useCamera();
  const [image, setImage] = useState("");
  const { Provider, coach, setData, isLoading } = useCoachProvider(id);
  const [formState, updateFormState, validateFormState] = useFormState(
    fields,
    coach
  );

  const title = `Redigera ${coach?.field_first_name} ${coach?.field_last_name}`;

  const doUploadImage = async () => {
    try {
      setImage(await getPhoto());
    } catch (error) {
      console.log(error);
    }
  };

  const doSave = () => {
    if (!validateFormState()) {
      return;
    }

    const data = {
      id,
      label: `${formState.field_first_name} ${formState.field_last_name}`,
      ...formState,
    };

    if (image) {
      data._drupalFiles = [
        {
          field: "field_avatar",
          path: image.webPath,
          type: image.format,
          filename: `${makeid(10)}.${image.format}`,
        },
      ];
    }

    setData(data);
  };

  return (
    <AppPageWithHideableHeader
      title={title}
      defaultHref={`/c/menu/manage/profile/coach/${id}`}
      backButton
      toolbarButtons={
        <IonButton onClick={doSave}>
          <IonIcon icon={checkmarkCircleOutline} slot="icon-only" />
        </IonButton>
      }
    >
      <IonLoading isOpen={isLoading} />
      <Provider />
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppMediaWithOverlap
            media={
              <ProfileImage
                placeholder="assets/images/defaul-avatar.png"
                newImage={image?.webPath}
                backendImage={coach?.field_avatar?.image_style_uri?.medium}
              />
            }
          />
          <div style={{ textAlign: "center", margin: "10px" }}>
            <IonButton
              onClick={doUploadImage}
              fill="outline"
              color="secondary"
              size="small"
            >
              Byt profilbild
            </IonButton>
          </div>
          <AppContent modifiers="pepalexa-content--padding pepalexa-content--margin-bottom">
            <AppHeading modifiers="center">{title}</AppHeading>
          </AppContent>
        </AppTopOfSheet>
        <AppSheet>
          <IonList class="form">
            {fields.map((field, i, fields) => (
              <FormItem
                key={field.field}
                label={field.label}
                type={field.type}
                config={field}
                value={formState[field.field]}
                onChange={(e) => updateFormState(field, e.detail.value)}
                last={i === fields.length - 1}
              />
            ))}
          </IonList>

          <BottomSpacer />
        </AppSheet>
      </AppPageWithSheet>
    </AppPageWithHideableHeader>
  );
};

export default ProfileCoachEdit;

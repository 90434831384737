import { useState } from "react";
import {
  IonAlert,
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
} from "@ionic/react";
import { useDynamicDataAuthentication } from "@understandit/dynamic-data-react";

import AppContent from "../components/AppContent";
import AppAvatar from "../components/AppAvatar";
import AppHeading from "../components/AppHeading";

export const Login = () => {
  const [formValues, setFormValues] = useState({
    username: "",
    password: "",
  });
  const { error, isLoading, login, reset } =
    useDynamicDataAuthentication("auth");

  const handleLogin = () => {
    login(formValues.username, formValues.password);
  };

  const resetLogin = () => {
    reset();
    setFormValues({ ...formValues, password: "" });
  };

  return (
    <IonPage>
      <IonContent>
        <IonAlert
          isOpen={error !== ""}
          buttons={["Ok"]}
          onDidDismiss={resetLogin}
          header="Lofsan"
          message={
            error === "invalid_credentials"
              ? "Fel inloggningsuppgifter, kontrollera att du skrivit rätt."
              : "Fel vid kontakt med servern."
          }
        />

        <IonLoading isOpen={isLoading} />

        <div className="wrapper">
          <div className="wrapper__column wrapper__column--space">
            <AppContent modifiers="text-center margin safe-inset">
              <AppAvatar src="assets/images/logo.png" modifiers="large" />
              <AppHeading modifiers="highlighted-white">
                Välkommen till Lofsangruppen!
              </AppHeading>
            </AppContent>
            <AppContent>
              <IonList class="form">
                <IonItem lines="full">
                  <IonLabel position="stacked">E-post</IonLabel>
                  <IonInput
                    type="email"
                    value={formValues.username}
                    onIonChange={(e) =>
                      setFormValues({ ...formValues, username: e.target.value })
                    }
                  />
                </IonItem>
                <IonItem lines="none">
                  <IonLabel position="stacked">Lösenord</IonLabel>
                  <IonInput
                    type="password"
                    value={formValues.password}
                    onIonChange={(e) =>
                      setFormValues({ ...formValues, password: e.target.value })
                    }
                  />
                </IonItem>
              </IonList>
              <IonButton onClick={handleLogin} color="secondary" expand="block">
                Logga in
              </IonButton>
              <IonButton
                routerLink="/login/password"
                color="secondary"
                expand="block"
                fill="clear"
              >
                Glömt lösenord?
              </IonButton>
            </AppContent>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;

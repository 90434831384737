import { useEffect, useState, Fragment } from "react";
import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonLoading,
  IonTextarea,
} from "@ionic/react";
import {
  useDynamicDataAuthentication,
  useZeusDataProvider,
} from "@understandit/dynamic-data-react";

import { useClientGroupContentProvider } from "../providers/client-group_content-provider";
import { getSetting } from "../settings";
import { useCoachProvider } from "../providers/coach-provider";
import { useClientProvider } from "../providers/client-provider";
import { useCamera } from "../hooks";
import { makeid } from "../util";
import AppPageWithHideableHeader from "../compositions/AppPageWithHideableHeader";
import {
  AppPageWithSheet,
  AppTopOfSheet,
  AppSheet,
} from "../compositions/AppPageWithSheet";
import AppMediaWithOverlap from "../components/AppMediaWithOverlap";
import PrivateImage from "../components/PrivateImage";
import BottomSpacer from "../components/BottomSpacer";

const ProfileImage = ({ backendImage, newImage, placeholder }) => {
  if (newImage) {
    return <img src={newImage} alt="" />;
  }
  if (backendImage) {
    return <PrivateImage auth="auth" src={backendImage} />;
  }
  return <img src={placeholder} alt="" />;
};

const ClientProfile = ({ groupId }) => {
  const { logout } = useDynamicDataAuthentication("auth");
  const { Provider, client, setData, isLoading } = useClientProvider(groupId);
  const { getPhoto } = useCamera();
  const [image, setImage] = useState("");
  const {
    Provider: QuestionsProvider,
    entities: questions,
    isLoading: isLoadingQuestions,
  } = useClientGroupContentProvider(groupId, "question", {sort: "created"});
  const { setData: saveAnswers } = useZeusDataProvider("save-answers");

  const [formState, setFormState] = useState({
    field_first_name: "",
    field_last_name: "",
    field_street: "",
    field_postal: "",
    field_city: "",
    field_country: "",
    field_phone: "",
  });

  useEffect(() => {
    if (client) {
      setFormState({
        field_first_name: client.field_first_name,
        field_last_name: client.field_last_name,
        field_street: client.field_street,
        field_postal: client.field_postal,
        field_city: client.field_city,
        field_country: client.field_country,
        field_phone: client.field_phone,
      });
    }
  }, [client]);

  const [questionsFormState, setQuestionsFormState] = useState({});

  useEffect(() => {
    if (questions) {
      setQuestionsFormState(
        questions.reduce((acc, cur) => {
          acc[cur.id] = {
            saved_answer: cur.field_question_answer,
            current_answer: cur.field_question_answer,
          };
          return acc;
        }, {})
      );
    }
  }, [questions]);

  const doSave = () => {
    const data = {
      id: groupId,
      label: `${formState.field_first_name} ${formState.field_last_name}`,
      ...formState,
    };

    if (image) {
      data._drupalFiles = [
        {
          field: "field_avatar",
          path: image.webPath,
          type: image.format,
          filename: `${makeid(10)}.${image.format}`,
        },
      ];
    }

    setData(data);

    const answers = Object.entries(questionsFormState).reduce(
      (acc, [id, value]) => {
        if (value.saved_answer !== value.current_answer) {
          acc.push({ id: id, answer: value.current_answer });
        }
        return acc;
      },
      []
    );
    if (answers.length) {
      saveAnswers({ answers: answers });
    }
  };

  const doUploadImage = async () => {
    try {
      setImage(await getPhoto());
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AppPageWithHideableHeader
      title="Redigera profil"
      backButton={true}
      defaultHref="/c/dashboard"
    >
      <Provider />
      <QuestionsProvider />
      <IonLoading isOpen={isLoading || isLoadingQuestions} />
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppMediaWithOverlap
            media={
              <ProfileImage
                newImage={image?.webPath}
                backendImage={client?.field_avatar?.image_style_uri?.medium}
                placeholder="assets/images/default-avatar.png"
              />
            }
          />
          <div style={{ textAlign: "center", margin: "10px" }}>
            <IonButton
              onClick={doUploadImage}
              fill="outline"
              color="secondary"
              size="small"
            >
              Byt profilbild
            </IonButton>
          </div>
        </AppTopOfSheet>
        <AppSheet>
          <IonList class="form">
            <IonItem lines="full">
              <IonLabel position="stacked">Förnamn</IonLabel>
              <IonInput
                value={formState.field_first_name}
                onIonChange={(e) =>
                  setFormState((current) => ({
                    ...current,
                    field_first_name: e.detail.value,
                  }))
                }
              />
            </IonItem>
            <IonItem lines="full">
              <IonLabel position="stacked">Efternamn</IonLabel>
              <IonInput
                value={formState.field_last_name}
                onIonChange={(e) =>
                  setFormState((current) => ({
                    ...current,
                    field_last_name: e.detail.value,
                  }))
                }
              />
            </IonItem>
            <IonItem lines="full">
              <IonLabel position="stacked">Adress</IonLabel>
              <IonInput
                value={formState.field_street}
                onIonChange={(e) =>
                  setFormState((current) => ({
                    ...current,
                    field_street: e.detail.value,
                  }))
                }
              />
            </IonItem>
            <IonItem lines="full">
              <IonLabel position="stacked">Postnummer</IonLabel>
              <IonInput
                value={formState.field_postal}
                onIonChange={(e) =>
                  setFormState((current) => ({
                    ...current,
                    field_postal: e.detail.value,
                  }))
                }
              />
            </IonItem>
            <IonItem lines="full">
              <IonLabel position="stacked">Ort</IonLabel>
              <IonInput
                value={formState.field_city}
                onIonChange={(e) =>
                  setFormState((current) => ({
                    ...current,
                    field_city: e.detail.value,
                  }))
                }
              />
            </IonItem>
            <IonItem lines="full">
              <IonLabel position="stacked">Land</IonLabel>
              <IonInput
                value={formState.field_country}
                onIonChange={(e) =>
                  setFormState((current) => ({
                    ...current,
                    field_country: e.detail.value,
                  }))
                }
              />
            </IonItem>
            <IonItem lines="none">
              <IonLabel position="stacked">Telefon</IonLabel>
              <IonInput
                value={formState.field_phone}
                onIonChange={(e) =>
                  setFormState((current) => ({
                    ...current,
                    field_phone: e.detail.value,
                  }))
                }
              />
            </IonItem>
            {questions.map((question) => (
              <Fragment key={question.id}>
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    {question.entity_id.title}
                  </IonLabel>
                  {question.entity_id.field_question_answer_type ===
                  "textarea" ? (
                    <IonTextarea
                      className="edit-profile-input"
                      autoGrow={true}
                      placeholder={
                        question.entity_id.field_question_placeholder
                      }
                      value={questionsFormState[question.id]?.current_answer}
                      onIonChange={(e) =>
                        setQuestionsFormState((current) => {
                          const newCurrent = { ...current };
                          newCurrent[question.id].current_answer =
                            e.detail.value;
                          return newCurrent;
                        })
                      }
                    />
                  ) : (
                    <IonInput
                      className="edit-profile-input"
                      placeholder={
                        question.entity_id.field_question_placeholder
                      }
                      value={questionsFormState[question.id]?.current_answer}
                      onIonChange={(e) =>
                        setQuestionsFormState((current) => {
                          const newCurrent = { ...current };
                          newCurrent[question.id].current_answer =
                            e.detail.value;
                          return newCurrent;
                        })
                      }
                    />
                  )}
                </IonItem>
              </Fragment>
            ))}
          </IonList>
          <IonButton color="secondary" expand="block" onClick={doSave}>
            Spara
          </IonButton>
          <IonButton
            color="secondary"
            expand="block"
            fill="outline"
            onClick={logout}
          >
            Logga ut
          </IonButton>
          <BottomSpacer />
        </AppSheet>
      </AppPageWithSheet>
      <zeus-data-provider id="save-answers" write-only={true}>
        <zeus-data-source-json
          url={`${getSetting("BACKEND")}/api/save-answers`}
        ></zeus-data-source-json>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </AppPageWithHideableHeader>
  );
};

const CoachProfile = ({ groupId }) => {
  const { logout } = useDynamicDataAuthentication("auth");
  const { Provider, coach, setData, isLoading } = useCoachProvider(groupId);
  const { getPhoto } = useCamera();
  const [image, setImage] = useState("");

  const [formState, setFormState] = useState({
    field_first_name: "",
    field_last_name: "",
  });

  useEffect(() => {
    if (coach) {
      setFormState({
        field_first_name: coach.field_first_name,
        field_last_name: coach.field_last_name,
      });
    }
  }, [coach]);

  const doSave = () => {
    const data = {
      id: groupId,
      label: `${formState.field_first_name} ${formState.field_last_name}`,
      ...formState,
    };

    if (image) {
      data._drupalFiles = [
        {
          field: "field_avatar",
          path: image.webPath,
          type: image.format,
          filename: `${makeid(10)}.${image.format}`,
        },
      ];
    }

    setData(data);
  };

  const doUploadImage = async () => {
    try {
      setImage(await getPhoto());
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AppPageWithHideableHeader
      title="Redigera profil"
      backButton={true}
      defaultHref="/c/dashboard"
    >
      <IonLoading isOpen={isLoading} />
      <Provider />
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppMediaWithOverlap
            media={
              <ProfileImage
                newImage={image?.webPath}
                backendImage={coach?.field_avatar?.image_style_uri?.medium}
                placeholder="assets/images/default-avatar.png"
              />
            }
          />
          <div style={{ textAlign: "center", margin: "10px" }}>
            <IonButton
              onClick={doUploadImage}
              fill="outline"
              color="secondary"
              size="small"
            >
              Byt profilbild
            </IonButton>
          </div>
        </AppTopOfSheet>
        <AppSheet>
          <IonList class="form">
            <IonItem lines="full">
              <IonLabel position="stacked">Förnamn</IonLabel>
              <IonInput
                value={formState.field_first_name}
                onIonChange={(e) =>
                  setFormState((current) => ({
                    ...current,
                    field_first_name: e.detail.value,
                  }))
                }
              />
            </IonItem>
            <IonItem lines="none">
              <IonLabel position="stacked">Efternamn</IonLabel>
              <IonInput
                value={formState.field_last_name}
                onIonChange={(e) =>
                  setFormState((current) => ({
                    ...current,
                    field_last_name: e.detail.value,
                  }))
                }
              />
            </IonItem>
          </IonList>
          <IonButton color="secondary" expand="block" onClick={doSave}>
            Spara
          </IonButton>
          <IonButton
            color="secondary"
            expand="block"
            fill="outline"
            onClick={logout}
          >
            Logga ut
          </IonButton>
          <BottomSpacer />
        </AppSheet>
      </AppPageWithSheet>
    </AppPageWithHideableHeader>
  );
};

const Profile = ({ mode, groupId }) => {
  if (!mode && !groupId) {
    return <></>;
  }
  return mode === "coach" ? (
    <CoachProfile groupId={groupId} />
  ) : (
    <ClientProfile groupId={groupId} />
  );
};

export default Profile;

import { useCallback } from "react";
import { isPlatform, useIonActionSheet, useIonLoading } from "@ionic/react";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

import { makeid } from "./util";

function guessType(filename) {
  const ext = filename.split(".").pop();
  if (ext.toLowerCase() === "jpg") {
    return "photo";
  }
  return "video";
}

function createVideoThumbnail(path) {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");

    video.src = path;
    video.muted = true;
    video.playsInline = true;
    video.preload = "metadata";

    const canvas = document.createElement("canvas");

    let lowerTime = 0;
    let upperTime = 0;
    let bestThumbnail = null;

    video.addEventListener("error", reject);

    video.addEventListener("loadedmetadata", () => {
      const preferredThumbnailTime = 6;
      const preferredThumbnailDuration = 2;

      if (preferredThumbnailTime + preferredThumbnailDuration / 2 > video.duration) {
        const middleTime = video.duration / 2;
        lowerTime = Math.max(0, middleTime - preferredThumbnailDuration / 2);
        upperTime = Math.min(video.duration, middleTime + preferredThumbnailDuration / 2);
      } else {
        lowerTime = preferredThumbnailTime - preferredThumbnailDuration / 2;
        upperTime = preferredThumbnailTime + preferredThumbnailDuration / 2;
      }

      video.currentTime = lowerTime;

      const maxSize = 1000;
      const aspect = video.videoWidth / video.videoHeight;
      const scale = Math.min(maxSize / video.videoWidth, maxSize / video.videoHeight);
      canvas.width = Math.round((maxSize * aspect) / scale);
      canvas.height = Math.round(canvas.width / aspect);
    });

    video.addEventListener("seeked", async () => {
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const thumbnail = await new Promise((resolve) => {
        canvas.toBlob((blob) => resolve(blob), "image/jpeg", 0.8);
      });

      if (!bestThumbnail || thumbnail.size > bestThumbnail.size) {
        bestThumbnail = thumbnail;
      }

      if (video.currentTime < upperTime) {
        video.currentTime = Math.min(video.duration, video.currentTime + 1 / 10);
      } else {
        resolve(window.URL.createObjectURL(bestThumbnail));
      }
    });
  });
}

async function transcodeVideo(path, options = {}) {
  if (!path.startsWith("file://")) {
    path = "file://" + path;
  }
  const videoInfo = await new Promise((resolve, reject) => {
    window.VideoEditor.getVideoInfo(resolve, reject, {
      fileUri: path,
    });
  });

  return new Promise((resolve, reject) => {
    window.VideoEditor.transcodeVideo(resolve, reject, {
      fileUri: path,
      outputFileName: makeid(12),
      outputFileType: window.VideoEditorOptions.OutputFileType.MPEG4,
      optimizeForNetworkUse: window.VideoEditorOptions.OptimizeForNetworkUse.YES,
      saveToLibrary: false,
      maintainAspectRatio: true,
      width: Math.min(videoInfo.width, options.width ?? 640),
      height: Math.min(videoInfo.height, options.height ?? 640),
      progress: (progress) => {
        if (!options.progress) {
          return;
        }

        if (isPlatform("ios")) {
          options.progress(progress / 100);
        } else {
          options.progress(progress);
        }
      },
    });
  });
}

function captureVideo(options = {}) {
  return new Promise((resolve, reject) => {
    window.navigator.device.capture.captureVideo(
      (mediaFiles) => {
        if (mediaFiles.length > 0) {
          resolve(mediaFiles[0].fullPath);
        } else {
          reject("no media files captured");
        }
      },
      reject,
      {
        limit: 1,
        duration: options.duration ?? 60,
        width: options.width ?? 640,
        height: options.height ?? 640,
      }
    );
  });
}

function capturePhoto() {
  return Camera.getPhoto({
    source: CameraSource.Camera,
    resultType: CameraResultType.Uri,
  });
}

function getMediaFromLibrary() {
  return new Promise((resolve, reject) => {
    navigator.camera.getPicture(resolve, reject, {
      sourceType: 0,
      mediaType: 2,
    });
  });
}

async function getGalleryMedia(type) {
  if (type === "photo") {
    const photo = await capturePhoto();
    return {
      type: "photo",
      path: photo.webPath,
    };
  }
  if (type === "video") {
    const video = await captureVideo({});
    return {
      type: "video",
      path: video,
    };
  }
  if (type === "library") {
    const file = await getMediaFromLibrary();
    const type = guessType(file);
    return {
      type: type,
      path: type === "video" ? file : window.Ionic.WebView.convertFileSrc(file),
    };
  }
}

function useSheet() {
  const [present] = useIonActionSheet();
  return useCallback(() => {
    return new Promise((resolve) => {
      present({
        cssClass: "app-action-sheet",
        buttons: [
          {
            text: "Nytt foto",
            data: {
              action: "photo",
            },
          },
          {
            text: "Ny video",
            data: {
              action: "video",
            },
          },
          {
            text: "Välj från bibliotek",
            data: {
              action: "library",
            },
          },
          {
            text: "Avbryt",
            role: "cancel",
            data: {
              action: "cancel",
            },
          },
        ],
        onDidDismiss: ({ detail }) => {
          resolve(detail?.data?.action);
        },
      });
    });
  }, [present]);
}

export function useGalleryMedia() {
  const [presentLoading, dismissLoading] = useIonLoading();
  const showSheet = useSheet();

  const getMedia = useCallback(async () => {
    if (!isPlatform("hybrid")) {
      const photo = await Camera.getPhoto({
        webUseInput: true,
        source: CameraSource.Camera,
        resultType: CameraResultType.Uri,
      });
      return {
        thumbnail: {
          preview: photo.webPath,
          path: photo.webPath,
          filename: `${makeid(12)}.${photo.format}`,
        },
        media: {
          preview: photo.webPath,
          path: photo.webPath,
          filename: `${makeid(12)}.${photo.format}`,
        },
      };
    }

    const action = await showSheet();

    if (!action) {
      console.log("User canceled action.");
      return;
    }

    let file;

    try {
      file = await getGalleryMedia(action);
    } catch (error) {
      console.log(error);
      console.log("Error getting media.");
      return null;
    }

    let media = file.path;
    let thumbnail = file.path;

    if (file.type === "video") {
      presentLoading({ message: "Förbereder media för uppladdning." });

      // Compress video.
      const compressedFile = await transcodeVideo(media);
      media = window.Ionic.WebView.convertFileSrc(compressedFile);

      // Create thumbnail.
      thumbnail = await createVideoThumbnail(media);

      dismissLoading();
    }

    return {
      thumbnail: {
        preview: thumbnail,
        path: thumbnail,
        filename: `${makeid(12)}.jpg`,
      },
      media: {
        preview: media,
        path: media,
        filename: `${makeid(12)}.${file.type === "video" ? "mp4" : "jpg"}`,
      },
    };
  }, [dismissLoading, presentLoading, showSheet]);

  return getMedia;
}

// Below is original code by Martin, preserved here as reference.
/*
const captureVideo2 = async (options) => {
  const media = await new Promise((resolve, reject) => {
    window.navigator.device.capture.captureVideo(
      (mediaFiles) => {
        if (mediaFiles.length > 0) {
          resolve(mediaFiles[0]);
        } else {
          reject("no media files captured");
        }
      },
      reject,
      {
        limit: 1,
        duration: options.duration ?? 60,
        width: options.width ?? 640,
        height: options.height ?? 640,
      }
    );
  });

  const videoInfo = await new Promise((resolve, reject) => {
    window.VideoEditor.getVideoInfo(resolve, reject, {
      fileUri: media.fullPath,
    });
  });

  console.log(media, videoInfo);

  const transcodedVideoFullPath = await new Promise((resolve, reject) => {
    window.VideoEditor.transcodeVideo(resolve, reject, {
      fileUri: media.fullPath,
      outputFileName: makeid(12),
      outputFileType: window.VideoEditorOptions.OutputFileType.MPEG4,
      optimizeForNetworkUse:
        window.VideoEditorOptions.OptimizeForNetworkUse.YES,
      saveToLibrary: false,
      maintainAspectRatio: true,
      width: Math.min(videoInfo.width, options.width ?? 640),
      height: Math.min(videoInfo.height, options.height ?? 640),
      progress: (progress) => {
        if (!options.progress) {
          return;
        }

        if (isPlatform("ios")) {
          options.progress(progress / 100);
        } else {
          options.progress(progress);
        }
      },
    });
  });

  console.log(transcodedVideoFullPath);
  console.time("thumbnail");

  const fileEntry = await new Promise((resolve, reject) => {
    window.resolveLocalFileSystemURL(
      "file://" + transcodedVideoFullPath,
      resolve,
      reject
    );
  });

  const path = window.Ionic.WebView.convertFileSrc(fileEntry.toURL());

  const blob = async () => {
    const response = await fetch(path);
    const arrayBuffer = await response.arrayBuffer();

    return new Blob([arrayBuffer], { type: "video/mp4" });
  };

  const thumbnail = new Promise((resolve, reject) => {
    const video = document.createElement("video");

    video.src = path;
    video.muted = true;
    video.playsInline = true;
    video.preload = "metadata";

    const canvas = document.createElement("canvas");


    // document.body.appendChild(video);
    // document.body.appendChild(canvas);

    // video.style.position = "fixed";
    // video.style.top = "0";
    // video.style.left = "0";
    // video.style.width = "200px";
    // video.style.height = "200px";

    // canvas.style.position = "fixed";
    // canvas.style.bottom = "0";
    // canvas.style.left = "0";
    // canvas.style.width = "200px";
    // canvas.style.height = "200px";

    let lowerTime = 0;
    let upperTime = 0;
    let bestThumbnail = null;

    video.addEventListener("error", reject);

    video.addEventListener("loadedmetadata", () => {
      console.log(
        "loaded",
        video.duration,
        video.videoWidth,
        video.videoHeight
      );

      const preferredThumbnailTime = 6;
      const preferredThumbnailDuration = 2;

      if (
        preferredThumbnailTime + preferredThumbnailDuration / 2 >
        video.duration
      ) {
        const middleTime = video.duration / 2;
        lowerTime = Math.max(0, middleTime - preferredThumbnailDuration / 2);
        upperTime = Math.min(
          video.duration,
          middleTime + preferredThumbnailDuration / 2
        );
      } else {
        lowerTime = preferredThumbnailTime - preferredThumbnailDuration / 2;
        upperTime = preferredThumbnailTime + preferredThumbnailDuration / 2;
      }

      video.currentTime = lowerTime;

      const maxSize = 1000;
      const aspect = video.videoWidth / video.videoHeight;
      const scale = Math.min(
        maxSize / video.videoWidth,
        maxSize / video.videoHeight
      );
      canvas.width = Math.round((maxSize * aspect) / scale);
      canvas.height = Math.round(canvas.width / aspect);


      // canvas.style.width = `${canvas.width}px`;
      // canvas.style.height = `${canvas.height}px`;

    });

    video.addEventListener("seeked", async () => {
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const thumbnail = await new Promise((resolve) => {
        canvas.toBlob((blob) => resolve(blob), "image/jpeg", 0.8);
      });

      if (!bestThumbnail || thumbnail.size > bestThumbnail.size) {
        bestThumbnail = thumbnail;
      }

      if (video.currentTime < upperTime) {
        video.currentTime = Math.min(
          video.duration,
          video.currentTime + 1 / 10
        );
      } else {
        console.timeEnd("thumbnail");
        resolve(bestThumbnail);
      }
    });
  });

  return {
    blob,
    thumbnail: () => thumbnail,
  };
};
*/

import { useMemo, useState } from "react";
import dayjs from "dayjs";
import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-calendar/pepalexa-calendar.scss";

import { classList } from "../util";
import { IonIcon } from "@ionic/react";

const AppCalendar = ({ value, onChange, onViewChange, highlightDays = [] }) => {
  const [activeMonth, setActiveMonth] = useState(
    dayjs(value).startOf("month").toDate()
  );

  const weeks = useMemo(() => {
    // Locale sv sets first day of week to monday.
    let t0 = dayjs(activeMonth).startOf("week");

    const weeks = [];
    for (let i = 0; i < 6; i++) {
      const week = [];
      for (let i = 0; i < 7; i++) {
        week.push({
          date: t0.toDate(),
          day: t0.date(),
          inMonth: t0.isSame(activeMonth, "month"),
        });
        t0 = t0.add(1, "day");
      }
      if (week.some((i) => i.inMonth)) {
        weeks.push(week);
      }
    }
    return weeks;
  }, [activeMonth]);

  const doDayClicked = (date) => {
    onChange &&
      onChange(
        new CustomEvent("AppCalenderChange", { detail: { value: date } })
      );
  };

  const doGoCurrent = () => {
    if (dayjs().isSame(activeMonth, "mont")) {
      return;
    }
    const newValue = dayjs().startOf("month");
    setActiveMonth(newValue);
    onViewChange &&
      onViewChange(
        new CustomEvent("AppViewChange", { detail: { value: newValue } })
      );
  };

  const doGoNext = () => {
    const newValue = dayjs(activeMonth).add(1, "month").toDate();
    setActiveMonth(newValue);
    onViewChange &&
      onViewChange(
        new CustomEvent("AppViewChange", { detail: { value: newValue } })
      );
  };

  const doGoPrev = () => {
    const newValue = dayjs(activeMonth).subtract(1, "month").toDate();
    setActiveMonth(newValue);
    onViewChange &&
      onViewChange(
        new CustomEvent("AppViewChange", { detail: { value: newValue } })
      );
  };

  return (
    <div className="pepalexa-calendar">
      <div className="pepalexa-calendar__header">
        <button className="pepalexa-calendar__button" onClick={doGoPrev}>
          <IonIcon
            slot="icon-only"
            src="assets/icons/chevron-left.svg"
          ></IonIcon>
        </button>
        <h2 className="pepalexa-calendar__title" onClick={doGoCurrent}>
          {dayjs(activeMonth).format("MMMM YYYY")}
        </h2>
        <button className="pepalexa-calendar__button" onClick={doGoNext}>
          <IonIcon
            slot="icon-only"
            src="assets/icons/chevron-right.svg"
          ></IonIcon>
        </button>
      </div>

      <div className="pepalexa-calendar__row">
        <div className="pepalexa-calendar__day pepalexa-calendar__day--of-week">
          Mån
        </div>
        <div className="pepalexa-calendar__day pepalexa-calendar__day--of-week">
          Tis
        </div>
        <div className="pepalexa-calendar__day pepalexa-calendar__day--of-week">
          Ons
        </div>
        <div className="pepalexa-calendar__day pepalexa-calendar__day--of-week">
          Tors
        </div>
        <div className="pepalexa-calendar__day pepalexa-calendar__day--of-week">
          Fre
        </div>
        <div className="pepalexa-calendar__day pepalexa-calendar__day--of-week">
          Lör
        </div>
        <div className="pepalexa-calendar__day pepalexa-calendar__day--of-week">
          Sön
        </div>
      </div>

      {weeks.map((week, i) => (
        <div key={i} className="pepalexa-calendar__row">
          {week.map((day, i) => (
            <button
              key={i}
              disabled={!day.inMonth}
              onClick={() => doDayClicked(day.date)}
              className={classList(
                "pepalexa-calendar__day",
                day.inMonth &&
                  dayjs(day.date).isSame(value, "day") &&
                  "pepalexa-calendar__day--is-selected",
                day.inMonth &&
                  highlightDays.includes(day.day) &&
                  "pepalexa-calendar__day--has-info"
              )}
            >
              {day.inMonth && day.day}
            </button>
          ))}
        </div>
      ))}
    </div>
  );
};

export default AppCalendar;

import { processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-label/pepalexa-label.scss";

const AppLabel = ({ children, modifiers, ...props }) => (
  <p className={processModifiers("pepalexa-label", modifiers)} {...props}>
    {children}
  </p>
);

export default AppLabel;

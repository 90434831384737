import { useParams } from "react-router";
import { useDynamicDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";
import { fileSelectPlaceholderImage } from "../util";
import AppContent from "../components/AppContent";
import AppHeading from "../components/AppHeading";
import AppHero from "../components/AppHero";
import AppPageWithHideableHeader from "../compositions/AppPageWithHideableHeader";
import BottomSpacer from "../components/BottomSpacer";

const DietDetail = ({ defaultHref = "/c/menu/diet" }) => {
  const { id } = useParams();
  const [diet] = useDynamicDataProvider(`diet-${id}`);

  return (
    <AppPageWithHideableHeader
      title={diet?.title}
      defaultHref={defaultHref}
      fullscreen
      backButton
    >
      <div className="wrapper">
        <AppHero
          background={
            diet?.field_media
              ? diet.field_media.thumbnail.image_style_uri?.large
              : fileSelectPlaceholderImage(diet?.drupal_internal__nid)
          }
          contentPosition="center-left"
        >
          <AppHeading modifiers="highlighted">
            {diet?.field_public_title ?? diet?.title}
          </AppHeading>
        </AppHero>
        <AppContent modifiers="padding margin-top margin-bottom">
          <AppHeading>Beskrivning</AppHeading>
        </AppContent>
        <AppContent
          modifiers="padding opacity"
          innerContent={diet?.body?.value}
        />
        <BottomSpacer />
      </div>
      <zeus-data-provider id={`diet-${id}`}>
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/api/v3/node/diet/${id}?include=field_diet_tags,field_media.thumbnail`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </AppPageWithHideableHeader>
  );
};

export default DietDetail;

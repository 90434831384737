import { IonSpinner } from "@ionic/react";

import { formatMediumDateTime, processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-message-box/pepalexa-message-box.scss";

const AppMessageBox = ({ children, modifiers, showReadAt, readAt, inTransit, error, onEnter, ...props }) => {
  return (
    <div className="pepalexa-message-box__wrap">
      <div
        {...props}
        className={processModifiers("pepalexa-message-box", [modifiers, inTransit && "in-transit", error && "error"])}
      >
        {inTransit && (
          <div className="spinner-container">
            <IonSpinner />
          </div>
        )}

        {children}

        {showReadAt && !error && (
          <div className="read-at">{readAt ? `Läst ${formatMediumDateTime(readAt)}` : "Oläst"}</div>
        )}

        {error && <div className="read-at">Kunde inte skicka meddelandet, klicka här för att försöka igen.</div>}
      </div>
    </div>
  );
};

export default AppMessageBox;

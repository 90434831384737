import { useMemo } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

export function useCoachProvider(id) {
  const providerId = `coach-provider-${id}`;
  const url = `${getSetting(
    "BACKEND"
  )}/api/v3/group/coach/${id}?include=field_avatar`;
  const { data, isLoading, setData } = useZeusDataProvider(providerId, []);

  const Provider = useMemo(() => {
    const Component = () => (
      <>
        <zeus-data-provider id={providerId} send-tags="coach">
          <zeus-data-source-jsonapi
            type="group--coach"
            attributes="label,field_first_name,field_last_name,field_avatar"
            relationships="field_avatar:file--file"
            url={url}
          ></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      </>
    );
    return Component;
  }, [providerId, url]);

  return {
    Provider,
    coach: data,
    isLoading,
    setData,
  };
}

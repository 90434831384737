import { useCallback, useState } from "react";
import { useIonAlert, useIonModal, useIonActionSheet, isPlatform } from "@ionic/react";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

import { getSetting } from "./settings";
import { useGalleryMedia } from "./capture-video";
import { useAppState } from "./app-context";
import ActionModal from "./components/ActionModal";
import GalleryAdd from "./modals/GalleryAdd";
import AddRichMessage from "./modals/AddRichMessage";

export function useError() {
  const [presentAlert] = useIonAlert();
  const setError = (message) => {
    presentAlert({
      message,
      header: getSetting("APP_NAME"),
      buttons: ["Ok"],
    });
  };
  return setError;
}

export function useEntityAction(onDone) {
  const doCloseModal = () => {
    dismissModal();
    onDone && onDone();
  };
  const [actionState, setActionState] = useState({
    action: "",
    args: {},
  });
  const [presentModal, dismissModal] = useIonModal(ActionModal, {
    ...actionState,
    onDone: doCloseModal,
  });

  const doEntityAction = useCallback(
    (action, args) => {
      setActionState({ action, args });
      presentModal({ backdropDismiss: false, cssClass: "action-modal" });
    },
    [presentModal]
  );

  return doEntityAction;
}

export function useCamera() {
  const [presentSheet] = useIonActionSheet();

  const getPhoto = useCallback(async () => {
    if (!isPlatform("capacitor")) {
      return Camera.getPhoto({
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        webUseInput: true,
        source: CameraSource.Photos,
      });
    }

    return new Promise((resolve, reject) => {
      presentSheet({
        cssClass: "app-action-sheet",
        buttons: [
          { text: "Från bilder", data: CameraSource.Photos },
          { text: "Ta foto", data: CameraSource.Camera },
          { text: "Avbryt", role: "cancel", data: "cancel" },
        ],
        onDidDismiss: (event) => {
          if (event.detail.role !== "cancel") {
            Camera.getPhoto({
              quality: 90,
              allowEditing: true,
              resultType: CameraResultType.Uri,
              webUseInput: true,
              source: event.detail.data,
            }).then(resolve, reject);
          } else {
            reject("canceled");
          }
        },
      });
    });
  }, [presentSheet]);

  return { getPhoto };
}

export function useUploadMedia(groupId) {
  const getMedia = useGalleryMedia();
  const [formState, setFormState] = useState({
    field_gallery_tags: {},
    field_gallery_text: "",
    field_gallery_thumbnail: "",
    field_gallery_file: null,
  });

  const [presentModal, dismissModal] = useIonModal(GalleryAdd, {
    groupId,
    formState,
    setFormState,
    dismiss: () => dismissModal(),
  });

  const doOpenCamera = useCallback(async () => {
    try {
      const media = await getMedia();
      if (!media) {
        return;
      }
      setFormState({
        field_category: {},
        field_description: "",
        field_gallery_thumbnail: media.thumbnail,
        field_gallery_file: media.media,
      });
      presentModal();
    } catch (error) {
      console.log(error);
      return;
    }
  }, [getMedia, presentModal]);

  return doOpenCamera;
}

export function useRichMessage(groupId) {
  const { mode, coaches, threads } = useAppState();
  const activeThreads = threads?.filter((x) => !x.closed);

  let defaultThread = null;
  if (threads?.length === 1) {
    defaultThread = threads[0].id;
  } else {
    defaultThread = coaches.length === 1 ? activeThreads.find((x) => x.coachId === coaches[0].id)?.id ?? null : null;
  }

  const [actionParams, setActionParams] = useState({
    id: "",
    type: "",
    entity: null,
    index: 0,
  });

  const [presentModal, dismissModal] = useIonModal(AddRichMessage, {
    groupId,
    actionParams,
    mode,
    threads: activeThreads,
    dismiss: () => dismissModal(),
  });

  const actionCallback = useCallback(
    (type, id, entity, index) => {
      setActionParams({ id, type, entity, index, threadId: defaultThread });
      presentModal();
    },
    [defaultThread, presentModal]
  );

  return actionCallback;
}

export function useRichMessageCoach(groupId) {
  const { mode, threads } = useAppState();
  const [actionParams, setActionParams] = useState({
    id: "",
    type: "",
    entity: null,
    index: 0,
    threadId: "",
  });
  const [presentModal, dismissModal] = useIonModal(AddRichMessage, {
    groupId,
    actionParams,
    mode,
    threads,
    dismiss: () => dismissModal(),
  });

  const actionCallback = useCallback(
    (threadId, type, id, entity, index) => {
      setActionParams({
        id,
        type,
        entity,
        index,
        threadId,
        hideThreadSelector: true,
      });
      presentModal();
    },
    [presentModal]
  );

  return actionCallback;
}

export function useOptionDialog() {
  const [presentAlert] = useIonAlert();
  const showDialog = ({ message, options }) => {
    return new Promise((resolve) => {
      const inputs = options.map((opt) => ({ ...opt, type: "radio" }));
      inputs[0].checked = true;
      presentAlert({
        header: getSetting("APP_NAME"),
        message,
        buttons: [
          { text: "Avbryt", role: "cancel" },
          { text: "Ok", role: "accept" },
        ],
        inputs,
        onDidDismiss: (event) => {
          if (event.detail.role === "accept") {
            resolve(event.detail.data?.values);
          } else {
            resolve(null);
          }
        },
      });
    });
  };
  return showDialog;
}

export function useActionSheet() {
  const [present] = useIonActionSheet();
  return (params) => {
    return new Promise((resolve) => {
      present({
        ...params,
        onDidDismiss: (e) => resolve(e.detail?.data),
      });
    });
  };
}

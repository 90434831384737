import { useParams } from "react-router";
import {
  IonItem,
  IonList,
  IonLabel,
  IonSpinner,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { trashOutline } from "ionicons/icons";
import dayjs from "dayjs";

import { useClientContentProvider } from "../../providers/client-content-provider";
import { formatMediumDate } from "../../util";
import { useEntityAction } from "../../hooks";
import { useDialogs } from "../../form/hooks";
import {
  AppPageWithSheet,
  AppSheet,
  AppTopOfSheet,
} from "../../compositions/AppPageWithSheet";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import AppContent from "../../components/AppContent";
import AppHeading from "../../components/AppHeading";
import BottomSpacer from "../../components/BottomSpacer";

const ClientProgress = () => {
  const { id } = useParams();
  const type = "progress";
  const doEntityAction = useEntityAction();
  const { confirmDangerousAction } = useDialogs();
  const { Provider, entities, isLoading } = useClientContentProvider(
    id,
    "progress",
    { include: "entity_id.field_progress_values" }
  );

  const getLatestProgressValue = (item) => {
    if (item.field_progress_values.length < 1) {
      return "Inget värde";
    }
    const unit = item.field_progress_unit;
    const latestValue = item.field_progress_values.sort(
      (a, b) =>
        dayjs(b.field_progress_timestamp).unix() -
        dayjs(a.field_progress_timestamp).unix()
    )[0];
    return `${
      latestValue.field_progress_value_text
    } ${unit} - ${formatMediumDate(latestValue.field_progress_timestamp)}`;
  };

  const doDelete = (e, id) => {
    e?.preventDefault();
    e?.stopPropagation();
    confirmDangerousAction({
      message: "Är du säker på att du vill ta bort innehållet?",
      button: "Ta bort",
      handler: () => {
        doEntityAction("delete", { type, id });
      },
    });
  };

  return (
    <AppPageWithHideableHeader
      title="Framgång"
      defaultHref={`/c/menu/manage/client/${id}`}
      backButton
      toolbarButtons={
        <IonButton routerLink={`/c/menu/manage/client/${id}/progress/add`}>
          <IonIcon slot="icon-only" src="assets/icons/add.svg" />
        </IonButton>
      }
    >
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppContent modifiers="pepalexa-content--padding pepalexa-content--margin-bottom">
            <AppHeading modifiers="center">Framgång</AppHeading>
          </AppContent>
        </AppTopOfSheet>
        <AppSheet>
          <IonList className="light ion-no-padding">
            {isLoading && (
              <div className="ion-text-center">
                <IonSpinner color="dark" />
              </div>
            )}
            {entities.map((item) => (
              <IonItem
                key={item.id}
                routerLink={`/c/menu/manage/client/${id}/progress/${item.id}`}
              >
                <IonLabel>
                  <h2>{item.title}</h2>
                  <p>{getLatestProgressValue(item)}</p>
                </IonLabel>
                <IonButton
                  slot="end"
                  fill="clear"
                  onClick={(e) => doDelete(e, item.id)}
                >
                  <IonIcon slot="icon-only" icon={trashOutline} />
                </IonButton>
              </IonItem>
            ))}
          </IonList>
          <BottomSpacer />
        </AppSheet>
      </AppPageWithSheet>
      <Provider />
    </AppPageWithHideableHeader>
  );
};

export default ClientProgress;

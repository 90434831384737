import { processModifiers } from "../util";
import PrivateImage from "./PrivateImage";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-avatar/pepalexa-avatar.scss";

const AppAvatar = ({ modifiers, ...props }) => (
  <img
    {...props}
    alt={props.alt ?? ""}
    className={processModifiers("pepalexa-avatar", modifiers)}
  />
);

export const AppAvatarPrivate = ({ auth, modifiers, src, defaultImage }) => {
  return (
    <PrivateImage
      defaultImage={defaultImage}
      auth={auth}
      src={src}
      className={processModifiers("pepalexa-avatar", modifiers)}
    />
  );
};

export default AppAvatar;

import AppMessageBoxMedia from "./AppMessageBoxMedia";
import PrivateImage from "./PrivateImage";

import { useContentProvider } from "../providers/content-provider";

const AppMessageBoxGallery = ({ id, modifiers, onClick }) => {
  const { Provider, entity } = useContentProvider(id, "gallery", {
    include: "field_gallery_thumbnail,field_gallery_tags,field_gallery_file",
  });

  const entity_image = entity?.field_gallery_thumbnail?.image_style_uri?.medium
    ? entity?.field_gallery_thumbnail?.image_style_uri?.medium
    : entity?.field_gallery_file?.image_style_uri?.medium;

  return (
    <>
      <AppMessageBoxMedia
        onClick={onClick}
        modifiers={modifiers}
        media={
          <PrivateImage
            auth="auth"
            src={entity_image}
            height="250"
            style={{ objectFit: "cover" }}
          />
        }
      >
        {entity?.field_gallery_text}
      </AppMessageBoxMedia>
      <Provider />
    </>
  );
};

export default AppMessageBoxGallery;

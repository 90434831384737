import { useState } from "react";
import { useParams } from "react-router";
import {
  IonLoading,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonInput,
  useIonRouter,
} from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";
import {
  useZeusDataProvider,
  useZeusDataStatusSubscriber,
} from "@understandit/dynamic-data-react";

import { getSetting } from "../../settings";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import AppContent from "../../components/AppContent";
import AppHeading from "../../components/AppHeading";
import {
  AppPageWithSheet,
  AppSheet,
  AppTopOfSheet,
} from "../../compositions/AppPageWithSheet";

const ClientProgressAdd = () => {
  const { id } = useParams();
  const router = useIonRouter();
  const type = "progress";
  const providerId = `create-entity-${type}`;
  const { setData, isLoading } = useZeusDataProvider(providerId);
  const [formState, setFormState] = useState({
    title: "",
    field_progress_unit: "",
  });

  useZeusDataStatusSubscriber(providerId, (status) => {
    if (status === "created") {
      router.push(`/c/menu/manage/client/${id}/progress`, "back");
    }
  });

  const doSave = () => {
    const data = {
      _group: id,
      ...formState,
    };

    setData(data);
  };

  return (
    <AppPageWithHideableHeader
      title="Skapa framgång"
      defaultHref={`/c/menu/manage/client/${id}/progress`}
      backButton
      toolbarButtons={
        <IonButton onClick={doSave}>
          <IonIcon slot="icon-only" icon={checkmarkCircleOutline} />
        </IonButton>
      }
    >
      <IonLoading isOpen={isLoading} />
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppContent modifiers="padding margin-bottom">
            <AppHeading modifiers="center">Skapa framgång</AppHeading>
          </AppContent>
        </AppTopOfSheet>
        <AppSheet>
          <IonList className="form">
            <IonItem lines="full">
              <IonLabel position="stacked">
                Vad skall mätas (ex. Tid på 10 km löpning)
              </IonLabel>
              <IonInput
                value={formState.title}
                onIonChange={(e) =>
                  setFormState({ ...formState, title: e.detail.value })
                }
              />
            </IonItem>
            <IonItem lines="none">
              <IonLabel position="stacked">Enhet</IonLabel>
              <IonInput
                value={formState.field_progress_unit}
                onIonChange={(e) =>
                  setFormState({
                    ...formState,
                    field_progress_unit: e.detail.value,
                  })
                }
              />
            </IonItem>
          </IonList>
        </AppSheet>
      </AppPageWithSheet>
      <zeus-data-provider id={providerId} send-tags={type}>
        <zeus-data-source-drupal-group-member-content-create
          base-url={getSetting("BACKEND")}
          jsonapi-path="/api/v3"
          content-bundle={type}
          group-bundle="client"
          token-provider="auth"
        ></zeus-data-source-drupal-group-member-content-create>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </AppPageWithHideableHeader>
  );
};

export default ClientProgressAdd;

import { useMemo } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

export function useCoachClientsProvider(coachId) {
  const providerId = `coach-clients-provider-${coachId}`;
  const url = `${getSetting(
    "BACKEND"
  )}/api/v3/group_content/client-subgroup-coach?filter[entity_id.id]=${coachId}&include=gid.field_avatar`;

  const { data, isLoading } = useZeusDataProvider(providerId, []);

  const Provider = useMemo(() => {
    const Component = () => {
      if (!coachId) {
        return null;
      }
      return (
        <zeus-data-provider id={providerId}>
          <zeus-data-source-jsonapi url={url}></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
          <zeus-data-processor-jmespath filter="[*].gid"></zeus-data-processor-jmespath>
        </zeus-data-provider>
      );
    };
    return Component;
  }, [coachId, providerId, url]);

  return {
    Provider,
    clients: data,
    isLoading,
  };
}

import { Deserializer } from "jsonapi-serializer";

/**
 * @typedef {Object} Tokens
 * @property {string} access_token
 */

/**
 *
 * @returns {Promise<Tokens>}
 */
async function getTokens() {
  const q = new Promise((resolve, reject) => {
    document.addEventListener(
      `zeusDataAvailable:auth`,
      (event) => {
        if (event.detail && event.detail.tokens) {
          resolve(event.detail.tokens);
        } else {
          reject("No token");
        }
      },
      { once: true }
    );
  });
  document.dispatchEvent(new Event(`zeusDataListener:auth`));
  return q;
}

/**
 *
 * @param {string} url
 * @param {RequestInit} init
 */
export async function httpClient(url, init) {
  const tokens = await getTokens();
  init = {
    ...(init ?? {}),
    headers: {
      ...(init?.headers ?? {}),
      Authorization: `Bearer ${tokens?.access_token}`,
    },
  };

  return fetch(url, init);
}

/**
 *
 * @param {*} data
 * @returns {Primise<*>}
 */
export function deserializeJsonApi(data) {
  const opts = {
    keyForAttribute: (x) => x,
    typeAsAttribute: true,
  };
  const deserializer = new Deserializer(opts);
  return deserializer.deserialize(data);
}

import { useMemo } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

export function buildSearchUrl(
  type,
  searchString,
  searchTags = {},
  enableArchive,
  archiveValue
) {
  const path = `${getSetting("BACKEND")}/api/v3/group/${type}`;

  const params = {
    include: "field_avatar",
    sort: "field_last_name,field_first_name",
  };
  params["page[limit]"] = "300";

  if (searchString) {
    params["filter[name][condition][path]"] = "label";
    params["filter[name][condition][operator]"] = "CONTAINS";
    params["filter[name][condition][value]"] = searchString;
  }

  if (enableArchive && archiveValue) {
    params["filter[archived][condition][path]"] = "field_archived";
    params["filter[archived][condition][operator]"] = "=";
    params["filter[archived][condition][value]"] = "1";
  }

  if (enableArchive && !archiveValue) {
    params["filter[archived-filter][group][conjunction]"] = "OR";

    params["filter[archived][condition][path]"] = "field_archived";
    params["filter[archived][condition][operator]"] = "=";
    params["filter[archived][condition][value]"] = "0";
    params["filter[archived][condition][memberOf]"] = "archived-filter";

    params["filter[archived2][condition][path]"] = "field_archived";
    params["filter[archived2][condition][operator]"] = "IS NULL";
    params["filter[archived2][condition][memberOf]"] = "archived-filter";
  }

  Object.keys(searchTags).forEach((key) => {
    if (searchTags[key]) {
      params[`filter[${key}][condition][path]`] = `${key}.id`;
      params[`filter[${key}][condition][value]`] = searchTags[key];
    }
  });

  return `${path}?${new URLSearchParams(params)}`;
}

export function useProfileProvider(
  type,
  searchString,
  searchTags,
  showArchived = false
) {
  const providerId = `profile-provider-${type}`;
  const url = buildSearchUrl(
    type,
    searchString,
    searchTags,
    type === "client",
    showArchived
  );
  const { data, isLoading, setData } = useZeusDataProvider(providerId, []);

  const Provider = useMemo(() => {
    const Component = () => (
      <zeus-data-provider id={providerId} listen-tags={type}>
        <zeus-data-source-jsonapi
          type={`group--${type}`}
          relationships="field_avatar:file--file"
          url={url}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    );
    return Component;
  }, [providerId, type, url]);

  return {
    Provider,
    profiles: data,
    isLoading,
    setData,
  };
}

import { processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-small-title/pepalexa-small-title.scss";

const AppSmallTitle = ({ modifiers, children, ...props }) => {
  return (
    <h5 className={processModifiers("pepalexa-small-title", modifiers)}>
      {children}
    </h5>
  );
};

export default AppSmallTitle;

import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useZeusDataStatusSubscriber } from "@understandit/dynamic-data-react";
import {
  IonButton,
  IonIcon,
  IonList,
  IonLoading,
  IonItem,
  IonLabel,
  useIonRouter,
  IonInput,
  IonDatetime,
  IonModal,
  IonDatetimeButton,
  IonToggle,
} from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";

import { useParagraphProvider } from "../../providers/paragraph-provider";
import {
  AppPageWithSheet,
  AppSheet,
  AppTopOfSheet,
} from "../../compositions/AppPageWithSheet";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import BottomSpacer from "../../components/BottomSpacer";
import AppContent from "../../components/AppContent";
import AppHeading from "../../components/AppHeading";

const ProfileClientEditLofsanShopSub = () => {
  const { id, type, sub } = useParams();
  const defaultBack = `/c/menu/manage/profile/client/${id}`;
  const {
    Provider,
    entity,
    setData: updateSub,
    isLoading,
    providerId,
  } = useParagraphProvider(sub, type, {
    attributes:
      "field_subscription_label,field_subscription_from,field_subscription_to,field_subscription_active",
    providerTags: { "send-tags": "client" },
  });
  const router = useIonRouter();
  const [field_subscription_label, set_field_subscription_label] =
    useState(null);
  const [field_subscription_from, set_field_subscription_from] = useState(null);
  const [subscription_has_to, set_subscription_has_to] = useState(null);
  const [field_subscription_to, set_field_subscription_to] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    set_field_subscription_label(entity?.field_subscription_label);
    set_field_subscription_from(entity?.field_subscription_from);
    set_subscription_has_to(entity?.field_subscription_to ? true : false);
    set_field_subscription_to(entity?.field_subscription_to);
  }, [entity]);

  useZeusDataStatusSubscriber(providerId, (status) => {
    if (status === "updated") {
      setIsUpdating(false);
      router.push(defaultBack, "back");
    }
  });

  const doSave = () => {
    const now = new Date();
    const from = field_subscription_from
      ? field_subscription_from.replace(/\+[0-9][0-9]:[0-9][0-9]/, "Z")
      : null;
    const to = subscription_has_to
      ? field_subscription_to
        ? field_subscription_to.replace(/\+[0-9][0-9]:[0-9][0-9]/, "Z")
        : now.toISOString().replace(/\.[0-9]{3}/, "")
      : null;
    const fromDate = new Date(from);
    const toDate = to ? new Date(to) : null;
    const subData = {
      id: sub,
      type: `paragraph--${type}`,
      field_subscription_from: from,
      field_subscription_to: to,
      field_subscription_label: field_subscription_label,
      field_subscription_active: fromDate < now && (!toDate || toDate > now),
    };
    console.log(subData);

    setIsUpdating(true);
    updateSub(subData);
  };

  return (
    <AppPageWithHideableHeader
      title="Uppdatera abonnemang"
      defaultHref={defaultBack}
      backButton
      toolbarButtons={
        <IonButton
          onClick={doSave}
          disabled={field_subscription_label?.length === 0}
        >
          <IonIcon icon={checkmarkCircleOutline} slot="icon-only" />
        </IonButton>
      }
    >
      <Provider />
      <IonLoading isOpen={isLoading || isUpdating} />
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppContent modifiers="pepalexa-content--padding pepalexa-content--margin-bottom">
            <AppHeading modifiers="center">Uppdatera abonnemang</AppHeading>
          </AppContent>
        </AppTopOfSheet>
        <AppSheet>
          <IonList class="form">
            <IonItem lines="none">
              <IonLabel>Namn:</IonLabel>
              <IonInput
                value={field_subscription_label}
                onIonChange={(e) =>
                  set_field_subscription_label(e.detail.value)
                }
              />
            </IonItem>
            <IonModal keepContentsMounted={true} style={{ color: "black" }}>
              <IonDatetime
                firstDayOfWeek={1}
                locale="sv"
                value={field_subscription_from}
                id="datetime-modal-from"
                onIonChange={(e) => {
                  set_field_subscription_from(e.detail.value);
                }}
              />
            </IonModal>
            <IonItem>
              <IonLabel>Från:</IonLabel>
              <IonDatetimeButton
                style={{ "--ion-text-color": "black" }}
                color="dark"
                datetime="datetime-modal-from"
              ></IonDatetimeButton>
            </IonItem>
            <IonModal keepContentsMounted={true} style={{ color: "black" }}>
              <IonDatetime
                value={field_subscription_to}
                id="datetime-modal-to"
                onIonChange={(e) => {
                  set_field_subscription_to(e.detail.value);
                }}
              />
            </IonModal>
            <IonItem>
              <IonLabel>Ange till-värde:</IonLabel>
              <IonToggle
                checked={subscription_has_to}
                onIonChange={(e) => set_subscription_has_to(e.detail.checked)}
              />
            </IonItem>
            {subscription_has_to && (
              <IonItem lines="none">
                <IonLabel>Till:</IonLabel>
                <IonDatetimeButton
                  style={{ "--ion-text-color": "black" }}
                  color="dark"
                  datetime="datetime-modal-to"
                ></IonDatetimeButton>
              </IonItem>
            )}
          </IonList>
          <BottomSpacer />
        </AppSheet>
      </AppPageWithSheet>
    </AppPageWithHideableHeader>
  );
};

export default ProfileClientEditLofsanShopSub;

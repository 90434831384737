import { useState } from "react";
import {
  IonButton,
  IonIcon,
  IonList,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonModal,
  IonItem,
  IonAvatar,
  IonSpinner,
} from "@ionic/react";
import { addCircleOutline, closeCircleOutline } from "ionicons/icons";

import { useAppState } from "../app-context";
import { useCoachClientsProvider } from "../providers/coach-clients-provider";
import { AppPageWithSheet, AppSheet } from "../compositions/AppPageWithSheet";
import { useCreateThreadMutation } from "../api/messages";
import Content from "../components/AppContent";
import Heading from "../components/AppHeading";
import Container from "../components/AppContainer";
import NotificationListItem from "../compositions/AppNotificationListItem";
import AppPageWithHideableHeader from "../compositions/AppPageWithHideableHeader";
import BottomSpacer from "../components/BottomSpacer";
import PrivateImage from "../components/PrivateImage";

const AddThreadModal = ({ dismiss }) => {
  const { threads, groupId } = useAppState();
  const { Provider, clients, isLoading } = useCoachClientsProvider(groupId);
  const mutation = useCreateThreadMutation();
  const availableClients = clients.filter((client) => !threads.some((thread) => thread.clientId === client.id));

  const doCreateThread = async (clientId) => {
    await mutation.mutateAsync({ clientId, coachId: groupId });
    dismiss();
  };

  return (
    <>
      <IonHeader className="app-header">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={dismiss}>
              <IonIcon icon={closeCircleOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>Starta ny meddelandetråd</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <AppPageWithSheet>
          <AppSheet>
            {isLoading && (
              <div className="ion-text-center">
                <IonSpinner color="dark" />
              </div>
            )}
            {!isLoading && availableClients?.length === 0 && (
              <div className="ion-text-center" style={{ color: "#000" }}>
                Du har redan öppna trådar med alla dina klienter
              </div>
            )}
            <IonList class="light ion-no-padding">
              {availableClients?.map((client) => (
                <IonItem key={client.id} onClick={() => doCreateThread(client.id)} button>
                  <IonAvatar slot="start">
                    <PrivateImage
                      auth="auth"
                      src={client.field_avatar?.image_style_uri?.medium}
                      defaultImage="assets/images/default-avatar.png"
                    />
                  </IonAvatar>
                  {client.field_first_name} {client.field_last_name}
                </IonItem>
              ))}
            </IonList>
            <BottomSpacer />
          </AppSheet>
        </AppPageWithSheet>
      </IonContent>
      <Provider />
    </>
  );
};

const Messages = ({ mode }) => {
  const [newThreadModalOpen, setNewThreadModalOpen] = useState(false);
  const { threads, unreadMessages } = useAppState();

  return (
    <AppPageWithHideableHeader
      title="Meddelanden"
      toolbarButtons={
        mode === "coach" && (
          <IonButton onClick={() => setNewThreadModalOpen(true)}>
            <IonIcon slot="icon-only" icon={addCircleOutline} />
          </IonButton>
        )
      }
    >
      <IonModal isOpen={newThreadModalOpen} onWillDismiss={() => setNewThreadModalOpen(false)}>
        <AddThreadModal dismiss={() => setNewThreadModalOpen(false)} />
      </IonModal>
      <div className="wrapper">
        <div className="wrapper__column">
          <Content modifiers="padding margin-bottom">
            <Heading modifiers="center">Meddelanden</Heading>
          </Content>
        </div>
        <div className="wrapper__column wrapper__column--flex">
          <Container modifiers="rounded-top fill">
            <IonList>
              {threads?.map((thread) => (
                <NotificationListItem
                  key={thread.id}
                  title={thread.title}
                  avatarPrivateAuth="auth"
                  avatar={thread.image ?? "assets/images/default-avatar.png"}
                  subtitle={mode === "coach" ? "Klient" : "Coach"}
                  link={`/c/messages/${thread.id}`}
                  button={thread.mode === "client"}
                  badge={unreadMessages.find((x) => x.threadId === thread.id)?.unread}
                />
              ))}
            </IonList>
            <BottomSpacer />
          </Container>
        </div>
      </div>
    </AppPageWithHideableHeader>
  );
};

export default Messages;

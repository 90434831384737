import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonSplitPane,
  IonIcon,
  IonMenu,
  IonContent,
  IonBadge,
  IonHeader,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { IonReactHashRouter } from "@ionic/react-router";
import {
  useDynamicDataAuthentication,
  useDynamicDataProvider,
  useZeusDataProvider,
} from "@understandit/dynamic-data-react";
import { useOneSignal, setExternalUserId, removeExternalUserId } from "./notifications";

import { getSetting } from "./settings";
import { useConnector, ConnectorProvider } from "./form/connector-context";
import { AppStateProvider, useAppState } from "./app-context";

import AppConnector from "./form/AppConnector";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import Menu from "./pages/Menu";
import WorkoutDetail from "./pages/WorkoutDetail";
import Workout from "./pages/Workout";
import Diet from "./pages/Diet";
import DietDetail from "./pages/DietDetail";
import Gallery from "./pages/Gallery";
import GalleryDetail from "./pages/GalleryDetail";
import WorkoutLog from "./pages/WorkoutLog";
import Messages from "./pages/Messages";
import MessageThread from "./pages/MessageThread";
import Progress from "./pages/Progress";
import ProgressDetail from "./pages/ProgressDetail";
import PasswordReset from "./pages/PasswordReset";
import WorkoutBlockDetail from "./pages/WorkoutBlockDetail";

import ClientEntityList from "./pages/manage/ClientEntityList";
import ClientGallery from "./pages/manage/ClientGallery";
import ClientGalleryDetail from "./pages/manage/ClientGalleryDetail";
import ClientList from "./pages/manage/ClientList";
import ClientProfile from "./pages/manage/ClientProfile";
import ClientProgress from "./pages/manage/ClientProgress";
import ClientView from "./pages/manage/ClientView";
import ClientWorkoutLog from "./pages/manage/ClientWorkoutLog";
import ClientWorkoutLogDetail from "./pages/manage/ClientWorkoutLogDetail";
import ClientProgressDetail from "./pages/manage/ClientProgressDetail";
import ClientProgressAdd from "./pages/manage/ClientProgressAdd";
import EntityAdd from "./pages/manage/EntityAdd";
import EntityCopy from "./pages/manage/EntityCopy";
import EntityEdit from "./pages/manage/EntityEdit";
import EntityList from "./pages/manage/EntityList";
import EntityView from "./pages/manage/EntityView";
import ClientMessages from "./pages/manage/ClientMessages";
import ClientMessageThread from "./pages/manage/ClientMessageThread";
import Profiles from "./pages/manage/Profiles";
import ProfileClient from "./pages/manage/ProfileClient";
import ProfileCoach from "./pages/manage/ProfileCoach";
import ProfileCoachEdit from "./pages/manage/ProfileCoachEdit";
import ProfileClientEdit from "./pages/manage/ProfileClientEdit";
import ProfileCoachAdd from "./pages/manage/ProfileCoachAdd";
import ProfileClientAdd from "./pages/manage/ProfileClientAdd";
import ProfileClientAddSub from "./pages/manage/ProfileClientAddSub";
import ProfileClientEditLofsanShopSub from "./pages/manage/ProfileClientEditLofsanShopSub";
import ProfileClientEditSub from "./pages/manage/ProfileClientEditSub";

// Create a client
const queryClient = new QueryClient();

const NotificationReciever = () => {
  const router = useIonRouter();
  useOneSignal((jsonData) => {
    console.log("Push recieed!!!", jsonData.notification);
    router.push(`/c/dashboard`, "root");
  });
  return null;
};

const routes = [
  { path: "/c/dashboard", Component: Dashboard },
  { path: "/c/dashboard/profile", Component: Profile },
  {
    path: "/c/dashboard/diet/:id",
    Component: DietDetail,
    props: { defaultHref: "/c/dashboard" },
  },
  {
    path: "/c/dashboard/workout/:id",
    Component: WorkoutDetail,
    props: { defaultHref: "/c/dashboard" },
  },
  {
    path: "/c/dashboard/workout-log/:client_id/:id",
    Component: ClientWorkoutLogDetail,
    props: { defaultHref: "/c/dashboard" },
  },
  {
    path: "/c/dashboard/gallery/:client_id/:id",
    Component: ClientGalleryDetail,
    props: { defaultHref: "/c/dashboard" },
  },
  {
    path: "/c/dashboard/profile/:id",
    Component: ClientProfile,
    props: { defaultHref: "/c/dashboard" },
  },

  { path: "/c/messages", Component: Messages },
  { path: "/c/messages/:id", Component: MessageThread },
  {
    path: "/c/messages/preview/gallery/:id",
    Component: ClientGalleryDetail,
    props: { defaultHref: "/c/messages", preview: true },
  },
  {
    path: "/c/messages/preview/workout/:id/block/:index",
    Component: WorkoutBlockDetail,
    props: { defaultHref: "/c/messages", preview: true },
  },

  { path: "/c/menu", Component: Menu },

  { path: "/c/menu/workout", Component: Workout },
  { path: "/c/menu/workout/:id", Component: WorkoutDetail },
  { path: "/c/menu/workout/:id/block/:index", Component: WorkoutBlockDetail },

  { path: "/c/menu/workout-log", Component: WorkoutLog },

  { path: "/c/menu/diet", Component: Diet },
  { path: "/c/menu/diet/:id", Component: DietDetail },

  { path: "/c/menu/gallery", Component: Gallery },
  { path: "/c/menu/gallery/:id", Component: GalleryDetail },

  { path: "/c/menu/progress", Component: Progress },
  { path: "/c/menu/progress/:id", Component: ProgressDetail },

  // Admin routes.
  { path: "/c/menu/manage/profile", Component: Profiles },
  { path: "/c/menu/manage/profile/add/client", Component: ProfileClientAdd },
  { path: "/c/menu/manage/profile/client/:id", Component: ProfileClient },
  {
    path: "/c/menu/manage/profile/client/:id/edit",
    Component: ProfileClientEdit,
  },
  {
    path: "/c/menu/manage/profile/client/:id/add-sub",
    Component: ProfileClientAddSub,
  },
  {
    path: "/c/menu/manage/profile/client/:id/edit-lofsan-shop-sub/:type/:sub",
    Component: ProfileClientEditLofsanShopSub,
  },
  {
    path: "/c/menu/manage/profile/client/:id/edit-sub/:type/:sub",
    Component: ProfileClientEditSub,
  },
  { path: "/c/menu/manage/profile/add/coach", Component: ProfileCoachAdd },
  { path: "/c/menu/manage/profile/coach/:id", Component: ProfileCoach },
  {
    path: "/c/menu/manage/profile/coach/:id/edit",
    Component: ProfileCoachEdit,
  },

  // Coach and admin routes.
  { path: "/c/menu/manage/client", Component: ClientList },
  { path: "/c/menu/manage/client/:id", Component: ClientView },
  { path: "/c/menu/manage/client/:id/gallery", Component: ClientGallery },
  {
    path: "/c/menu/manage/client/:client_id/gallery/:id",
    Component: ClientGalleryDetail,
  },
  {
    path: "/c/menu/manage/client/:id/workout-log",
    Component: ClientWorkoutLog,
  },
  {
    path: "/c/menu/manage/client/:id/messages",
    Component: ClientMessages,
  },
  {
    path: "/c/menu/manage/client/:client_id/messages/:id",
    Component: ClientMessageThread,
  },
  {
    path: "/c/menu/manage/client/:client_id/workout-log/:id",
    Component: ClientWorkoutLogDetail,
  },
  {
    path: "/c/menu/manage/client/:id/progress",
    Component: ClientProgress,
  },

  {
    path: "/c/menu/manage/client/:client_id/progress/:id",
    Component: ClientProgressDetail,
  },
  {
    path: "/c/menu/manage/client/:id/progress/add",
    Component: ClientProgressAdd,
  },
  {
    path: "/c/menu/manage/client/:id/profile",
    Component: ClientProfile,
  },
];

for (const type of ["diet", "workout"]) {
  routes.push({
    path: `/c/menu/manage/client/:id/${type}`,
    Component: ClientEntityList,
    props: { type },
  });
  routes.push({
    path: `/c/menu/manage/client/:client_id/${type}/:id`,
    Component: EntityView,
    props: { type, editable: true, context: "client" },
  });
  routes.push({
    path: `/c/menu/manage/client/:client_id/${type}/:id/edit`,
    Component: EntityEdit,
    props: { type, context: "client" },
  });
  routes.push({
    path: `/c/menu/manage/client/:client_id/${type}/:id/copy`,
    Component: EntityCopy,
    props: { type, context: "client" },
  });
}

for (const type of ["exercise", "diet", "workout"]) {
  routes.push({
    path: `/c/menu/manage/${type}`,
    props: { type },
    Component: EntityList,
  });
  routes.push({
    path: `/c/menu/manage/common/${type}/:id`,
    props: { type },
    Component: EntityView,
  });
  routes.push({
    path: `/c/menu/manage/my/${type}/:id`,
    props: { type, editable: true },
    Component: EntityView,
  });
  routes.push({
    path: `/c/menu/manage/${type}/:id/edit`,
    props: { type },
    Component: EntityEdit,
  });
  routes.push({
    path: `/c/menu/manage/${type}/:id/copy`,
    props: { type },
    Component: EntityCopy,
  });
  routes.push({
    path: `/c/menu/manage/${type}/add`,
    props: { type },
    Component: EntityAdd,
  });
}

const UserDataSources = () => {
  const [userId] = useDynamicDataProvider("user-id", "");
  return (
    <>
      <zeus-data-provider id="user-id">
        <zeus-data-source-json url={`${getSetting("BACKEND")}/api/v3`}></zeus-data-source-json>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        <zeus-data-processor-jmespath filter="meta.links.me.meta.id"></zeus-data-processor-jmespath>
      </zeus-data-provider>

      {userId && (
        <zeus-data-provider id="user">
          <zeus-data-source-jsonapi
            url={`${getSetting("BACKEND")}/api/v3/user/user/${userId}?include=roles`}
          ></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      )}

      <zeus-data-provider id="workout-tags" listen-tags="workout-tags">
        <zeus-data-source-jsonapi
          url={`${getSetting("BACKEND")}/api/v3/taxonomy_term/workout_tags`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>

      <zeus-data-provider id="exercise-tags" listen-tags="exercise-tags">
        <zeus-data-source-jsonapi
          url={`${getSetting("BACKEND")}/api/v3/taxonomy_term/exercise_tags`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>

      <zeus-data-provider id="exercise-tools" listen-tags="exercise-tools">
        <zeus-data-source-jsonapi
          url={`${getSetting("BACKEND")}/api/v3/taxonomy_term/exercise_tools`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>

      <zeus-data-provider id="diet-tags" listen-tags="diet-tags">
        <zeus-data-source-jsonapi
          url={`${getSetting("BACKEND")}/api/v3/taxonomy_term/diet_tags`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>

      <zeus-data-provider id="gallery-tags" listen-tags="gallery-tags">
        <zeus-data-source-jsonapi
          url={`${getSetting("BACKEND")}/api/v3/taxonomy_term/gallery_tags`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>

      <zeus-data-provider id="profile-tags" listen-tags="profile-tags">
        <zeus-data-source-jsonapi
          url={`${getSetting("BACKEND")}/api/v3/taxonomy_term/profile_tags`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>

      {userId && (
        <zeus-data-provider id="client" listen-tags="client">
          <zeus-data-source-jsonapi
            url={`${getSetting(
              "BACKEND"
            )}/api/v3/group_content/client-group_membership?filter[entity_id.id]=${userId}&include=gid.field_avatar,gid.field_subscriptions`}
          ></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
          <zeus-data-processor-jmespath filter="[0].gid"></zeus-data-processor-jmespath>
        </zeus-data-provider>
      )}

      {userId && (
        <zeus-data-provider id="collection" listen-tags="coach">
          <zeus-data-source-jsonapi
            url={`${getSetting(
              "BACKEND"
            )}/api/v3/group_content/coach-group_membership?filter[entity_id.id]=${userId}&include=gid.field_avatar`}
          ></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
          <zeus-data-processor-jmespath filter="[0].gid"></zeus-data-processor-jmespath>
        </zeus-data-provider>
      )}
    </>
  );
};

const MessageBadge = () => {
  const { unreadMessages } = useAppState();
  const allUnread = unreadMessages.reduce((acc, i) => acc + i.unread, 0);
  if (allUnread > 0) {
    return <IonBadge>{allUnread}</IonBadge>;
  }
  return null;
};

const AuthEnv = () => {
  const { connectorIsOpen } = useConnector();
  const [userId] = useDynamicDataProvider("user-id", "");
  const { data: user } = useZeusDataProvider("user");
  const { data: client } = useZeusDataProvider("client");
  const { data: coach } = useZeusDataProvider("collection");

  const mode = coach ? "coach" : client ? "client" : "";
  const groupId = mode === "coach" ? coach.id : client?.id;
  const isAdmin = user?.roles?.some((i) => i.drupal_internal__id === "administrator");

  const tabsRef = useRef(null);

  /*
   * Register the userId at onesignal when available
   */
  useEffect(() => {
    if (userId) {
      console.log(`setExternalUserId(${userId})`);
      setExternalUserId(userId);
    }
  }, [userId]);

  /*
   * Unregister the userId at onesignal on logout
   */
  useEffect(() => {
    return () => {
      console.log("removeExternalUserId");
      removeExternalUserId();
    };
  }, []);

  return (
    <AppStateProvider
      tabs={tabsRef}
      mode={mode}
      groupId={groupId}
      profile={mode === "coach" ? coach : client}
      isAdmin={isAdmin}
    >
      <IonSplitPane contentId="main">
        <IonTabs id="main" ref={tabsRef}>
          <IonRouterOutlet>
            {routes.map((r) => (
              <Route path={r.path} exact key={r.path}>
                <r.Component {...(r.props ?? {})} mode={mode} groupId={groupId} inTabs={true} isAdmin={isAdmin} />
              </Route>
            ))}
          </IonRouterOutlet>

          <IonTabBar slot="bottom" className="app-tab-bar" translucent={true}>
            <IonTabButton tab="home" href="/c/dashboard">
              <IonIcon src="assets/icons/home.svg" />
            </IonTabButton>
            <IonTabButton tab="messages" href="/c/messages">
              <IonIcon src="assets/icons/message.svg" />
              <MessageBadge />
            </IonTabButton>
            <IonTabButton tab="menu" href="/c/menu">
              <IonIcon src="assets/icons/menu.svg" />
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
        <IonMenu
          className="connector-menu"
          contentId="main"
          side="end"
          disabled={!connectorIsOpen}
          menuId="connector-menu"
        >
          <IonHeader className="app-header">
            <IonToolbar></IonToolbar>
          </IonHeader>
          <IonContent>
            <AppConnector />
          </IonContent>
        </IonMenu>
      </IonSplitPane>
      <UserDataSources />
    </AppStateProvider>
  );
};

const App = () => {
  const { isAuthenticated, isLoading } = useDynamicDataAuthentication("auth");
  return (
    <QueryClientProvider client={queryClient}>
      <IonApp>
        <IonReactHashRouter>
          <NotificationReciever />
          <IonRouterOutlet>
            <Route path={`/login`} exact>
              {!isAuthenticated ? <Login /> : <Redirect to="/c/dashboard" />}
            </Route>
            <Route path={`/login/password`} exact>
              {!isAuthenticated ? <PasswordReset /> : <Redirect to="/c/dashboard" />}
            </Route>
            <Route path={`/c`}>
              {isAuthenticated ? (
                <ConnectorProvider>
                  <AuthEnv />
                </ConnectorProvider>
              ) : isLoading ? (
                "Laddar..."
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Redirect from="/" to="/login" exact />
          </IonRouterOutlet>
        </IonReactHashRouter>
        <zeus-data-provider id="auth" no-cache>
          <zeus-data-source-oauth
            url={`${getSetting("BACKEND")}/oauth/token`}
            client-id="5a6c29c8-4864-4362-a895-27fb2b3f078d"
            grant-type="password"
            client-secret="1cd448d5-1e79-4447-9f30-9ca643f34181"
            scope="coach client administrator"
          ></zeus-data-source-oauth>
        </zeus-data-provider>
      </IonApp>
    </QueryClientProvider>
  );
};

export default App;

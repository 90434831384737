import { useParams } from "react-router";
import { useDynamicDataProvider } from "@understandit/dynamic-data-react";

import { useAppState } from "../app-context";
import { getSetting } from "../settings";
import { useUploadMedia, useRichMessage } from "../hooks";
import AppContent from "../components/AppContent";
import AppHeading from "../components/AppHeading";
import AppPageWithHideableHeader from "../compositions/AppPageWithHideableHeader";
import AppExerciseCard from "../compositions/AppExerciseCard";
import BottomSpacer from "../components/BottomSpacer";
import EmbededVideo from "../components/EmbededVideo";

const ExerciseMedia = ({ type, src }) => {
  if (type === "video") {
    return <EmbededVideo src={src} />;
  }
  if (type === "image") {
    return <img src={src} alt="alt" />;
  }
};

const WorkoutBlockDetail = ({ groupId, defaultHref, preview = false }) => {
  const { id, index } = useParams();
  const [workout] = useDynamicDataProvider(`workout-${id}`);
  const selectedBlock = workout?.field_workout_blocks?.[index];
  const { mode, threads } = useAppState();

  const uploadMedia = useUploadMedia(groupId);
  const sendRichMessage = useRichMessage(groupId);

  return (
    <AppPageWithHideableHeader title={workout?.title} defaultHref={defaultHref ?? `/c/menu/workout/${id}`} backButton>
      <AppContent modifiers="pepalexa-content--padding pepalexa-content--margin-bottom">
        <AppHeading modifiers="center">{selectedBlock?.field_title ?? "Block"}</AppHeading>
      </AppContent>

      <AppContent
        modifiers="opacity padding margin-top margin-bottom"
        innerContent={selectedBlock?.field_description?.value}
      />
      {selectedBlock?.field_workout_block_exercises?.map((exercise, i) => (
        <AppExerciseCard
          key={`${exercise.id}-${i}`}
          exercise_title={exercise.field_public_title ?? exercise.title}
          exercise_media={
            <ExerciseMedia
              type={exercise.field_media?.field_media_oembed_video ? "video" : "image"}
              src={
                exercise?.field_media?.field_media_oembed_video ?? exercise.field_media?.thumbnail.image_style_uri.large
              }
            />
          }
          exercise_description={exercise.body?.value}
          exercise_tools={exercise.field_exercise_tools?.map((i) => i?.name).join(", ")}
          onMessageClick={() => {
            if (threads.length) {
              sendRichMessage("workout", id, exercise, index);
            }
          }}
          onMediaClick={() => uploadMedia()}
          showButtons={!preview && mode === "client"}
        />
      ))}
      <BottomSpacer />
      <zeus-data-provider id={`workout-${id}`}>
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/api/v3/node/workout/${id}?include=field_workout_tags,field_workout_image.thumbnail,field_workout_blocks.field_workout_block_exercises.field_media.thumbnail,field_workout_blocks.field_workout_block_exercises.field_exercise_tools`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </AppPageWithHideableHeader>
  );
};

export default WorkoutBlockDetail;

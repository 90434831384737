import { IonItem, IonLabel } from "@ionic/react";

import AppAvatar from "../components/AppAvatar";
import AppIconBlock from "../components/AppIconBlock";

import "@understandit/lofsangruppen-storybook-22173948/compositions/informative-list-item/informative-list-item.scss";

const AppInformativeListItem = (props) => (
  <IonItem className="ion-item--border-transparent ion-item--color-highlighted ion-item--informative">
    {props.avatar && (
      <AppAvatar
        src={props.avatar}
        modifiers="list-small bordered margin-right"
      />
    )}
    <IonLabel>
      <h2>{props.name}</h2>
      <p>{props.date}</p>
    </IonLabel>
    {props.icon && (
      <AppIconBlock onClick={props.onIconClick} icon={props.icon}>
        {props.iconText}
      </AppIconBlock>
    )}
  </IonItem>
);

export default AppInformativeListItem;

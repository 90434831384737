import { useState, useEffect, useRef } from "react";
import {
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonList,
  IonButton,
  IonIcon,
  IonLabel,
  IonInput,
  IonSearchbar,
  IonModal,
  IonSpinner,
} from "@ionic/react";
import { add } from "ionicons/icons";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { pageConfig } from "../settings";
import { buildSearchUrl } from "./helpers";
import { useConnector } from "./connector-context";
import { AppTabsHeader, AppTabsButton } from "../components/AppTabs";
import { getSetting } from "../settings";
import AppTagFilter from "./AppTagFilter";
import EntityPreview from "../pages/manage/EntityPreview";

const ConnectorField = ({ target, onEntityAdd }) => {
  const bundle = target.type === "workout-blocks" ? "exercise" : target.bundle;
  const settings = pageConfig[bundle];
  const providerId = `connector-${target.field}`;
  const { data: collection } = useZeusDataProvider("collection");
  const providerRef = useRef();
  const [searchTags, setSearchTags] = useState({});
  const [searchString, setSearchString] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchUrl, setSearchUrl] = useState("");
  const [blockTitle, setBlockTitle] = useState("");
  const [previewId, setPreviewId] = useState("");
  const [selectedCollection, setSelectedCollection] = useState("");
  const { data: entities, isLoading } = useZeusDataProvider(providerId, []);
  const inCommonCollection =
    selectedCollection === getSetting("COMMON_COLLECTION");
  const hasMore = Boolean(providerRef.current?.source?.hasNextPage);

  useEffect(() => {
    if (collection?.id && selectedCollection === "") {
      setSelectedCollection(collection.id);
    }
  }, [collection, selectedCollection]);

  useEffect(() => {
    setSearchUrl(
      buildSearchUrl(bundle, selectedCollection, searchString, searchTags)
    );
  }, [bundle, selectedCollection, searchString, searchTags]);

  const doLoadMore = () => {
    document.dispatchEvent(
      new CustomEvent(`zeusDataOperation:${providerId}`, {
        detail: { name: "next-page" },
      })
    );
  };

  const doAddEntity = (entity) => {
    onEntityAdd?.({ field: target, entity });
  };

  const doSearch = (e) => {
    e?.preventDefault?.();
    setSearchString(searchValue);
  };

  const doResetFilter = () => {
    setSearchString("");
    setSearchValue("");
    setSearchTags({});
  };

  const doAddBlock = () => {
    onEntityAdd?.({
      field: target,
      entity: {
        id: `new__${Math.random()}`,
        field_title: blockTitle,
        field_workout_block_exercises: [],
      },
    });
    setBlockTitle("");
  };

  return (
    <>
      <IonModal isOpen={previewId !== ""} onDidDismiss={() => setPreviewId("")}>
        <EntityPreview
          id={previewId}
          type={bundle}
          dismiss={() => setPreviewId("")}
        />
      </IonModal>
      {target.type === "workout-blocks" && (
        <IonAccordion value={`block-${target.field}`}>
          <IonItem slot="header" color="light">
            <IonLabel>Block</IonLabel>
          </IonItem>
          <IonList slot="content">
            <IonItem className="connector-entity">
              <IonInput
                placeholder="Namn"
                value={blockTitle}
                onIonChange={(e) => setBlockTitle(e.target.value)}
              />
              <IonButton
                slot="end"
                fill="clear"
                onClick={doAddBlock}
                disabled={blockTitle === ""}
              >
                <IonIcon slot="icon-only" icon={add} />
              </IonButton>
            </IonItem>
          </IonList>
        </IonAccordion>
      )}

      <IonAccordion value={target.field}>
        <IonItem slot="header" color="light">
          {target.label}
        </IonItem>

        <IonList slot="content" className="ion-no-padding">
          <AppTabsHeader>
            <AppTabsButton
              onClick={() =>
                setSelectedCollection(getSetting("COMMON_COLLECTION"))
              }
              selected={inCommonCollection}
            >
              Gemensamma
            </AppTabsButton>
            <AppTabsButton
              onClick={() =>
                collection?.id && setSelectedCollection(collection.id)
              }
              selected={selectedCollection === collection?.id}
            >
              Mina
            </AppTabsButton>
          </AppTabsHeader>

          <IonItem color="medium" lines="none">
            <form
              onSubmit={doSearch}
              action="/"
              className="connector-search-form"
            >
              <IonSearchbar
                className="connector-searchbar"
                placeholder="Sök"
                inputmode="search"
                value={searchValue}
                debounce={0}
                onIonChange={(e) => setSearchValue(e.target.value)}
              />
            </form>
          </IonItem>

          {settings.searchTags.map((tag, index, array) => (
            <AppTagFilter
              color="medium"
              lines={index !== array.length - 1 ? "inset" : "none"}
              key={tag.field}
              label={tag.label}
              provider={tag.provider}
              value={searchTags[tag.field] ?? ""}
              onChange={(value) =>
                setSearchTags((current) => ({
                  ...current,
                  [tag.field]: value,
                }))
              }
            />
          ))}

          <IonItem color="medium">
            <IonButton onClick={doResetFilter}>Återställ</IonButton>
          </IonItem>

          {entities.map((entity) => (
            <IonItem
              key={entity.id}
              className="connector-entity"
              button
              onClick={() => setPreviewId(entity.id)}
            >
              <IonLabel>{entity.title}</IonLabel>
              <IonButton
                slot="end"
                fill="clear"
                onClick={(e) => {
                  e.stopPropagation();
                  doAddEntity(entity);
                }}
              >
                <IonIcon slot="icon-only" icon={add} />
              </IonButton>
            </IonItem>
          ))}

          {isLoading && (
            <div className="ion-text-center ion-padding">
              <IonSpinner color="light" />
            </div>
          )}

          {hasMore && (
            <div className="ion-text-center ion-padding">
              <IonButton
                color="secondary"
                fill="outline"
                size="small"
                onClick={doLoadMore}
              >
                Ladda fler
              </IonButton>
            </div>
          )}
        </IonList>

        <zeus-data-provider
          id={providerId}
          listen-tags={target.bundle}
          ref={providerRef}
        >
          <zeus-data-source-jsonapi url={searchUrl}></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
          <zeus-data-processor-jmespath filter="[*].entity_id"></zeus-data-processor-jmespath>
        </zeus-data-provider>
      </IonAccordion>
    </>
  );
};

const AppConnector = () => {
  const { connectorTargets, connectorCallback } = useConnector();

  const onEntityAdd = (conf) => {
    connectorCallback.cb(conf);
  };

  return (
    <IonAccordionGroup>
      {connectorTargets.map((target) => (
        <ConnectorField
          key={target.field}
          target={target}
          onEntityAdd={onEntityAdd}
        />
      ))}
    </IonAccordionGroup>
  );
};

export default AppConnector;

import { useState } from "react";
import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonIcon,
  IonSpinner,
} from "@ionic/react";
import { cameraOutline } from "ionicons/icons";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { useUploadMedia } from "../hooks";
import { useAppState } from "../app-context";
import AppPageWithHideableHeader from "../compositions/AppPageWithHideableHeader";
import AppGalleryCard from "../compositions/AppGalleryCard";
import Content from "../components/AppContent";
import BottomSpacer from "../components/BottomSpacer";
import PrivateImage from "../components/PrivateImage";
import AppHeading from "../components/AppHeading";

const Gallery = ({ groupId }) => {
  const { data: galleryTags } = useZeusDataProvider("gallery-tags", []);
  const {
    galleryItems,
    hasMoreGalleryItems,
    loadMoreGalleryItems,
    galleryIsLoading,
  } = useAppState();
  const [filter, setFilter] = useState([]);
  const uploadMedia = useUploadMedia(groupId);

  const isChecked = (id) => {
    return filter.includes(id);
  };

  const filterFuntion = (item) =>
    filter.length === 0 || filter.includes(item.field_gallery_tags?.id);

  const doToggleFilter = (id) => {
    if (isChecked(id)) {
      setFilter(filter.filter((i) => i !== id));
    } else {
      setFilter([...filter, id]);
    }
  };

  return (
    <AppPageWithHideableHeader
      title="Mitt träningsgalleri"
      defaultHref="/c/menu"
      backButton
      toolbarButtons={
        <IonButton onClick={uploadMedia}>
          <IonIcon slot="icon-only" icon={cameraOutline} />
        </IonButton>
      }
    >
      <Content modifiers="padding margin-bottom">
        <AppHeading modifiers="center">Galleri</AppHeading>
      </Content>
      <Content modifiers="padding">
        <div className="checkbox-container">
          {galleryTags.map((tag) => (
            <IonItem className="checkbox-item" lines="none" key={tag.id}>
              <IonCheckbox
                checked={isChecked(tag.id)}
                onIonChange={(e) => doToggleFilter(tag.id)}
              />
              <IonLabel>{tag.name}</IonLabel>
            </IonItem>
          ))}
        </div>
      </Content>
      <Content>
        <IonGrid>
          <IonRow>
            {galleryItems.filter(filterFuntion).map((item) => (
              <IonCol size="6" key={item.id} className="gallery-column">
                <AppGalleryCard
                  modifiers="pepalexa-card--short-text"
                  media={
                    <PrivateImage
                      auth="auth"
                      src={
                        item.field_gallery_thumbnail?.image_style_uri.large ??
                        item.field_gallery_file?.image_style_uri.large
                      }
                    />
                  }
                  routerLink={`/c/menu/gallery/${item.id}`}
                >
                  {item.field_gallery_text}
                </AppGalleryCard>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>

        <IonGrid>
          <IonRow>
            <IonCol className="ion-text-center">
              {galleryIsLoading && <IonSpinner />}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center">
              {hasMoreGalleryItems && (
                <IonButton
                  color="secondary"
                  fill="outline"
                  onClick={loadMoreGalleryItems}
                >
                  Ladda fler
                </IonButton>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </Content>
      <BottomSpacer />
    </AppPageWithHideableHeader>
  );
};

export default Gallery;

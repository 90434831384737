import { processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-message-box-media/pepalexa-message-box-media.scss";

const AppMessageBoxMedia = ({ modifiers, media, children, ...props }) => (
  <div
    {...props}
    className={processModifiers("pepalexa-message-box-media", modifiers)}
    style={{cursor:"pointer"}}
  >
    <div className="pepalexa-message-box-media__media">{media}</div>
    <div className="pepalexa-message-box-media__description">{children}</div>
  </div>
);

export default AppMessageBoxMedia;

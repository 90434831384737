import { Children, useState } from "react";
import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-tabs/pepalexa-tabs.scss";

import { classList } from "../util";

export const AppTabsHeader = ({ children }) => {
  return (
    <div className="pepalexa-tabs" role="tablist">
      <header className="pepalexa-tabs__header">{children}</header>
    </div>
  );
};

export const AppTabsButton = ({ selected, children, onClick }) => (
  <button
    onClick={onClick}
    role="tab"
    aria-selected={selected}
    className={classList(
      "pepalexa-tabs__button",
      selected && "pepalexa-tabs__button--is-selected"
    )}
  >
    {children}
  </button>
);

export const AppTabPanel = ({ children, selected }) => (
  <div
    role="tabpanel"
    className={classList(
      "pepalexa-tabs__tabpanel",
      selected && "pepalexa-tabs__tabpanel--is-selected"
    )}
  >
    {children}
  </div>
);

const AppTabs = ({ children }) => {
  Children.toArray(children).map((child) => console.log(child));
  const buttons = [];
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className="pepalexa-tabs" role="tablist">
      <AppTabsHeader>
        {buttons.map((button, i) => (
          <AppTabsButton
            key={i}
            selected={i === activeIndex}
            onClick={() => setActiveIndex(i)}
          >
            {button}
          </AppTabsButton>
        ))}
      </AppTabsHeader>
      {Children.map((Pane, i) => (
        <Pane selected={activeIndex === i} />
      ))}
    </div>
  );
};

export default AppTabs;

import { getSetting } from "../settings";

const relationShipTypes = ["term", "media", "node-reference", "workout-blocks"];

export function buildSearchUrl(
  entityType,
  collectionId,
  searchString,
  searchTags = {}
) {
  if (!collectionId) {
    return null;
  }

  const path = `${getSetting(
    "BACKEND"
  )}/api/v3/group_content/coach-group_node-${entityType}`;

  const params = {
    "filter[gid.id]": collectionId,
    include: "entity_id",
    sort: "entity_id.title",
  };

  if (searchString) {
    params["filter[title-filter][condition][path]"] = "entity_id.title";
    params["filter[title-filter][condition][operator]"] = "CONTAINS";
    params["filter[title-filter][condition][value]"] = searchString.trim();
  }

  Object.keys(searchTags).forEach((key) => {
    if (searchTags[key]) {
      params[`filter[${key}][condition][path]`] = `entity_id.${key}.id`;
      params[`filter[${key}][condition][value]`] = searchTags[key];
    }
  });

  return `${path}?${new URLSearchParams(params)}`;
}

export function generateRelationshipsString(fields) {
  return fields
    .filter((item) => relationShipTypes.includes(item.type))
    .map((item) => {
      if (item.type === "term") {
        return `${item.field}:taxonomy_term--${item.vocabulary}`;
      } else if (item.type === "media") {
        return `${item.field}:media--image`;
      } else if (item.type === "node-reference") {
        return `${item.field}:node--${item.bundle}`;
      } else if (item.type === "workout-blocks") {
        return `${item.field}:paragraph--workout_block`;
      }
      return item.field;
    })
    .join(",");
}

export function generateAttributeString(fields) {
  return fields.map((i) => i.field).join(",");
}

export function generateIncludeString(fields) {
  return fields
    .filter((item) => relationShipTypes.includes(item.type))
    .map((item) => {
      if (item.type === "media") {
        return `${item.field}.thumbnail`;
      }
      if (item.type === "workout-blocks") {
        return `${item.field}.field_workout_block_exercises`;
      }
      return item.field;
    })
    .join(",");
}

import dayjs from "dayjs";
import { createContext, useContext, useCallback, useState, useEffect, useMemo } from "react";

import { useAdminFeedProvider } from "./providers/admin-feed-provider.js";
import { useClientFeedProvider } from "./providers/client-feed-provider.js";
import { useCoachFeedProvider } from "./providers/coach-feed-provider.js";
import { useClientGalleryProvider } from "./providers/client-gallery-provider.js";
import { useClientCoachesProvider } from "./providers/client-coaches-provider.js";
import { useAllMessages, useMessageThreads } from "./api/messages.js";

const AppContext = createContext();

export function AppStateProvider({ children, tabs, mode, groupId, profile, isAdmin }) {
  const { data: threads } = useMessageThreads(mode, mode === "coach" ? profile.drupal_internal__id : groupId);
  const allMessages = useAllMessages(mode, threads);
  const unreadMessages = allMessages.map((queryResult, i) => {
    return {
      threadId: threads[i].id,
      unread: queryResult.data?.reduce((acc, i) => (!i.is_author && !i.read_at ? acc + 1 : acc), 0) ?? 0,
    };
  });

  const [ts, setTs] = useState(0);
  const [activeGallery, setActiveGallery] = useState([]);

  const changeTab = useCallback(
    (name) => {
      tabs.current.ionTabContextState.selectTab(name);
    },
    [tabs]
  );

  const { Provider: CoachesProvider, coaches } = useClientCoachesProvider(mode === "client" ? groupId : null);

  const { GalleryProvider, galleryItems, hasMoreGalleryItems, loadMoreGalleryItems, galleryIsLoading } =
    useClientGalleryProvider(mode === "client" ? groupId : null);

  const { Provider: ClientFeedProvider, entities: clientFeed } = useClientFeedProvider(
    mode === "client" && groupId,
    ts
  );

  const { Provider: CoachFeedProvider, entities: coachFeed } = useCoachFeedProvider(mode === "coach" && groupId, ts);

  const { Provider: AdminFeedProvider, entities: adminFeed } = useAdminFeedProvider(isAdmin, ts);

  const profileHasSubscription = useMemo(() => {
    if (!profile) {
      return false;
    }

    if (!("field_subscriptions" in profile)) {
      return false;
    }

    return profile.field_subscriptions.filter((sub) => sub && sub.field_subscription_active).length > 0;
  }, [profile]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTs(dayjs().unix());
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = {
    changeTab,
    groupId,
    isAdmin,
    mode,
    clientFeed,
    coachFeed,
    adminFeed,
    profile,
    coaches,
    profileHasSubscription,

    activeGallery,
    setActiveGallery,

    galleryItems,
    hasMoreGalleryItems,
    loadMoreGalleryItems,
    galleryIsLoading,

    threads,
    allMessages,
    unreadMessages,
  };
  return (
    <AppContext.Provider value={value}>
      {children}
      <ClientFeedProvider />
      <AdminFeedProvider />
      <CoachFeedProvider />
      <GalleryProvider />
      <CoachesProvider />
    </AppContext.Provider>
  );
}

/**
 * @typedef {object} AppState
 * @property {import("./api/messages.js").MessageThread[]} threads
 * @property {Array} threads
 * @property {Array.<{threadId: string, unread: number}>} unreadMessages
 * @property {string} groupId
 */

/**
 * @returns {AppState}
 */
export function useAppState() {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppState must be used within a AppStateProvider");
  }
  return context;
}

import { processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-hero/pepalexa-hero.scss";

const AppHero = ({
  children,
  background,
  contentPosition,
  modifiers,
  ...props
}) => {
  return (
    <div
      className={processModifiers(
        "pepalexa-hero",
        modifiers,
        `pepalexa-hero--contents-${contentPosition ?? "top-left"}`
      )}
      {...props}
    >
      <div className="pepalexa-hero__contents">{children}</div>
      <div className="pepalexa-hero__background pepalexa-hero__background--has-overlay">
        <img src={background} alt="" />
      </div>
    </div>
  );
};

export default AppHero;

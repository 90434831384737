import { useEffect, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonLoading,
  IonContent,
} from "@ionic/react";
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";
import { useZeusDataStatusSubscriber } from "@understandit/dynamic-data-react";

import { useContentProvider } from "../providers/content-provider";
import AppContent from "../components/AppContent";
import AppRangeWithLabel from "../compositions/AppRangeWithLabel";
import AppTextareaWithTitle from "../compositions/AppTextareaWithTitle";
import AppLabel from "../components/AppLabel";
import AppInput from "../components/AppInput";

const WorkoutLogEdit = ({ workoutLogId, onClose }) => {
  const { Provider, setData, entity, providerId, isLoading } =
    useContentProvider(workoutLogId, "workout_log", {
      attributes:
        "title,field_workout_log_summary,field_workout_log_enjoyment,field_workout_log_effort,field_workout_log_challenge",
      include: "field_workout_log_workout",
    });

  const showTitleField = entity && !entity.field_workout_log_workout;

  useZeusDataStatusSubscriber(providerId, (status) => {
    if (status === "updated") {
      onClose();
    }
  });

  const [formState, setFormState] = useState({
    title: "",
    field_workout_log_challenge: 0,
    field_workout_log_effort: 0,
    field_workout_log_enjoyment: 0,
    field_workout_log_summary: "",
  });

  useEffect(() => {
    if (entity) {
      setFormState({
        title: entity.title,
        field_workout_log_challenge: entity.field_workout_log_challenge,
        field_workout_log_effort: entity.field_workout_log_effort,
        field_workout_log_enjoyment: entity.field_workout_log_enjoyment,
        field_workout_log_summary: entity.field_workout_log_summary,
      });
    }
  }, [entity]);

  const doUpdateFormState = (field, value) => {
    setFormState((state) => ({
      ...state,
      [field]: value,
    }));
  };

  const doSave = () => {
    setData({ id: workoutLogId, ...formState });
  };

  return (
    <>
      <IonLoading isOpen={isLoading} />
      <Provider />
      <IonHeader className="app-header">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onClose}>
              <IonIcon slot="icon-only" icon={closeCircleOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Redigera logg</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={doSave}>
              <IonIcon slot="icon-only" icon={checkmarkCircleOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {showTitleField && (
          <AppContent modifiers="padding">
            <div className="textarea-with-title">
              <AppLabel modifiers="padding">Titel</AppLabel>
              <AppInput
                onChange={(e) => doUpdateFormState("title", e.target.value)}
                value={formState.title}
              />
            </div>
          </AppContent>
        )}

        <AppRangeWithLabel
          label="Hur ansträngande kändes passet?"
          from_text="0"
          to_text="100%"
          onChange={(e) =>
            doUpdateFormState("field_workout_log_effort", e.detail.value)
          }
          value={formState.field_workout_log_effort}
        />
        <AppRangeWithLabel
          label="Hur utmanande kändes passet?"
          from_text="0"
          to_text="100%"
          onChange={(e) =>
            doUpdateFormState("field_workout_log_challenge", e.detail.value)
          }
          value={formState.field_workout_log_challenge}
        />
        <AppRangeWithLabel
          label="Hur roligt kändes passet?"
          from_text="0"
          to_text="100%"
          onChange={(e) =>
            doUpdateFormState("field_workout_log_enjoyment", e.detail.value)
          }
          value={formState.field_workout_log_enjoyment}
        />
        <AppTextareaWithTitle
          title="Anteckningar"
          placeholder="Skriv en anteckning"
          onChange={(e) =>
            doUpdateFormState("field_workout_log_summary", e.detail.value)
          }
          value={formState.field_workout_log_summary}
        />
      </IonContent>
    </>
  );
};

export default WorkoutLogEdit;

import { useParams } from "react-router-dom";
import { trashOutline, copyOutline, createOutline } from "ionicons/icons";
import {
  IonButton,
  IonIcon,
  IonList,
  IonLoading,
  useIonRouter,
} from "@ionic/react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { useEntityAction } from "../../hooks";
import { useDialogs, useFormState } from "../../form/hooks";
import { getSetting, pageConfig } from "../../settings";
import { generateIncludeString } from "../../form/helpers";
import { FormValue } from "../../form/FormItem";
import {
  AppPageWithSheet,
  AppSheet,
  AppTopOfSheet,
} from "../../compositions/AppPageWithSheet";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import BottomSpacer from "../../components/BottomSpacer";
import AppContent from "../../components/AppContent";
import AppHeading from "../../components/AppHeading";
import AppSmallTitle from "../../components/AppSmallTitle";

const EntityView = ({ type, editable = false, context, isAdmin }) => {
  const showEditButton = editable || isAdmin;
  const { client_id, id } = useParams();
  const baseHref =
    context === "client"
      ? `/c/menu/manage/client/${client_id}/${type}`
      : `/c/menu/manage/${type}`;
  const entityConfig = pageConfig[type];
  const providerId = `coach-entity-view-${type}-${id}`;
  const providerTag = `coach-entity-${type}-${id}`;
  const { data: entity, isLoading } = useZeusDataProvider(providerId);
  const [formState] = useFormState(entityConfig.fields, entity, true);
  const { confirmDangerousAction } = useDialogs();
  const router = useIonRouter();
  const doEntityAction = useEntityAction(() => {
    router.push(baseHref, "back");
  });

  const normalFields = pageConfig[type].fields.filter(
    (i) => i.type !== "workout-blocks"
  );
  const connectorFields = pageConfig[type].fields.filter(
    (i) => i.type === "workout-blocks"
  );

  const doDelete = () => {
    confirmDangerousAction({
      button: "Ta bort",
      message: "Är du säker på att du vill ta bort innehållet?",
      handler: () => doEntityAction("delete", { type, id }),
    });
  };

  return (
    <AppPageWithHideableHeader
      title={entity?.title ?? "Laddar..."}
      defaultHref={baseHref}
      backButton
      toolbarButtons={
        <>
          {showEditButton && (
            <IonButton onClick={doDelete}>
              <IonIcon slot="icon-only" icon={trashOutline} />
            </IonButton>
          )}

          <IonButton routerLink={`${baseHref}/${id}/copy`}>
            <IonIcon slot="icon-only" icon={copyOutline} />
          </IonButton>
          {showEditButton && (
            <IonButton routerLink={`${baseHref}/${id}/edit`}>
              <IonIcon slot="icon-only" icon={createOutline} />
            </IonButton>
          )}
        </>
      }
    >
      <IonLoading isOpen={isLoading} />
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppContent modifiers="pepalexa-content--padding pepalexa-content--margin-bottom">
            <AppHeading modifiers="center">
              {entity?.title ?? "Laddar..."}
            </AppHeading>
          </AppContent>
        </AppTopOfSheet>
        <AppSheet>
          <IonList class="form">
            {normalFields.map((field, i, fields) => (
              <FormValue
                key={field.field}
                label={field.label}
                type={field.type}
                config={field}
                value={formState[field.field]}
                last={i === fields.length - 1}
              />
            ))}
          </IonList>

          {connectorFields?.length > 0 && (
            <>
              <div className="ion-margin-vertical">
                <AppSmallTitle>Övningar</AppSmallTitle>
              </div>
              {connectorFields.map((field) => (
                <FormValue
                  key={field.field}
                  type={field.type}
                  value={formState[field.field]}
                />
              ))}
            </>
          )}

          <BottomSpacer />
        </AppSheet>
      </AppPageWithSheet>
      <zeus-data-provider id={providerId} listen-tags={providerTag}>
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/api/v3/node/${type}/${id}?include=${generateIncludeString(
            pageConfig[type].fields
          )}`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </AppPageWithHideableHeader>
  );
};

export default EntityView;

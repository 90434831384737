import { IonCol, IonIcon } from "@ionic/react";

import MediaWithOverlap from "../components/AppMediaWithOverlap";

const AppIconWithOverlap = (props) => {
  const modifiers = ["small"];
  if (props.selected) {
    modifiers.push("selected");
  }

  if (props.pointer) {
    modifiers.push("pointer");
  }

  return (
    <IonCol onClick={props.onClick}>
      <MediaWithOverlap
        modifiers={modifiers}
        text={props.text}
        media={
          <div className="pepalexa-media-with-overlap__icon-container">
            <IonIcon
              className="pepalexa-media-with-overlap__icon"
              src={props.icon}
            />
          </div>
        }
        mediaOverlap={props.number && <span>{props.number}</span>}
      />
    </IonCol>
  );
};

export default AppIconWithOverlap;

import { useMemo } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

export function useCreateProfileProvider(type) {
  const providerId = `create-profile-provider`;
  const url = `${getSetting("BACKEND")}/api/create-profile`;
  const { isLoading, setData, error } = useZeusDataProvider(providerId, []);

  const Provider = useMemo(() => {
    const Component = () => (
      <zeus-data-provider id={providerId} write-only send-tags={type}>
        <zeus-data-source-json url={url}></zeus-data-source-json>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    );
    return Component;
  }, [providerId, url, type]);

  return {
    Provider,
    isLoading,
    setData,
    error,
    providerId,
  };
}

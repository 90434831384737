import { useEffect } from "react";
import { IonLoading } from "@ionic/react";
import { useDynamicDataProvider, useZeusDataStatusSubscriber } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

const EntityStatusUpdateAction = ({ type, bundle, id, status, onDone = () => {} }) => {
  const [actionResponse] = useDynamicDataProvider("action-provider");
  const data = { id, status };
  useEffect(() => {
    if (actionResponse?.status === status) {
      onDone();
    }
  }, [actionResponse, onDone, status]);
  return (
    <>
      <IonLoading isOpen={true} showBackdrop={false} message="Uppdaterar status" />
      <zeus-data-provider id="action-provider" set-data={JSON.stringify(data)} write-only={true} send-tags={bundle}>
        <zeus-data-source-jsonapi
          url={`${getSetting("BACKEND")}/jsonapi/${type}/${bundle}/${id}`}
          attributes="status"
          type={`${type}--${bundle}`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

const WorkoutLogUpdateAction = ({ id, fields, onDone = () => {} }) => {
  const data = { id, ...fields };
  useZeusDataStatusSubscriber("action-provider", (status) => {
    if (status === "updated") {
      onDone();
    }
  });
  return (
    <>
      <IonLoading isOpen={true} showBackdrop={false} message="Uppdaterar loggen" />
      <zeus-data-provider
        id="action-provider"
        set-data={JSON.stringify(data)}
        write-only={true}
        send-tags="workout_log"
      >
        <zeus-data-source-jsonapi
          url={`${getSetting("BACKEND")}/api/v3/node/workout_log/${id}`}
          attributes="field_workout_log_summary,field_workout_log_enjoyment,field_workout_log_challenge,field_workout_log_effort"
          type="node--workout_log"
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

const EntityDeleteAction = ({ type, bundle, id, onDone = () => {} }) => {
  const data = { id };
  useZeusDataStatusSubscriber("action-provider", (status) => {
    if (status === "deleted") {
      onDone();
    }
  });
  return (
    <>
      <IonLoading isOpen={true} showBackdrop={false} message="Raderar" />
      <zeus-data-provider id="action-provider" set-data={JSON.stringify(data)} write-only={true} send-tags={bundle}>
        <zeus-data-source-jsonapi
          url={`${getSetting("BACKEND")}/api/v3/${type}/${bundle}/${id}`}
          attributes="status"
          type={`${type}--${bundle}`}
          data-action="delete"
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

const EntityAssignAction = ({ target, bundle, id, status, onDone = () => {} }) => {
  const data = { group_id: target, type: bundle, id, status };
  useZeusDataStatusSubscriber("action-provider", (status) => {
    if (status === "updated") {
      onDone();
    }
  });
  return (
    <>
      <IonLoading isOpen={true} showBackdrop={false} message="Tilldelar" />
      <zeus-data-provider id="action-provider" set-data={JSON.stringify(data)} write-only={true} send-tags={bundle}>
        <zeus-data-source-json url={`${getSetting("BACKEND")}/api/assign`} data-action="create"></zeus-data-source-json>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

const EntityMultiAssignAction = ({ targets, bundle, id, status, onDone = () => {} }) => {
  const data = { group_ids: targets, type: bundle, id, status };
  useZeusDataStatusSubscriber("action-provider", (status) => {
    if (status === "updated") {
      onDone();
    }
  });
  return (
    <>
      <IonLoading isOpen={true} showBackdrop={false} message="Tilldelar" />
      <zeus-data-provider id="action-provider" set-data={JSON.stringify(data)} write-only={true} send-tags={bundle}>
        <zeus-data-source-json
          url={`${getSetting("BACKEND")}/api/multi-assign`}
          data-action="create"
        ></zeus-data-source-json>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

const EntityStatusAction = ({ bundle, id, status, onDone = () => {} }) => {
  const entityType = `group_content--client-group_node-${bundle}`;
  const data = {
    id,
    type: entityType,
    field_status: status,
  };
  const url = `${getSetting("BACKEND")}/api/v3/group_content/client-group_node-${bundle}/${id}`;

  useZeusDataStatusSubscriber("action-provider", (status) => {
    if (status === "updated") {
      onDone();
    }
  });

  return (
    <>
      <IonLoading isOpen={true} showBackdrop={false} message="Uppdaterar" />
      <zeus-data-provider id="action-provider" set-data={JSON.stringify(data)} write-only={true} send-tags={bundle}>
        <zeus-data-source-jsonapi
          url={url}
          attributes="field_status"
          data-action="update"
          type={entityType}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

const UnassignCoachAction = ({ id, onDone = () => {} }) => {
  const data = { group_content_id: id };
  useZeusDataStatusSubscriber("action-provider", (status) => {
    if (status === "updated") {
      onDone();
    }
  });
  return (
    <>
      <IonLoading isOpen={true} showBackdrop={false} message="Uppdaterar" />
      <zeus-data-provider
        id="action-provider"
        set-data={JSON.stringify(data)}
        write-only={true}
        send-tags="client-coaches"
      >
        <zeus-data-source-json
          url={`${getSetting("BACKEND")}/api/unassign-coach`}
          data-action="create"
        ></zeus-data-source-json>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

const AssignCoachAction = ({ coach, client, onDone = () => {} }) => {
  const data = { coach, client };
  useZeusDataStatusSubscriber("action-provider", (status) => {
    if (status === "updated") {
      onDone();
    }
  });
  return (
    <>
      <IonLoading isOpen={true} showBackdrop={false} message="Uppdaterar" />
      <zeus-data-provider
        id="action-provider"
        set-data={JSON.stringify(data)}
        write-only={true}
        send-tags="client-coaches"
      >
        <zeus-data-source-json
          url={`${getSetting("BACKEND")}/api/assign-coach`}
          data-action="create"
        ></zeus-data-source-json>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

const SortGroupContentAction = ({ bundle, entities, onDone = () => {} }) => {
  const data = { bundle, entities };
  useZeusDataStatusSubscriber("action-provider", (status) => {
    if (status === "updated") {
      onDone();
    }
  });
  return (
    <>
      <IonLoading isOpen={true} showBackdrop={false} message="Uppdaterar" />
      <zeus-data-provider id="action-provider" set-data={JSON.stringify(data)} write-only={true} send-tags={bundle}>
        <zeus-data-source-json
          url={`${getSetting("BACKEND")}/api/sort-group-content`}
          data-action="create"
        ></zeus-data-source-json>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

const ActionModal = ({ action, args, onDone }) => {
  const entityType = "node";
  if (action === "publish") {
    return (
      <EntityStatusUpdateAction type={entityType} bundle={args?.type} id={args?.id} status={true} onDone={onDone} />
    );
  } else if (action === "unpublish") {
    return (
      <EntityStatusUpdateAction type={entityType} bundle={args?.type} id={args?.id} status={false} onDone={onDone} />
    );
  } else if (action === "delete") {
    return <EntityDeleteAction type={entityType} bundle={args?.type} id={args?.id} onDone={onDone} />;
  } else if (action === "assign") {
    return (
      <EntityAssignAction
        type={entityType}
        bundle={args?.type}
        id={args?.id}
        target={args?.target}
        status={args?.status}
        onDone={onDone}
      />
    );
  } else if (action === "multi-assign") {
    return (
      <EntityMultiAssignAction
        type={entityType}
        bundle={args?.type}
        id={args?.id}
        targets={args?.targets}
        status={args?.status}
        onDone={onDone}
      />
    );
  } else if (action === "entity_status") {
    return <EntityStatusAction id={args?.id} status={args?.status} onDone={onDone} bundle={args?.type} />;
  } else if (action === "unassign_coach") {
    return <UnassignCoachAction id={args?.id} onDone={onDone} />;
  } else if (action === "assign_coach") {
    return <AssignCoachAction coach={args?.coach} client={args?.client} onDone={onDone} />;
  } else if (action === "update_log") {
    return <WorkoutLogUpdateAction id={args?.id} fields={args?.fields} onDone={onDone} />;
  } else if (action === "sort_group_content") {
    return <SortGroupContentAction bundle={args?.type} entities={args?.entities} onDone={onDone} />;
  }

  return null;
};

export default ActionModal;

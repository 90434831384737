import { useState } from "react";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";

const BackButton = ({ defaultHref }) => {
  if (isPlatform("ios")) {
    return (
      <IonBackButton
        defaultHref={defaultHref}
        text="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
      />
    );
  }
  return <IonBackButton defaultHref={defaultHref} text="" />;
};

const AppPageWithHideableHeader = ({
  threshold = 40,
  children,
  title,
  defaultHref,
  fullscreen = false,
  toolbarButtons,
  backButton,
}) => {
  const [headerVisible, setHeaderVisible] = useState(false);
  const onScroll = (e) => setHeaderVisible(e.detail.scrollTop > threshold);
  return (
    <IonPage>
      <IonHeader
        translucent={true}
        className={`app-header app-hiding-header ${
          headerVisible ? "app-header--visible" : "app-header--invisible"
        }`}
      >
        <IonToolbar>
          {backButton && (
            <IonButtons slot="start">
              <BackButton defaultHref={defaultHref} />
            </IonButtons>
          )}
          <IonTitle>{title}</IonTitle>
          {toolbarButtons && (
            <IonButtons slot="end">{toolbarButtons}</IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent
        scrollEvents={true}
        onIonScroll={onScroll}
        fullscreen={isPlatform("ios")}
      >
        {!fullscreen && <div className="app-hiding-header-spacer" />}
        {children}
      </IonContent>
    </IonPage>
  );
};

export default AppPageWithHideableHeader;

import { processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-text/pepalexa-text.scss";

const AppText = ({ modifiers, children, ...props }) => (
  <p {...props} className={processModifiers("pepalexa-text", modifiers)}>
    {children}
  </p>
);

export default AppText;

import { useParams } from "react-router";
import { IonItem, IonList, IonLabel } from "@ionic/react";
import dayjs from "dayjs";

import { useContentProvider } from "../../providers/content-provider";
import { formatMediumDate } from "../../util";
import {
  AppPageWithSheet,
  AppSheet,
  AppTopOfSheet,
} from "../../compositions/AppPageWithSheet";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import AppContent from "../../components/AppContent";
import AppHeading from "../../components/AppHeading";
import BottomSpacer from "../../components/BottomSpacer";

const ClientProgressDetail = () => {
  const { client_id, id } = useParams();
  const { Provider, entity } = useContentProvider(id, "progress", {
    include: "field_progress_values",
  });

  const values =
    entity?.field_progress_values?.sort(
      (a, b) =>
        dayjs(b.field_progress_timestamp).unix() -
        dayjs(a.field_progress_timestamp).unix()
    ) ?? [];

  return (
    <AppPageWithHideableHeader
      title={entity?.title}
      defaultHref={`/c/menu/manage/client/${client_id}/progress`}
      backButton
    >
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppContent modifiers="pepalexa-content--padding pepalexa-content--margin-bottom">
            <AppHeading modifiers="center">{entity?.title}</AppHeading>
          </AppContent>
        </AppTopOfSheet>
        <AppSheet>
          <IonList className="light">
            {values.map((item) => (
              <IonItem key={item.id}>
                <IonLabel>
                  <h2>
                    {item.field_progress_value_text}{" "}
                    {entity?.field_progress_unit}
                  </h2>
                  <p>{formatMediumDate(item.field_progress_timestamp)}</p>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
          <BottomSpacer />
        </AppSheet>
      </AppPageWithSheet>
      <Provider />
    </AppPageWithHideableHeader>
  );
};

export default ClientProgressDetail;

import AppContainer from "../components/AppContainer";

export const AppPageWithSheet = ({ children }) => (
  <div className="wrapper">{children}</div>
);

export const AppTopOfSheet = ({ children }) => (
  <div className="wrapper__column">{children}</div>
);

export const AppSheet = ({ children }) => (
  <div className="wrapper__column wrapper__column--flex">
    <AppContainer modifiers="rounded-top fill padding-top">
      {children}
    </AppContainer>
  </div>
);

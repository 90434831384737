import { useParams } from "react-router-dom";
import {
  IonButton,
  IonList,
  IonLoading,
  IonIcon,
  useIonRouter,
  useIonViewWillLeave,
  IonButtons,
  IonMenuButton,
} from "@ionic/react";
import {
  useZeusDataProvider,
  useZeusDataStatusSubscriber,
} from "@understandit/dynamic-data-react";
import { checkmarkCircleOutline } from "ionicons/icons";

import { getSetting, pageConfig } from "../../settings";
import { useError } from "../../hooks";
import { useFormState } from "../../form/hooks";
import {
  generateRelationshipsString,
  generateAttributeString,
  generateIncludeString,
} from "../../form/helpers";
import {
  AppPageWithSheet,
  AppSheet,
  AppTopOfSheet,
} from "../../compositions/AppPageWithSheet";
import { FormItem } from "../../form/FormItem";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import BottomSpacer from "../../components/BottomSpacer";
import AppSmallTitle from "../../components/AppSmallTitle";
import AppContent from "../../components/AppContent";
import AppHeading from "../../components/AppHeading";

const EntityEdit = ({ type, context }) => {
  const { client_id, id } = useParams();
  const providerId = `coach-entity-edit-${type}-${id}`;
  const providerTags = `${type},coach-entity-${type}-${id}`;
  const router = useIonRouter();
  const { data: entity, isLoading, setData } = useZeusDataProvider(providerId);
  const [formState, updateFormState, validateFormState, onLeave] = useFormState(
    pageConfig[type].fields,
    entity
  );

  const normalFields = pageConfig[type].fields.filter(
    (i) => i.type !== "workout-blocks"
  );
  const connectorFields = pageConfig[type].fields.filter(
    (i) => i.type === "workout-blocks"
  );

  const baseHref =
    context === "client"
      ? `/c/menu/manage/client/${client_id}/${type}/${id}`
      : `/c/menu/manage/${type}/${id}`;

  const setValidationError = useError();

  useZeusDataStatusSubscriber(providerId, (status) => {
    if (status === "updated") {
      router.canGoBack() && router.goBack();
    }
  });

  const doSave = () => {
    const errors = validateFormState();
    if (errors.length) {
      setValidationError(errors[0]);
      return;
    }

    const _paragraphs = [];
    connectorFields.forEach(() =>
      _paragraphs.push({
        field: "field_workout_blocks",
        type: "paragraph--workout_block",
        attributes: [
          "field_title",
          "field_workout_block_exercises",
          "field_description",
        ],
        relationships: {
          field_workout_block_exercises: {
            type: "node--exercise",
            ref: "id",
          },
        },
        values: formState.field_workout_blocks.map((value) => ({
          ...value,
          action: value.id.startsWith("new__") ? "create" : "update",
        })),
      })
    );

    setData({ id, ...formState, _paragraphs });
  };

  useIonViewWillLeave(() => {
    onLeave();
  }, [onLeave]);

  return (
    <AppPageWithHideableHeader
      title={pageConfig[type].editTitle}
      defaultHref={baseHref}
      backButton
      toolbarButtons={
        <IonButtons>
          <IonButton onClick={doSave}>
            <IonIcon slot="icon-only" icon={checkmarkCircleOutline} />
          </IonButton>
          <IonMenuButton></IonMenuButton>
        </IonButtons>
      }
    >
      <IonLoading isOpen={isLoading} />
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppContent modifiers="pepalexa-content--padding pepalexa-content--margin-bottom">
            <AppHeading modifiers="center">
              {pageConfig[type].editTitle}
            </AppHeading>
          </AppContent>
        </AppTopOfSheet>
        <AppSheet>
          <IonList class="form">
            {normalFields.map((field, i, fields) => (
              <FormItem
                key={field.field}
                label={field.label}
                type={field.type}
                config={field}
                value={formState[field.field]}
                onChange={(e) => updateFormState(field, e.detail.value)}
                last={i === fields.length - 1}
              />
            ))}
          </IonList>

          {connectorFields?.length > 0 && (
            <>
              <div className="ion-margin-vertical">
                <AppSmallTitle>Övningar</AppSmallTitle>
              </div>
              {connectorFields.map((field) => (
                <FormItem
                  key={field.field}
                  label={field.label}
                  type={field.type}
                  config={field}
                  value={formState[field.field]}
                  onChange={(e) => updateFormState(field, e.detail.value)}
                />
              ))}
            </>
          )}
          <BottomSpacer />
        </AppSheet>
      </AppPageWithSheet>
      <zeus-data-provider id={providerId} send-tags={providerTags}>
        <zeus-data-source-jsonapi
          type={`node--${type}`}
          attributes={generateAttributeString(pageConfig[type].fields)}
          relationships={generateRelationshipsString(pageConfig[type].fields)}
          api-root={`${getSetting("BACKEND")}/api/v3`}
          url={`${getSetting(
            "BACKEND"
          )}/api/v3/node/${type}/${id}?include=${generateIncludeString(
            pageConfig[type].fields
          )}`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </AppPageWithHideableHeader>
  );
};

export default EntityEdit;

import { processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-flag/pepalexa-flag.scss";

const AppFlag = ({ modifiers, children, ...props }) => {
  return (
    <span className={processModifiers("pepalexa-flag", modifiers)} {...props}>
      {children}
    </span>
  );
};

export default AppFlag;

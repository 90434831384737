import { useState, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import { IonButton, IonIcon, IonGrid, IonRow, IonCol, IonLoading, useIonRouter, IonModal } from "@ionic/react";
import { trashOutline, createOutline, arrowBack, arrowForward } from "ionicons/icons";

import { getSetting } from "../../settings";
import { formatMediumDateTime } from "../../util";
import { useClientProvider } from "../../providers/client-provider";
import { useContentProvider } from "../../providers/content-provider";
import { useAppState } from "../../app-context";
import { useDialogs } from "../../form/hooks";
import { useEntityAction, useRichMessageCoach } from "../../hooks";
import AppContent from "../../components/AppContent";
import AppMedia from "../../components/AppMedia";
import AppText from "../../components/AppText";
import AppInformativeListItem from "../../compositions/AppInformativeListItem";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import PrivateImage from "../../components/PrivateImage";
import PrivateVideo from "../../components/PrivateVideo";
import BottomSpacer from "../../components/BottomSpacer";
import GalleryEdit from "../../modals/GalleryEdit";

const ClientGalleryDetail = ({ defaultHref, preview = false, groupId }) => {
  const { client_id, id: initialId } = useParams();
  const [id, setId] = useState(initialId);
  const { Provider: ClientProvider, client } = useClientProvider(client_id);
  const router = useIonRouter();
  const {
    setData,
    Provider,
    isLoading,
    entity: loadedItem,
  } = useContentProvider(id, "gallery", {
    include: "field_gallery_thumbnail,field_gallery_tags,field_gallery_file",
    relationships: "field_gallery_tags:taxonomy_term--gallery_tags",
    attributes: "field_gallery_tags,field_gallery_text",
  });
  const { activeGallery, threads } = useAppState();
  const galleryIndex = activeGallery.findIndex((i) => i.id === id);
  const galleryItem = loadedItem ?? activeGallery[galleryIndex];
  const prevId = activeGallery[galleryIndex - 1]?.id;
  const nextId = activeGallery[galleryIndex + 1]?.id;
  const doEntityAction = useEntityAction(() => {
    router.push(`/c/menu/manage/client/${client_id}/gallery`, "back");
  });
  const { confirmDangerousAction } = useDialogs();
  const modal = useRef(null);

  const sendRichMessage = useRichMessageCoach(groupId);

  const messageThread = useMemo(() => {
    return threads.find((t) => t.clientId === client_id)?.id;
  }, [threads, client_id]);

  const doGoNext = () => {
    setId(nextId);
  };

  const doGoPrev = () => {
    setId(prevId);
  };

  const doDelete = () => {
    confirmDangerousAction({
      message: "Är du säker på att du vill ta bort innehållet?",
      button: "Ta bort",
      handler: () => {
        doEntityAction("delete", { type: "gallery", id });
      },
    });
  };

  return (
    <AppPageWithHideableHeader
      defaultHref={defaultHref ?? `/c/menu/manage/client/${client_id}/gallery`}
      backButton
      toolbarButtons={
        !preview && (
          <>
            <IonButton onClick={doDelete}>
              <IonIcon slot="icon-only" icon={trashOutline} />
            </IonButton>
            <IonButton id="open-edit">
              <IonIcon slot="icon-only" icon={createOutline} />
            </IonButton>
          </>
        )
      }
    >
      <IonModal trigger="open-edit" ref={modal}>
        <GalleryEdit galleryItem={galleryItem} setData={setData} onDismiss={() => modal?.current.dismiss()} />
      </IonModal>
      <IonLoading isOpen={isLoading} />
      <AppMedia modifiers="sharp">
        {galleryItem?.field_gallery_file?.filemime.startsWith("image") && (
          <PrivateImage auth="auth" src={galleryItem?.field_gallery_file.image_style_uri.large} />
        )}
        {galleryItem?.field_gallery_file?.filemime.startsWith("video") && (
          <PrivateVideo
            auth="auth"
            src={"https://delicate-butterfly-1457.a.udev.se" + galleryItem?.field_gallery_file.uri.url}
          />
        )}
      </AppMedia>
      {!preview && (
        <AppContent modifiers="padding small-margin-top small-margin-bottom">
          <IonGrid>
            <IonRow>
              <IonCol className="ion-padding-end">
                <IonButton
                  onClick={doGoPrev}
                  disabled={!prevId}
                  color="secondary"
                  fill="outline"
                  size="small"
                  expand="block"
                >
                  <IonIcon icon={arrowBack} slot="start" />
                  Föregående
                </IonButton>
              </IonCol>
              <IonCol className="ion-text-right">
                <IonButton
                  color="secondary"
                  fill="outline"
                  size="small"
                  onClick={doGoNext}
                  disabled={!nextId}
                  expand="block"
                >
                  Nästa
                  <IonIcon icon={arrowForward} slot="end" />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </AppContent>
      )}
      {!preview && (
        <AppContent modifiers="padding small-margin-top small-margin-bottom">
          <AppInformativeListItem
            name={`${client?.field_first_name} ${client?.field_last_name}`}
            date={formatMediumDateTime(galleryItem?.created)}
            {...(messageThread
              ? {
                  icon: "assets/icons/message.svg",
                  iconText: "Meddela klient",
                  onIconClick: () => sendRichMessage(messageThread, "gallery", id, galleryItem),
                }
              : {})}
          ></AppInformativeListItem>
        </AppContent>
      )}
      <AppContent modifiers={`padding margin-bottom ${preview && "small-margin-top"}`}>
        <AppText modifiers="wrapping">{galleryItem?.field_gallery_text}</AppText>
      </AppContent>
      <BottomSpacer />
      <zeus-data-provider id={`gallery-${id}`}>
        <zeus-data-source-jsonapi
          url={`${getSetting("BACKEND")}/api/v3/node/gallery/${id}?include=field_gallery_file`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
      <ClientProvider />
      <Provider />
    </AppPageWithHideableHeader>
  );
};

export default ClientGalleryDetail;

import { useMemo } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";
import dayjs from "dayjs";

import { getSetting } from "../settings";

function addType(inputArray, type) {
  return inputArray.map((i) => ({ ...i, type }));
}

export function useCoachFeedProvider(coachId, ts = 0) {
  const { data: data1, isLoading1 } = useZeusDataProvider(
    `coach-feed-${coachId}-workout_log_created-${ts}`,
    []
  );
  const { data: data2, isLoading2 } = useZeusDataProvider(
    `coach-feed-${coachId}-gallery_post_created-${ts}`,
    []
  );
  const { data: data3, isLoading3 } = useZeusDataProvider(
    `coach-feed-${coachId}-message_received-${ts}`,
    []
  );

  const isLoading = isLoading1 || isLoading2 || isLoading3;
  const entities = useMemo(() => {
    return [
      ...addType(data1, "workout_log_created"),
      ...addType(data2, "gallery_post_created"),
      ...addType(data3, "message"),
    ].sort((a, b) => dayjs(b.created).unix() - dayjs(a.created).unix());
  }, [data1, data2, data3]);

  const Provider = useMemo(() => {
    const Component = () => {
      if (!coachId) {
        return null;
      }
      return (
        <>
          {[
            "workout_log_created",
            "gallery_post_created",
            "message_received",
          ].map((type) => {
            const providerId = `coach-feed-${coachId}-${type}-${ts}`;
            const url = `${getSetting(
              "BACKEND"
            )}/api/v3/message/${type}?filter[field_group.id]=${coachId}&sort=-created&page[limit]=10&include=field_group_content.entity_id,field_group_content.gid${
              type === "message_received"
                ? `,field_group_content.entity_id.field_messsage_thread.field_message_thread_client,field_group_content.entity_id.field_message_reference.node_type`
                : ``
            }`;
            return (
              <zeus-data-provider
                key={type}
                id={`${providerId}`}
                listen-tags="feed"
              >
                <zeus-data-source-jsonapi url={url}></zeus-data-source-jsonapi>
                <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
              </zeus-data-provider>
            );
          })}
        </>
      );
    };
    return Component;
  }, [coachId, ts]);

  return {
    Provider,
    entities,
    isLoading,
  };
}

import AppLabel from "../components/AppLabel";
import AppTextarea from "../components/AppTextarea";

import "@understandit/lofsangruppen-storybook-22173948/compositions/textarea-with-title/textarea-with-title.scss";

const AppTextareaWithTitle = ({ value, onChange, ...props }) => {
  return (
    <div className="textarea-with-title">
      <AppLabel modifiers="padding">{props.title}</AppLabel>
      <AppTextarea
        placeholder={props.placeholder}
        modifiers="padding"
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default AppTextareaWithTitle;

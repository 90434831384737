import { IonList, IonItem, IonLabel } from "@ionic/react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";
import { useParams } from "react-router";

import { getSetting } from "../../settings";
import { formatMediumDate } from "../../util";
import AppContent from "../../components/AppContent";
import AppMediaWithOverlap from "../../components/AppMediaWithOverlap";
import AppHeading from "../../components/AppHeading";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import {
  AppPageWithSheet,
  AppSheet,
  AppTopOfSheet,
} from "../../compositions/AppPageWithSheet";
import PrivateImage from "../../components/PrivateImage";
import AppText from "../../components/AppText";
import AppSubscriptionItem from "../../compositions/AppSubscriptionItem";

const ClientView = () => {
  const { id } = useParams();
  const providerId = `client-${id}`;
  const coachesProviderId = `client-coaches-${id}`;
  const { data: client } = useZeusDataProvider(providerId);
  const { data: coaches } = useZeusDataProvider(coachesProviderId, []);

  const subscriptions =
    client?.field_subscriptions?.map((sub) => {
      const label = sub.field_subscription_label ?? "Manuell";
      let subtext = "";
      if (sub.field_subscription_from && sub.field_subscription_to) {
        subtext = `${formatMediumDate(
          sub.field_subscription_from
        )} - ${formatMediumDate(sub.field_subscription_to)}`;
      } else if (sub.field_subscription_from) {
        subtext = `${formatMediumDate(sub.field_subscription_from)}`;
      } else if (sub.field_subscription_active !== null) {
        subtext = `Aktivt: ${sub.field_subscription_active ? "Ja" : "Nej"}`;
      }

      return {
        id: sub.id,
        label,
        subtext,
      };
    }) ?? [];

  return (
    <AppPageWithHideableHeader
      title={`${client?.field_first_name} ${client?.field_last_name}`}
      backButton={true}
      defaultHref="/c/menu/manage/client"
    >
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppMediaWithOverlap
            media={
              <PrivateImage
                auth="auth"
                src={client?.field_avatar?.image_style_uri?.medium}
                defaultImage="assets/images/default-avatar.png"
              />
            }
            mediaOverlap={
              coaches.length > 0
                ? coaches.map((coach) => (
                    <PrivateImage
                      key={coach.id}
                      auth="auth"
                      src={coach?.field_avatar?.image_style_uri?.medium}
                      defaultImage="assets/images/default-avatar.png"
                    />
                  ))
                : null
            }
          />
          <AppContent modifiers="text-center">
            <AppHeading modifiers="highlighted">
              {client?.field_first_name} {client?.field_last_name}
            </AppHeading>
            <p>
              Coach{" "}
              {coaches.map((coach) => (
                <span key={coach.id} className="color-secondary">
                  {coach.field_first_name} {coach.field_last_name}
                </span>
              ))}
            </p>
          </AppContent>

          <AppText modifiers="white-small-center">
            {subscriptions?.length > 0 ? "A" : "Inga a"}
            ktiva abonnemang
          </AppText>
          {subscriptions?.map((sub) => (
            <AppSubscriptionItem
              key={sub.id}
              type_title={sub.label}
              period_title="Period"
              period_text={sub.subtext}
            />
          ))}
        </AppTopOfSheet>
        <AppSheet>
          <IonList class="light">
            <IonItem routerLink={`/c/menu/manage/client/${id}/workout`}>
              <IonLabel>Träningsprogram</IonLabel>
            </IonItem>
            <IonItem routerLink={`/c/menu/manage/client/${id}/workout-log`}>
              <IonLabel>Träningslogg</IonLabel>
            </IonItem>
            <IonItem routerLink={`/c/menu/manage/client/${id}/diet`}>
              <IonLabel>Kostråd</IonLabel>
            </IonItem>
            <IonItem routerLink={`/c/menu/manage/client/${id}/progress`}>
              <IonLabel>Framgång</IonLabel>
            </IonItem>
            <IonItem routerLink={`/c/menu/manage/client/${id}/gallery`}>
              <IonLabel>Galleri</IonLabel>
            </IonItem>
            <IonItem routerLink={`/c/menu/manage/client/${id}/messages`}>
              <IonLabel>Alla konversationer</IonLabel>
            </IonItem>
            <IonItem routerLink={`/c/menu/manage/client/${id}/profile`}>
              <IonLabel>Profil</IonLabel>
            </IonItem>
          </IonList>
        </AppSheet>
      </AppPageWithSheet>
      <zeus-data-provider id={providerId}>
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/api/v3/group/client/${id}?include=field_avatar,field_subscriptions`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>

      <zeus-data-provider id={coachesProviderId}>
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/api/v3/group_content/client-subgroup-coach?filter[gid.id]=${id}&include=entity_id.field_avatar`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        <zeus-data-processor-jmespath filter="[*].entity_id"></zeus-data-processor-jmespath>
      </zeus-data-provider>
    </AppPageWithHideableHeader>
  );
};

export default ClientView;

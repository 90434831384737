import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonRow,
  IonCol,
  IonItem,
  IonButton,
  IonGrid,
  useIonRouter,
  IonTitle,
  isPlatform,
} from "@ionic/react";
import { Keyboard } from "@capacitor/keyboard";
import { InView } from "react-intersection-observer";
import { useState, useEffect, useRef, Fragment } from "react";
import { useParams } from "react-router";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

import { useAppState } from "../app-context";
import { useCreateMessageMutation, useMarkAsReadMutation } from "../api/messages";

import { AppAvatarPrivate as Avatar } from "../components/AppAvatar";
import Container from "../components/AppContainer";
import Timestamp from "../components/AppTimestamp";
import MessageBox from "../components/AppMessageBox";
import MessageBoxExercise from "../components/AppMessageBoxExercise";
import MessageBoxGallery from "../components/AppMessageBoxGallery";
import AppResponsiveFooter from "../compositions/AppResponsiveFooter";

const MessageContent = ({ length, children }) => {
  const atBottom = useRef(true);
  const firstRender = useRef(true);
  const ionContent = useRef(null);

  useEffect(() => {
    if (length && firstRender.current) {
      ionContent.current.scrollToBottom();
      firstRender.current = false;
    } else if (length && atBottom.current && !firstRender.current) {
      ionContent.current.scrollToBottom(200);
    }
  }, [length]);

  useEffect(() => {
    if (!isPlatform("capacitor")) {
      return;
    }

    const handle = Keyboard.addListener("keyboardDidShow", () => {
      if (atBottom.current) {
        ionContent.current.scrollToBottom();
      }
    });
    return async () => (await handle).remove();
  }, []);

  const checkIfBottom = async (event) => {
    const scrollEl = await event.target.getScrollElement();
    const currentOffset = scrollEl.scrollHeight - scrollEl.scrollTop - scrollEl.clientHeight;
    atBottom.current = currentOffset < 20;
  };

  return (
    <IonContent fullscreen={true} ref={ionContent} scrollEvents={true} onIonScrollEnd={(e) => checkIfBottom(e)}>
      {children}
    </IonContent>
  );
};

const MessagesFooter = ({ onSubmit, locked = false }) => {
  const textArea = useRef();
  const [message, setMessage] = useState("");

  const onMessageChange = (e) => {
    setMessage(e.target.value);
    const pos = e.target.scrollTop;
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
    e.target.style.height = `${Math.min(e.target.scrollHeight, 160)}px`;
    e.target.scrollTo(0, pos);
  };

  const doSubmit = () => {
    if (textArea.current) {
      textArea.current.focus();
      textArea.current.style.height = "inherit";
    }
    onSubmit(message);
    setMessage("");
  };

  return (
    <IonGrid style={{ backgroundColor: "black" }}>
      {locked && (
        <IonRow className="message_row">
          <IonCol className="message_columns">
            <div style={{ width: "100%", textAlign: "center" }}>Tråden är stängd</div>
          </IonCol>
        </IonRow>
      )}
      <IonRow className="message_row">
        <IonCol className="message_columns" size="9">
          <IonItem lines="none">
            <textarea
              ref={textArea}
              disabled={locked}
              style={{
                border: 0,
                width: "100%",
                resize: "none",
                focus: "none",
              }}
              placeholder="Meddelande"
              value={message}
              rows={1}
              onChange={onMessageChange}
            ></textarea>
          </IonItem>
        </IonCol>
        <IonCol class="message_columns" size="3">
          <IonButton clear color="primary" disabled={locked || message === ""} onClick={doSubmit}>
            Skicka
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

const MessageThread = ({ mode }) => {
  const { id: threadId } = useParams();
  const { threads, allMessages } = useAppState();
  const thread = threads?.find((x) => x.id === threadId);
  const messages = allMessages?.find((x) => x?.data?.some((x) => x.thread === threadId))?.data;
  const { mutate } = useCreateMessageMutation(mode);
  const router = useIonRouter();

  const markAsReadMutation = useMarkAsReadMutation();

  const doCreateMessage = (message) => {
    const id = uuidv4();
    mutate({ id, message, thread });
  };

  return (
    <IonPage>
      <IonHeader className="app-header" translucent>
        <IonToolbar className="messages-toolbar">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/c/messages"></IonBackButton>
          </IonButtons>
          <IonTitle>
            <div className="ttt">
              <Avatar
                defaultImage="assets/images/default-avatar.png"
                auth="auth"
                src={thread?.image}
                modifiers="medium bordered"
              />
              <div>{thread?.title}</div>
            </div>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <MessageContent length={messages?.length}>
        <Container modifiers="rounded-top messages-container">
          {messages?.map((message, i, array) => {
            const posts = [];
            const sendOrRecieve = message.is_author ? "sending" : "recieving";

            if (i === 0 || array[i].posted - array[i - 1].posted > 3600000) {
              posts.push(
                <Timestamp key={`${message.id}-timestamp`}>
                  {dayjs(message.posted).format("D MMMM YYYY - HH:mm")}
                </Timestamp>
              );
            }

            if (message.reference?.type === "workout") {
              posts.push(
                <MessageBoxExercise
                  onClick={() => router.push(message.reference?.link)}
                  key={`${message.id}-workout`}
                  modifiers={sendOrRecieve}
                  title={message.reference.text}
                />
              );
            } else if (message.reference?.type === "gallery") {
              posts.push(
                <MessageBoxGallery
                  onClick={() => router.push(message.reference?.link)}
                  key={`${message.id}-gallery`}
                  modifiers={sendOrRecieve}
                  id={message.reference?.id}
                />
              );
            }

            posts.push(
              <InView
                key={`${message.id}-message`}
                onChange={() => {
                  if (message.read_at === null && sendOrRecieve === "recieving") {
                    markAsReadMutation.mutate(message);
                  }
                }}
              >
                <MessageBox
                  onClick={() => {
                    if (message.error) {
                      // TODO: RESEND
                      console.log("resenfd");
                    }
                  }}
                  inTransit={message.in_transit}
                  error={message.error}
                  modifiers={sendOrRecieve}
                  showReadAt={mode === "coach" && message.is_author}
                  readAt={message.read_at}
                >
                  {message.message}
                </MessageBox>
              </InView>
            );

            return <Fragment key={message.id}>{posts}</Fragment>;
          })}
        </Container>
      </MessageContent>
      <AppResponsiveFooter>
        <MessagesFooter locked={thread?.closed} onSubmit={doCreateMessage} />
      </AppResponsiveFooter>
    </IonPage>
  );
};

export default MessageThread;

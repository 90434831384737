import { useState } from "react";
import { useParams } from "react-router";
import { IonButton, IonList, IonModal } from "@ionic/react";
import {
  useDynamicDataProvider,
  useZeusDataProvider,
} from "@understandit/dynamic-data-react";

import { fileSelectPlaceholderImage } from "../util";
import { getSetting } from "../settings";
import AppContainer from "../components/AppContainer";
import AppContent from "../components/AppContent";
import AppHeading from "../components/AppHeading";
import AppHero from "../components/AppHero";
import AppPageWithHideableHeader from "../compositions/AppPageWithHideableHeader";
import AppListItem from "../compositions/AppListItem";
import BottomSpacer from "../components/BottomSpacer";
import WorkoutLogAdd from "../modals/WorkoutLogAdd";

const WorkoutDetail = ({ defaultHref = "/c/menu/workout" }) => {
  const { id } = useParams();
  const [workout] = useDynamicDataProvider(`workout-${id}`);
  const { data: client } = useZeusDataProvider("client");
  const [logModalIsOpen, setLogModalIsOpen] = useState(false);

  return (
    <AppPageWithHideableHeader
      title={workout?.title}
      defaultHref={defaultHref}
      threshold={150}
      backButton
      fullscreen
    >
      <div className="wrapper">
        <AppHero
          contentPosition="center-left"
          background={
            workout?.field_workout_image
              ? workout.field_workout_image.thumbnail.image_style_uri?.large
              : fileSelectPlaceholderImage(workout?.drupal_internal__nid)
          }
        >
          <AppHeading modifiers="highlighted">{workout?.title}</AppHeading>
        </AppHero>

        {workout?.body && (
          <div className="wrapper__column">
            <AppContent modifiers="padding margin-top">
              <h2>Beskrivning</h2>
            </AppContent>
            <AppContent
              modifiers="padding opacity margin-bottom"
              innerContent={workout?.body?.value}
            />
          </div>
        )}

        <div className="wrapper__column wrapper__column--flex">
          <AppContainer modifiers="rounded-top fill">
            <IonList>
              {workout?.field_workout_blocks.map((block, i) => (
                <AppListItem
                  key={block.id}
                  title={block.field_title ?? "Block"}
                  routerLink={`/c/menu/workout/${id}/block/${i}`}
                />
              ))}
            </IonList>
            <AppContent modifiers="text-center">
              <IonButton
                color="secondary"
                onClick={() => setLogModalIsOpen(true)}
              >
                Avsluta / Logga
              </IonButton>
            </AppContent>
            <BottomSpacer />
          </AppContainer>
        </div>
      </div>

      <IonModal isOpen={logModalIsOpen}>
        <WorkoutLogAdd
          workoutId={id}
          clientId={client?.id}
          onClose={() => setLogModalIsOpen(false)}
          text={workout?.field_workout_feedback_text}
        />
      </IonModal>

      <zeus-data-provider id={`workout-${id}`}>
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/api/v3/node/workout/${id}?include=field_workout_tags,field_workout_image.thumbnail,field_workout_blocks.field_workout_block_exercises.field_media.thumbnail`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </AppPageWithHideableHeader>
  );
};

export default WorkoutDetail;

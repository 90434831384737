import { Link } from "react-router-dom";

import { processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-card/pepalexa-card.scss";

const AppCard = ({
  routerLink,
  modifiers,
  media,
  children,
  topRight,
  topLeft,
  ...props
}) => {
  if (routerLink) {
    return (
      <Link
        to={routerLink}
        className={processModifiers("pepalexa-card", modifiers)}
        {...props}
      >
        <div
          className={processModifiers("pepalexa-card", modifiers)}
          {...props}
        >
          {topLeft && (
            <div className="pepalexa-card__top pepalexa-card__top--left">
              {topLeft}
            </div>
          )}
          {topRight && (
            <div className="pepalexa-card__top pepalexa-card__top--right">
              {topRight}
            </div>
          )}
          {media && (
            <div className="pepalexa-card__media">
              <div className="pepalexa-card__media-aspect-ratio-box">
                <div className="pepalexa-card__media-aspect-ratio-box-inside">
                  {media}
                </div>
              </div>
            </div>
          )}
          <div className="pepalexa-card__contents">{children}</div>
        </div>
      </Link>
    );
  }

  return (
    <div className={processModifiers("pepalexa-card", modifiers)} {...props}>
      {topLeft && (
        <div className="pepalexa-card__top pepalexa-card__top--left">
          {topLeft}
        </div>
      )}
      {topRight && (
        <div className="pepalexa-card__top pepalexa-card__top--right">
          {topRight}
        </div>
      )}
      {media && (
        <div className="pepalexa-card__media">
          <div className="pepalexa-card__media-aspect-ratio-box">
            <div className="pepalexa-card__media-aspect-ratio-box-inside">
              {media}
            </div>
          </div>
        </div>
      )}
      <div className="pepalexa-card__contents">{children}</div>
    </div>
  );
};

export default AppCard;

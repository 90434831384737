import { useState } from "react";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSpinner,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";
import {
  useZeusDataProvider,
  useZeusDataStatusSubscriber,
} from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

const Preview = ({ thumbnail, onSave }) => {
  return (
    <div>
      <img src={thumbnail} alt="" />
      <div>
        <IonButton onClick={onSave}>Spara</IonButton>
      </div>
    </div>
  );
};

const Upload = ({ onUpload }) => {
  const [videoUrl, setVideoUrl] = useState("");
  const selectImage = async () => {
    const photo = await Camera.getPhoto({
      quality: 90,
      resultType: CameraResultType.Uri,
      webUseInput: true,
      source: isPlatform("desktop") ? CameraSource.Photos : CameraSource.Prompt,
    });
    onUpload("image", photo.webPath);
  };
  return (
    <IonList>
      <IonItem>
        <IonButton onClick={selectImage}>Välj bild från din dator</IonButton>
      </IonItem>
      <IonItem>eller</IonItem>
      <IonItem>
        <IonLabel position="stacked">Video</IonLabel>
        <IonInput
          value={videoUrl}
          onIonChange={(e) => setVideoUrl(e.target.value)}
          placeholder="Klistra in länk till youtube eller vimeo."
        ></IonInput>
      </IonItem>
      <IonItem>
        <IonButton onClick={() => onUpload("remote_video", videoUrl)}>
          Nästa
        </IonButton>
      </IonItem>
    </IonList>
  );
};

const AppMediaSelector = ({ title = "Välj media", onChange = (e) => {} }) => {
  const {
    data: videoData,
    setData: setVideoData,
    isLoading: videoIsLoading,
  } = useZeusDataProvider("create-remote_video");
  const {
    data: imageData,
    setData: setImageData,
    isLoading: imageIsLoading,
  } = useZeusDataProvider("create-image");
  const [thumbnail, setThumbnail] = useState({ path: "", type: "" });
  const [isOpen, setIsOpen] = useState(false);

  useZeusDataStatusSubscriber(
    "create-image",
    (status) => {
      if (status === "created") {
        setThumbnail({
          path: imageData.thumbnail.image_style_uri.media_library,
          type: "image",
        });
      }
    },
    [imageData]
  );

  useZeusDataStatusSubscriber(
    "create-remote_video",
    (status) => {
      if (status === "created") {
        setThumbnail({
          path: videoData.thumbnail.image_style_uri.media_library,
          type: "remote_video",
        });
      }
    },
    [videoData]
  );

  const open = () => {
    setIsOpen(true);
  };

  const save = () => {
    const mediaData = thumbnail.type === "image" ? imageData : videoData;
    onChange(
      new CustomEvent("appMediaPickerOnChange", {
        detail: { value: mediaData },
      })
    );
    setIsOpen(false);
  };

  const close = () => {
    setIsOpen(false);
    setThumbnail({ path: "", type: "" });
  };

  const upload = (type, data) => {
    if (type === "remote_video") {
      setVideoData({ name: "uploaded video", field_media_oembed_video: data });
    } else if (type === "image") {
      setImageData({
        name: "uploaded image",
        _drupalFiles: [
          { field: "field_media_image", path: data, filename: "test.jpg" },
        ],
      });
    }
  };

  const isLoading = videoIsLoading || imageIsLoading;
  const isUploaded = Boolean(thumbnail.path);

  return (
    <>
      <IonModal isOpen={isOpen} onDidDismiss={close}>
        <IonHeader className="app-header">
          <IonToolbar>
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="secondary">
              <IonButton onClick={() => setIsOpen(false)}>Avbryt</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {isLoading ? (
            <IonSpinner />
          ) : isUploaded ? (
            <Preview thumbnail={thumbnail.path} onSave={save} />
          ) : (
            <Upload onUpload={upload} />
          )}
        </IonContent>
      </IonModal>

      <IonButton onClick={open}>{title}</IonButton>

      <zeus-data-provider id="create-image" write-only>
        <zeus-data-source-jsonapi
          data-action="create"
          type={`media--image`}
          attributes="name,field_media_image"
          relationships="field_media_image:file--file"
          url={`${getSetting("BACKEND")}/api/v3/media/image?include=thumbnail`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>

      <zeus-data-provider id="create-remote_video" write-only>
        <zeus-data-source-jsonapi
          data-action="create"
          type={`media--remote_video`}
          attributes="name,field_media_oembed_video"
          url={`${getSetting(
            "BACKEND"
          )}/api/v3/media/remote_video?include=thumbnail`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

export default AppMediaSelector;

import { useRef, useState } from "react";
import {
  IonAvatar,
  IonButton,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import {
  addCircleOutline,
  addOutline,
  settingsOutline,
  trashOutline,
} from "ionicons/icons";

import { formatMediumDate } from "../../util";
import { useEntityAction } from "../../hooks";
import { useDialogs } from "../../form/hooks";
import { useClientProvider } from "../../providers/client-provider";
import { useParams } from "react-router";
import { useClientCoachesProvider } from "../../providers/client-coaches-provider";
import { useProfileProvider } from "../../providers/profile-provider";
import { useClientAccountsProvider } from "../../providers/client-accounts-provider";
import {
  AppPageWithSheet,
  AppSheet,
  AppTopOfSheet,
} from "../../compositions/AppPageWithSheet";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import BottomSpacer from "../../components/BottomSpacer";
import AppContent from "../../components/AppContent";
import AppHeading from "../../components/AppHeading";
import PrivateImage from "../../components/PrivateImage";

const ProfileClient = () => {
  const { id } = useParams();
  const [selectedCoach, setSelectedCoach] = useState("");
  const { Provider, client, setData } = useClientProvider(id);
  const { Provider: AllCoachesProvider, profiles } =
    useProfileProvider("coach");
  const { Provider: CoachesProvider, coaches } = useClientCoachesProvider(
    id,
    true
  );
  const { Provider: AccountProvider, accounts } = useClientAccountsProvider(id);

  const doEntityAction = useEntityAction();
  const { confirmDangerousAction } = useDialogs();
  const selectRef = useRef();
  const title = `${client?.field_first_name} ${client?.field_last_name}`;

  const subscriptions =
    client?.field_subscriptions?.map((sub) => {
      const label = sub.field_subscription_label ?? "Manuell";
      let subtext = "";
      if (sub.field_subscription_from && sub.field_subscription_to) {
        subtext = `${formatMediumDate(
          sub.field_subscription_from
        )} - ${formatMediumDate(sub.field_subscription_to)}`;
      } else if (sub.field_subscription_from) {
        subtext = `${formatMediumDate(sub.field_subscription_from)}`;
      } else if (sub.field_subscription_active !== null) {
        subtext = `Aktivt: ${sub.field_subscription_active ? "Ja" : "Nej"}`;
      }

      const lofsan_type =
        "field_sub_is_lofsan_fixed" in sub
          ? "subscription_lofsan"
          : "field_sub_is_lofsan_recurring" in sub
          ? "subscription_lofsan_recurring"
          : "subscription_manual";

      const edit_sub_path = [
        "subscription_lofsan",
        "subscription_lofsan_recurring",
      ].includes(lofsan_type)
        ? "edit-lofsan-shop-sub"
        : "edit-sub";

      const link = lofsan_type
        ? `/c/menu/manage/profile/client/${id}/${edit_sub_path}/${lofsan_type}/${sub.id}`
        : null;

      return {
        id: sub.id,
        label,
        subtext,
        manual: lofsan_type === "subscription_manual",
        link,
      };
    }) ?? [];

  const doUnassign = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    confirmDangerousAction({
      button: "Ta bort",
      message: "Är du säker på att du vill ta bort coachen från klienten?",
      handler: () => {
        setSelectedCoach("");
        doEntityAction("unassign_coach", { id });
      },
    });
  };

  const doAssign = (e) => {
    e.preventDefault();
    e.stopPropagation();
    doEntityAction("assign_coach", { coach: selectedCoach, client: id });
    setSelectedCoach("");
  };

  const doOpenSelect = (e) => {
    selectRef.current?.open(e);
  };

  const doDeleteSub = (sub) => {
    confirmDangerousAction({
      button: "Ta bort",
      message: "Är du säker på att du vill ta bort abonnemanget från klienten?",
      handler: () => {
        const newSubs = client.field_subscriptions
          .filter((i) => i.id !== sub.id)
          .map((i) => ({
            ...i,
            action: null,
          }));
        const data = {
          id,
          _paragraphs: [
            {
              field: "field_subscriptions",
              type: "paragraph--subscription_manual",
              values: newSubs,
            },
          ],
        };
        setData(data);
      },
    });
  };

  return (
    <AppPageWithHideableHeader
      title={title}
      defaultHref={"/c/menu/manage/profile"}
      backButton
      toolbarButtons={
        <IonButton routerLink={`/c/menu/manage/profile/client/${id}/edit`}>
          <IonIcon icon={settingsOutline} slot="icon-only" />
        </IonButton>
      }
    >
      <Provider />
      <CoachesProvider />
      <AccountProvider />
      <AllCoachesProvider />
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppContent modifiers="pepalexa-content--padding pepalexa-content--margin-bottom">
            <AppHeading modifiers="center">{title}</AppHeading>
          </AppContent>
        </AppTopOfSheet>
        <AppSheet>
          <IonList className="light">
            <IonItem routerLink={`/c/menu/manage/client/${id}`}>
              <IonAvatar slot="start">
                <PrivateImage
                  defaultImage="assets/images/default-avatar.png"
                  auth="auth"
                  src={client?.field_avatar?.image_style_uri?.medium}
                />
              </IonAvatar>
              <IonLabel>{title}</IonLabel>
            </IonItem>

            <IonItemDivider>Inloggning</IonItemDivider>
            {accounts.map((user) => (
              <IonItem key={user.id}>
                <IonLabel>{user.mail}</IonLabel>
              </IonItem>
            ))}

            <IonItemDivider>Abonnemang</IonItemDivider>
            {subscriptions.map((sub) => (
              <IonItem key={sub.id} routerLink={sub.link ?? undefined}>
                <IonLabel>
                  <h2>{sub.label}</h2>
                  <p>{sub.subtext}</p>
                </IonLabel>
                {sub.manual && (
                  <IonButton
                    slot="end"
                    fill="clear"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      doDeleteSub(sub);
                    }}
                  >
                    <IonIcon slot="icon-only" icon={trashOutline} />
                  </IonButton>
                )}
              </IonItem>
            ))}
            <IonItem routerLink={`/c/menu/manage/profile/client/${id}/add-sub`}>
              <IonLabel>Lägg till abonnemang</IonLabel>
              <IonButton slot="end" fill="clear">
                <IonIcon slot="icon-only" icon={addCircleOutline} />
              </IonButton>
            </IonItem>
            <IonItemDivider>Coach</IonItemDivider>
            {coaches.map((groupContent) => (
              <IonItem
                key={groupContent.entity_id.id}
                routerLink={`/c/menu/manage/profile/coach/${groupContent.entity_id.id}`}
              >
                <IonAvatar slot="start">
                  <PrivateImage
                    defaultImage="assets/images/default-avatar.png"
                    auth="auth"
                    src={
                      groupContent.entity_id?.field_avatar?.image_style_uri
                        ?.medium
                    }
                  />
                </IonAvatar>

                <IonLabel>
                  {groupContent.entity_id.field_first_name}{" "}
                  {groupContent.entity_id.field_last_name}
                </IonLabel>
                <IonButton
                  slot="end"
                  fill="clear"
                  onClick={(e) => doUnassign(e, groupContent.id)}
                >
                  <IonIcon icon={trashOutline} />
                </IonButton>
              </IonItem>
            ))}
            {coaches.length < 2 && (
              <IonItem onClick={doOpenSelect} button>
                <IonAvatar slot="start">
                  <img src="assets/images/default-avatar.png" alt="" />
                </IonAvatar>
                <IonLabel>Ny Coach</IonLabel>
                <IonSelect
                  ref={selectRef}
                  interface="popover"
                  value={selectedCoach}
                  onIonChange={(e) => setSelectedCoach(e.detail.value)}
                >
                  {profiles.map((profile) => (
                    <IonSelectOption key={profile.id} value={profile.id}>
                      {profile.field_first_name} {profile.field_last_name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
                <IonButton
                  slot="end"
                  fill="clear"
                  onClick={(e) => doAssign(e)}
                  disabled={!selectedCoach}
                >
                  <IonIcon slot="icon-only" icon={addOutline} />
                </IonButton>
              </IonItem>
            )}
          </IonList>
          <BottomSpacer />
        </AppSheet>
      </AppPageWithSheet>
    </AppPageWithHideableHeader>
  );
};

export default ProfileClient;

import {
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  isPlatform,
} from "@ionic/react";

import AppContent from "../components/AppContent";
import AppFlag from "../components/AppFlag";
import { useAppState } from "../app-context";
import { settingsOutline } from "ionicons/icons";

const Menu = ({ mode, isAdmin }) => {
  const { profileHasSubscription } = useAppState();

  const pages = [
    // Menu items for client.
    {
      title: "Träningsprogram",
      icon: "assets/icons/timer.svg",
      link: "/c/menu/workout",
      access: ["client"],
      require_subscription_status: true,
    },
    {
      title: "Träningslogg",
      icon: "assets/icons/activity.svg",
      link: "/c/menu/workout-log",
      access: ["client"],
      require_subscription_status: true,
    },
    {
      title: "Kostråd",
      icon: "assets/icons/diet.svg",
      link: "/c/menu/diet",
      access: ["client"],
      require_subscription_status: true,
    },
    {
      title: "Framgång",
      icon: "assets/icons/success.svg",
      link: "/c/menu/progress",
      access: ["client"],
      require_subscription_status: true,
    },
    {
      title: "Galleri",
      icon: "assets/icons/images.svg",
      link: "/c/menu/gallery",
      access: ["client"],
      require_subscription_status: true,
    },
    {
      title: "Din Profil",
      icon: settingsOutline,
      link: "/c/dashboard/profile",
      access: ["client"],
      require_subscription_status: false,
    },

    // Menu items for admin.
    {
      title: "Profiler",
      icon: "assets/icons/profiles.svg",
      link: "/c/menu/manage/profile",
      access: ["admin"],
    },

    // Menu items for coach.
    {
      title: "Klienter",
      icon: "assets/icons/clients.svg",
      link: "/c/menu/manage/client",
      access: ["coach"],
    },
    {
      title: "Träningsprogram",
      icon: "assets/icons/timer.svg",
      link: "/c/menu/manage/workout",
      access: ["coach"],
    },
    {
      title: "Övningar",
      icon: "assets/icons/activity.svg",
      link: "/c/menu/manage/exercise",
      access: ["coach"],
    },
    {
      title: "Kostråd",
      icon: "assets/icons/diet.svg",
      link: "/c/menu/manage/diet",
      access: ["coach"],
    },
  ];

  return (
    <IonPage>
      <IonContent>
        <div className="tmp-spacer"></div>
        <AppContent modifiers="menu">
          <div className="title">Meny</div>
        </AppContent>
        <AppContent modifiers="padding">
          <IonList className="menu-list menu-margin-top">
            {pages
              .filter(
                (page) =>
                  page.access === undefined ||
                  page.access.includes(mode) ||
                  (page.access.includes("admin") && isAdmin)
              )
              .filter(
                (page) =>
                  page.require_subscription_status === undefined ||
                  page.require_subscription_status === profileHasSubscription
              )
              .filter(
                (page) =>
                  !page.platform ||
                  page.platform.some((platform) => isPlatform(platform))
              )
              .map((page) => (
                <IonItem
                  key={page.title}
                  routerLink={page.link}
                  className="menu-item"
                  lines="full"
                >
                  <IonIcon src={page.icon} slot="start" />
                  {page.title}
                  {page.flag && <AppFlag modifiers="up">{page.flag}</AppFlag>}
                </IonItem>
              ))}
          </IonList>
        </AppContent>
      </IonContent>
    </IonPage>
  );
};

export default Menu;

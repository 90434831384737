import { useState } from "react";
import {
  IonButton,
  IonItem,
  IonIcon,
  IonLabel,
  IonModal,
  IonList,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
} from "@ionic/react";
import {
  trashOutline,
  reorderThree,
  createOutline,
  closeCircleOutline,
  checkmarkCircleOutline,
  clipboardOutline,
} from "ionicons/icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { AppPageWithSheet, AppSheet } from "../compositions/AppPageWithSheet";
import { FormItem } from "./FormItem";
import AppText from "../components/AppText";
import EntityPreview from "../pages/manage/EntityPreview";

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const Drag = ({
  id,
  index,
  title,
  showDelete = false,
  showEdit = false,
  onDelete,
  onEdit,
  children,
  level = 0,
  onClick,
  showCopy = true,
}) => {
  const doCopy = (e) => {
    e.stopPropagation();
    const elem = document.createElement("input");
    document.body.appendChild(elem);
    elem.value = title;
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  };

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <IonItem className={`level-${level}`} onClick={onClick} button={Boolean(onClick)}>
              <IonIcon slot="start" icon={reorderThree} {...provided.dragHandleProps} />
              <IonLabel>{title}</IonLabel>
              {showCopy && (
                <IonButton slot="end" onClick={doCopy} fill="clear">
                  <IonIcon slot="icon-only" icon={clipboardOutline} />
                </IonButton>
              )}
              {showEdit && (
                <IonButton slot="end" onClick={onEdit} fill="clear">
                  <IonIcon slot="icon-only" icon={createOutline} />
                </IonButton>
              )}
              {showDelete && (
                <IonButton slot="end" onClick={onDelete} fill="clear">
                  <IonIcon slot="icon-only" icon={trashOutline} />
                </IonButton>
              )}
            </IonItem>
            {children}
          </div>
        );
      }}
    </Draggable>
  );
};

const Drop = ({ id, type, value, children, level = 0 }) => {
  return (
    <Droppable
      droppableId={id}
      type={type}
      renderClone={(provided, _, rubric) => {
        let label = "Block";
        if (rubric.type === "droppable-block") {
          const blockId = rubric.draggableId;
          label = value.find((block) => block.id === blockId)?.field_title ?? "Block";
        } else {
          const blockId = rubric.source.droppableId;
          const sourceIndex = rubric.source.index;
          label = value.find((block) => block.id === blockId).field_workout_block_exercises[sourceIndex].title;
        }
        return (
          <div ref={provided.innerRef} {...provided.draggableProps} className="droppable-clone">
            <IonItem className={`level-${level}`}>
              <IonIcon slot="start" icon={reorderThree} {...provided.dragHandleProps} />
              <IonLabel>{label}</IonLabel>
              <IonButton slot="end" disabled fill="clear">
                <IonIcon slot="icon-only" icon={trashOutline} />
              </IonButton>
            </IonItem>
          </div>
        );
      }}
    >
      {(provided) => {
        return (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {children}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
};

const AppBlockSelector = ({ value = [], onChange = (e) => {} }) => {
  const [blockEditFields, setBlockEditFields] = useState({
    index: 0,
    title: "",
    description: "",
  });
  const [blockEditOpen, setBlockEditOpen] = useState(false);
  const [previewId, setPreviewId] = useState("");

  if (value.length === 0) {
    return <AppText>Välj övningar från listan till höger</AppText>;
  }

  const doReorder = ({ type, source, destination }) => {
    let newValue = value;

    const sourceBlockId = source.droppableId;
    const destinationBlockId = destination.droppableId;

    if (type === "droppable-exercise") {
      // Sort exercises

      if (sourceBlockId === destinationBlockId) {
        const updatedOrder = reorder(
          value.find((block) => block.id === sourceBlockId).field_workout_block_exercises,
          source.index,
          destination.index
        );
        newValue = value.map((block) =>
          block.id !== sourceBlockId ? block : { ...block, field_workout_block_exercises: updatedOrder }
        );
      } else {
        const sourceOrder = value.find((block) => block.id === sourceBlockId).field_workout_block_exercises;
        const destinationOrder = value.find((block) => block.id === destinationBlockId).field_workout_block_exercises;

        const [removed] = sourceOrder.splice(source.index, 1);
        destinationOrder.splice(destination.index, 0, removed);

        newValue = value.map((block) =>
          block.id === sourceBlockId
            ? { ...block, field_workout_block_exercises: sourceOrder }
            : block.id === destinationBlockId
            ? { ...block, items: destinationOrder }
            : block
        );
      }
    } else {
      // Sort blocks
      newValue = reorder(value, source.index, destination.index);
    }

    onChange(new CustomEvent("AppBlockSelectorChange", { detail: { value: newValue } }));
  };

  const doRemove = (blockIndex, exerciseIndex) => {
    // Copy value and remove item.
    const newExerciseList = [...value[blockIndex].field_workout_block_exercises];
    newExerciseList.splice(exerciseIndex, 1);
    const newValue = value.map((block, index) =>
      index === blockIndex ? { ...block, field_workout_block_exercises: newExerciseList } : block
    );

    onChange(new CustomEvent("AppBlockSelectorChange", { detail: { value: newValue } }));
  };

  const doRemoveBlock = (blockIndex) => {
    const newValue = [...value];
    newValue.splice(blockIndex, 1);
    onChange(new CustomEvent("AppBlockSelectorChange", { detail: { value: newValue } }));
  };

  const doEditBlock = (blockIndex) => {
    const block = value[blockIndex];
    setBlockEditFields({
      index: blockIndex,
      title: block.field_title,
      description: block.field_description?.value ?? block.field_description ?? "",
    });
    setBlockEditOpen(true);
  };

  const doSaveBlock = () => {
    const block = value[blockEditFields.index];
    block.field_title = blockEditFields.title;
    block.field_description = blockEditFields.description;
    setBlockEditOpen(false);
    setBlockEditFields({
      index: 0,
      title: "",
      description: "",
    });
  };

  return (
    <div className="app-block-selector">
      <IonModal isOpen={previewId !== ""} onDidDismiss={() => setPreviewId("")}>
        <EntityPreview id={previewId} type="exercise" dismiss={() => setPreviewId("")} />
      </IonModal>
      <IonModal isOpen={blockEditOpen} onDidDismiss={() => setBlockEditOpen(false)}>
        <IonHeader className="app-header">
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setBlockEditOpen(false)}>
                <IonIcon icon={closeCircleOutline} slot="icon-only" />
              </IonButton>
            </IonButtons>
            <IonTitle>Redigera block</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={doSaveBlock}>
                <IonIcon icon={checkmarkCircleOutline} slot="icon-only" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <AppPageWithSheet>
            <AppSheet>
              <IonList className="form">
                <FormItem
                  label="Titel"
                  type="text"
                  value={blockEditFields.title}
                  onChange={(e) =>
                    setBlockEditFields((current) => ({
                      ...current,
                      title: e.detail.value,
                    }))
                  }
                />
                <FormItem
                  label="Beskrivning"
                  type="body"
                  value={blockEditFields.description}
                  onChange={(e) =>
                    setBlockEditFields((current) => ({
                      ...current,
                      description: e.detail.value,
                    }))
                  }
                  last
                />
              </IonList>
            </AppSheet>
          </AppPageWithSheet>
        </IonContent>
      </IonModal>
      <DragDropContext onDragEnd={doReorder}>
        <Drop id="droppable" type="droppable-block" value={value}>
          {value.map((block, blockIndex) => (
            <Drag
              key={block.id}
              index={blockIndex}
              id={block.id}
              title={block.field_title ?? "Block"}
              showDelete
              showEdit
              onDelete={() => doRemoveBlock(blockIndex)}
              onEdit={() => doEditBlock(blockIndex)}
            >
              <Drop key={block.id} id={block.id} type="droppable-exercise" value={value} level={1}>
                {block.field_workout_block_exercises.map((exercise, exerciseIndex) => (
                  <Drag
                    key={`${exercise?.id ?? "null"}-${exerciseIndex}`}
                    id={`${exercise?.id ?? "null"}-${exerciseIndex}`}
                    index={exerciseIndex}
                    title={exercise?.title ?? "null"}
                    level={1}
                    showDelete
                    onDelete={(e) => {
                      e.stopPropagation();
                      doRemove(blockIndex, exerciseIndex);
                    }}
                    onClick={() => setPreviewId(exercise?.id)}
                  />
                ))}
              </Drop>
            </Drag>
          ))}
        </Drop>
      </DragDropContext>
    </div>
  );
};

export default AppBlockSelector;

import { useMemo } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";
import dayjs from "dayjs";

import { getSetting } from "../settings";

export function useClientWorkoutLogProvider(clientId, activeDay, mode = "day") {
  const type = "workout_log";
  const providerId = `client-${type}-provider-${clientId}-${mode}`;
  const date = dayjs(activeDay);

  const params = {
    "filter[date-filter][condition][path]": "entity_id.created",
    "filter[date-filter][condition][operator]": "BETWEEN",
    "filter[date-filter][condition][value][1]": date.startOf(mode).unix(),
    "filter[date-filter][condition][value][2]": date.endOf(mode).unix(),
    "filter[gid.id]": clientId,
    sort: "entity_id.created",
    include: "entity_id.field_workout_log_workout",
  };

  if (mode === "month") {
    params.include = "entity_id";
  }

  const url = `${getSetting(
    "BACKEND"
  )}/api/v3/group_content/client-group_node-${type}?${new URLSearchParams(
    params
  )}`;

  const { data, isLoading } = useZeusDataProvider(providerId, []);

  const Provider = useMemo(() => {
    const Component = () => (
      <zeus-data-provider id={providerId} listen-tags={type}>
        <zeus-data-source-jsonapi url={url}></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        <zeus-data-processor-jmespath filter="[*].entity_id"></zeus-data-processor-jmespath>
      </zeus-data-provider>
    );
    return Component;
  }, [providerId, url, type]);

  return {
    Provider,
    entities: data,
    isLoading,
  };
}

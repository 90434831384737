import { processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-media-with-overlap/pepalexa-media-with-overlap.scss";

const AppMediaWithOverlap = ({
  modifiers,
  media,
  text,
  mediaOverlap,
  ...props
}) => {
  return (
    <div
      {...props}
      className={processModifiers("pepalexa-media-with-overlap", modifiers)}
    >
      <div className="pepalexa-media-with-overlap__inner">
        {media}
        {mediaOverlap && (
          <div className="pepalexa-media-with-overlap__overlap">
            {mediaOverlap}
          </div>
        )}
      </div>
      {text && <p className="pepalexa-media-with-overlap__text">{text}</p>}
    </div>
  );
};

export default AppMediaWithOverlap;

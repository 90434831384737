import { useParams } from "react-router";
import { IonItem, IonList, IonLabel } from "@ionic/react";

import { useContentProvider } from "../../providers/content-provider";
import {
  AppPageWithSheet,
  AppSheet,
  AppTopOfSheet,
} from "../../compositions/AppPageWithSheet";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import AppContent from "../../components/AppContent";
import AppHeading from "../../components/AppHeading";

const ClientWorkoutLogDetail = ({ defaultHref }) => {
  const { client_id, id } = useParams();
  const { Provider, entity } = useContentProvider(id, "workout_log", {
    include: "field_workout_log_workout",
  });

  return (
    <AppPageWithHideableHeader
      title={entity?.field_workout_log_workout?.title ?? entity?.title}
      defaultHref={
        defaultHref ?? `/c/menu/manage/client/${client_id}/workout-log`
      }
      backButton
    >
      <Provider />
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppContent modifiers="pepalexa-content--padding pepalexa-content--margin-bottom">
            <AppHeading modifiers="center">
              {entity?.field_workout_log_workout?.title ?? entity?.title}
            </AppHeading>
          </AppContent>
        </AppTopOfSheet>
        <AppSheet>
          <IonList className="light">
            <IonItem>
              <IonLabel position="stacked">Ansträngning</IonLabel>
              {entity?.field_workout_log_effort}
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Utmaning</IonLabel>
              {entity?.field_workout_log_challenge}
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Roligt</IonLabel>
              {entity?.field_workout_log_enjoyment}
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Anteckning</IonLabel>
              {entity?.field_workout_log_summary}
            </IonItem>
          </IonList>
        </AppSheet>
      </AppPageWithSheet>
    </AppPageWithHideableHeader>
  );
};

export default ClientWorkoutLogDetail;

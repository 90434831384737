import { processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-icon/pepalexa-icon.scss";

const AppIcon = ({ modifiers, icon, ...props }) => (
  <img
    alt="icon"
    className={processModifiers("pepalexa-icon", modifiers)}
    src={icon}
    {...props}
  />
);

export default AppIcon;

import { useEffect, useState } from "react";
import { getPrivateFile } from "../private-files";

const PrivateVideo = ({ src, auth, poster, alt, className }) => {
  const [videoSrc, setVideoSrc] = useState("");
  const [posterSrc, setPosterSrc] = useState("");

  useEffect(() => {
    if (src) {
      const controller = new AbortController();
      const signal = controller.signal;
      getPrivateFile(src, auth, signal).then((vSrc) => setVideoSrc(vSrc));
      return () => controller.abort();
    }
  }, [auth, src]);

  useEffect(() => {
    if (poster) {
      const controller = new AbortController();
      const signal = controller.signal;
      getPrivateFile(poster, auth, signal).then((pSrc) => setPosterSrc(pSrc));
      return () => controller.abort();
    }
  }, [poster, auth]);

  return (
    <video
      controls
      playsInline
      poster={posterSrc}
      src={videoSrc}
      alt={alt}
      className={className}
    />
  );
};

export default PrivateVideo;

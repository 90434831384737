import { useState } from "react";
import { useParams } from "react-router";
import { useZeusDataStatusSubscriber } from "@understandit/dynamic-data-react";
import {
  IonButton,
  IonIcon,
  IonList,
  IonLoading,
  IonItem,
  IonLabel,
  IonToggle,
  useIonRouter,
  IonInput,
  IonModal,
  IonDatetime,
  IonDatetimeButton,
} from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";

import { useClientProvider } from "../../providers/client-provider";
import {
  AppPageWithSheet,
  AppSheet,
  AppTopOfSheet,
} from "../../compositions/AppPageWithSheet";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import BottomSpacer from "../../components/BottomSpacer";
import AppContent from "../../components/AppContent";
import AppHeading from "../../components/AppHeading";

const ProfileClientAddSub = () => {
  const { id } = useParams();
  const defaultBack = `/c/menu/manage/profile/client/${id}`;
  const { Provider, client, setData, isLoading, providerId } =
    useClientProvider(id);
  const router = useIonRouter();
  const [field_subscription_label, set_field_subscription_label] =
    useState(null);
  const [field_subscription_from, set_field_subscription_from] = useState(null);
  const [subscription_has_to, set_subscription_has_to] = useState(null);
  const [field_subscription_to, set_field_subscription_to] = useState(null);

  useZeusDataStatusSubscriber(providerId, (status) => {
    if (status === "updated") {
      router.push(defaultBack, "back");
    }
  });

  const doSave = () => {
    const newSubs = client.field_subscriptions.map((i) => ({
      ...i,
      action: null,
    }));

    const now = new Date();
    const from = field_subscription_from ? field_subscription_from.replace(/\+[0-9][0-9]:[0-9][0-9]/, "Z") : null;
    const to = subscription_has_to
          ? field_subscription_to
            ? field_subscription_to.replace(/\+[0-9][0-9]:[0-9][0-9]/, "Z")
            : now.toISOString().replace(/\.[0-9]{3}/, "")
          : null
    const fromDate = from ? new Date(from) : (() => {const date = new Date(); date.setHours(2,0,0,0); return date;})()
    const toDate = to ? new Date(to) : null;
    newSubs.push({
      action: "create",
      field_subscription_from: fromDate.toISOString().replace(/\.[0-9]{3}/, ""),
      field_subscription_to: toDate ? toDate.toISOString().replace(/\.[0-9]{3}/, "") : null,
      field_subscription_label: field_subscription_label,
      field_subscription_active: fromDate <= now && (!toDate || toDate > now),
    });
    const data = {
      id,
      _paragraphs: [
        {
          field: "field_subscriptions",
          type: "paragraph--subscription_manual",
          attributes: ["field_subscription_active","field_subscription_from","field_subscription_to","field_subscription_label"],
          values: newSubs,
        },
      ],
    };
    setData(data);
  };

  return (
    <AppPageWithHideableHeader
      title="Lägg till abonnemang"
      defaultHref={defaultBack}
      backButton
      toolbarButtons={
        <IonButton onClick={doSave} disabled={!field_subscription_label}>
          <IonIcon icon={checkmarkCircleOutline} slot="icon-only" />
        </IonButton>
      }
    >
      <Provider />
      <IonLoading isOpen={isLoading} />
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppContent modifiers="pepalexa-content--padding pepalexa-content--margin-bottom">
            <AppHeading modifiers="center">Lägg till abonnemang</AppHeading>
          </AppContent>
        </AppTopOfSheet>
        <AppSheet>
          <IonList class="form">
            <IonItem lines="none">
              <IonLabel>Namn:</IonLabel>
              <IonInput
                value={field_subscription_label}
                onIonChange={(e) =>
                  set_field_subscription_label(e.detail.value)
                }
              />
            </IonItem>
            <IonModal keepContentsMounted={true} style={{ color: "black" }}>
              <IonDatetime
                value={field_subscription_from}
                id="datetime-modal-from"
                onIonChange={(e) => {
                  set_field_subscription_from(e.detail.value);
                }}
              />
            </IonModal>
            <IonItem>
              <IonLabel>Från:</IonLabel>
              <IonDatetimeButton
                style={{ "--ion-text-color": "black" }}
                color="dark"
                datetime="datetime-modal-from"
              ></IonDatetimeButton>
            </IonItem>
            <IonModal keepContentsMounted={true} style={{ color: "black" }}>
              <IonDatetime
                value={field_subscription_to}
                id="datetime-modal-to"
                onIonChange={(e) => {
                  set_field_subscription_to(e.detail.value);
                }}
              />
            </IonModal>
            <IonItem>
              <IonLabel>Ange till-värde:</IonLabel>
              <IonToggle
                checked={subscription_has_to}
                onIonChange={(e) => set_subscription_has_to(e.detail.checked)}
              />
            </IonItem>
            {subscription_has_to && (
              <IonItem lines="none">
                <IonLabel>Till:</IonLabel>
                <IonDatetimeButton
                  style={{ "--ion-text-color": "black" }}
                  color="dark"
                  datetime="datetime-modal-to"
                ></IonDatetimeButton>
              </IonItem>
            )}
          </IonList>

          <BottomSpacer />
        </AppSheet>
      </AppPageWithSheet>
    </AppPageWithHideableHeader>
  );
};

export default ProfileClientAddSub;

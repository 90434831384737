import {
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonToggle,
} from "@ionic/react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import AppTagSelector from "./AppTagSelector";
import AppMediaSelector from "./AppMediaSelector";
import AppNodeSelector from "./AppNodeSelector";
import AppBlockSelector from "./AppBlockSelector";
import PrivateFileUpload from "./PrivateFileUpload";
import EmbededVideo from "../components/EmbededVideo";
import BlockPreview from "./BlockPreview";

export const FormItem = ({
  value,
  onChange,
  label,
  type,
  config,
  last = false,
}) => {
  if (type === "text") {
    return (
      <IonItem lines="full">
        <IonLabel position="stacked">{label}</IonLabel>
        <IonInput value={value} onIonChange={onChange} />
      </IonItem>
    );
  }
  if (type === "boolean") {
    return (
      <IonItem lines="full">
        <IonLabel>{label}</IonLabel>
        <IonToggle
          checked={value}
          onIonChange={(e) => {
            onChange(
              new CustomEvent("tagSelectorChange", {
                detail: { value: e.detail.checked },
              })
            );
          }}
        />
      </IonItem>
    );
  }
  if (type === "body") {
    return (
      <IonItem lines={!last ? "full" : "none"}>
        <IonLabel position="stacked">{label}</IonLabel>
        <div
          style={{
            marginTop: "10px",
            marginBottom: "16px",
            width: "100%",
          }}
        >
          <CKEditor
            editor={Editor}
            data={value}
            onChange={(event, editor) => {
              onChange(
                new CustomEvent("tagSelectorChange", {
                  detail: { value: editor.getData() },
                })
              );
            }}
          />
        </div>
      </IonItem>
    );
  }
  if (type === "term") {
    return (
      <AppTagSelector
        lines="full"
        title={label}
        vocabulary={config.vocabulary}
        value={value}
        onChange={onChange}
        single={config.single}
        showSearch={config.showSearch}
      />
    );
  }
  if (type === "media") {
    return (
      <IonItem>
        <IonLabel position="stacked">{label}</IonLabel>
        <img src={value?.thumbnail.image_style_uri.media_library} alt="" />
        <AppMediaSelector onChange={onChange} />
        {value && (
          <IonButton
            onClick={() =>
              onChange(
                new CustomEvent("appMediaPickerOnChange", {
                  detail: { value: null },
                })
              )
            }
          >
            Ta bort media
          </IonButton>
        )}
      </IonItem>
    );
  }
  if (type === "private_file") {
    <PrivateFileUpload label={label} value={value} onChange={onChange} />;
  }
  if (type === "node-reference") {
    return <AppNodeSelector value={value} onChange={onChange} />;
  }
  if (type === "workout-blocks") {
    return <AppBlockSelector value={value} onChange={onChange} />;
  }

  return null;
};

export const FormValue = ({ value, label, type, config, last = false }) => {
  if (type === "text") {
    return (
      <IonItem lines={!last ? "full" : "none"}>
        <IonLabel position="stacked">{label}</IonLabel>
        <IonInput value={value} readonly />
      </IonItem>
    );
  }
  if (type === "boolean") {
    return (
      <IonItem lines={!last ? "full" : "none"}>
        <IonLabel>{label}</IonLabel>
        <IonToggle disabled checked={value} />
      </IonItem>
    );
  }
  if (type === "body") {
    return (
      <IonItem lines={!last ? "full" : "none"}>
        <IonLabel position="stacked">{label}</IonLabel>
        <div
          style={{
            marginTop: "10px",
            marginBottom: "16px",
            width: "100%",
          }}
          dangerouslySetInnerHTML={{ __html: value }}
        />
      </IonItem>
    );
  }
  if (type === "term") {
    return (
      <IonItem lines={!last ? "full" : "none"}>
        <IonLabel position="stacked">{label}</IonLabel>
        <IonInput value={value?.map((term) => term.name).join(", ")} readonly />
      </IonItem>
    );
  }
  if (type === "media") {
    const type = value?.field_media_oembed_video ? "video" : "image";
    return (
      <IonItem lines={!last ? "full" : "none"}>
        <IonLabel position="stacked">{label}</IonLabel>
        <div className="media-preview-container">
          {type === "image" && (
            <img src={value?.thumbnail.image_style_uri.media_library} alt="" />
          )}
          {type === "video" && (
            <EmbededVideo src={value.field_media_oembed_video} />
          )}
        </div>
      </IonItem>
    );
  }
  if (type === "workout-blocks") {
    return <BlockPreview value={value} />;
  }

  return null;
};

import { IonAvatar, IonBadge, IonIcon, IonItem, IonLabel } from "@ionic/react";

import AppFlag from "../components/AppFlag";
import PrivateImage from "../components/PrivateImage";

const AppNotificationListItem = (props) => {
  return (
    <IonItem
      className="ion-item--padding ion-item--dark-text notification-item"
      lines="full"
      routerLink={props.link}
      onClick={props.action}
      button={props.button}
    >
      {props.avatar && props.avatarPrivateAuth && (
        <IonAvatar slot="start">
          <PrivateImage auth={props.avatarPrivateAuth} src={props.avatar} />
        </IonAvatar>
      )}
      {props.avatar && !props.avatarPrivateAuth && (
        <IonAvatar slot="start">
          <img src={props.avatar} alt="Profile avatar" />
        </IonAvatar>
      )}

      {props.icon && <IonIcon src={props.icon} slot="start" />}

      <IonLabel>
        <h2>{props.title}</h2>
        {props.subtitle && <p className="item-subtitle">{props.subtitle}</p>}
        {props.flag_text && <AppFlag modifiers="top-right-corner">{props.flag_text}</AppFlag>}
      </IonLabel>
      {!!props.badge && <IonBadge slot="end">{props.badge}</IonBadge>}
    </IonItem>
  );
};

export default AppNotificationListItem;

import { useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonSearchbar,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

const AppTagSelector = ({
  title,
  vocabulary,
  value = [],
  lines,
  onChange = (e) => {},
  single = false,
  showSearch = true,
}) => {
  const { data: terms } = useZeusDataProvider(`terms-${vocabulary}`, []);
  const { isLoading, setData } = useZeusDataProvider(
    `terms-${vocabulary}-create`,
    []
  );
  const [selected, setSelected] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const isTermSelected = (term) => selected.some((i) => i.id === term.id);

  const toggleTerm = (term, checked) => {
    setSelected((current) => {
      if (single) {
        const opt = current.some((x) => x.id === term.id) ? [] : current;
        return checked ? [{ id: term.id, name: term.name }] : opt;
      } else {
        const newList = current.filter((i) => i.id !== term.id);
        if (checked) {
          newList.push({ id: term.id, name: term.name });
        }
        return newList;
      }
    });
  };

  const save = () => {
    onChange(
      new CustomEvent("tagSelectorChange", { detail: { value: selected } })
    );
    setIsOpen(false);
  };

  const open = () => {
    setSelected(value);
    setSearchQuery("");
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  const filterTerms = (term) => {
    return searchQuery === "" || term.name.toLowerCase().includes(searchQuery);
  };

  const handleCreate = () => {
    setData({ name: searchQuery });
  };

  return (
    <>
      <IonModal isOpen={isOpen} onDidDismiss={close}>
        <IonLoading isOpen={isLoading} />
        <IonHeader>
          <IonToolbar>
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={save}>Spara</IonButton>
            </IonButtons>
            <IonButtons slot="secondary">
              <IonButton onClick={() => setIsOpen(false)}>Avbryt</IonButton>
            </IonButtons>
          </IonToolbar>
          {showSearch && (
            <IonToolbar>
              <IonSearchbar
                placeholder="Filtrera eller skapa"
                onIonChange={(e) => setSearchQuery(e.detail.value)}
              />
              <IonButtons slot="end">
                <IonButton disabled={searchQuery === ""} onClick={handleCreate}>
                  Skapa
                </IonButton>
              </IonButtons>
            </IonToolbar>
          )}
        </IonHeader>
        <IonContent>
          <IonList>
            {terms.filter(filterTerms).map((term) => (
              <IonItem key={term.id}>
                <IonLabel>{term.name}</IonLabel>
                <IonToggle
                  checked={isTermSelected(term)}
                  onIonChange={(e) => {
                    // console.log(e);
                    toggleTerm(term, e.detail.checked);
                  }}
                />
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      </IonModal>

      <IonItem lines={lines} button onClick={open}>
        <IonLabel position="stacked">{title}</IonLabel>
        <IonInput
          readonly
          placeholder="Klicka här för att välja"
          value={value.map((i) => i?.name).join(", ")}
        ></IonInput>
      </IonItem>

      <zeus-data-provider
        id={`terms-${vocabulary}`}
        listen-tags={`terms-${vocabulary}`}
      >
        <zeus-data-source-jsonapi
          url={`${getSetting("BACKEND")}/api/v3/taxonomy_term/${vocabulary}`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>

      <zeus-data-provider
        id={`terms-${vocabulary}-create`}
        send-tags={`terms-${vocabulary}`}
        write-only
      >
        <zeus-data-source-jsonapi
          url={`${getSetting("BACKEND")}/api/v3/taxonomy_term/${vocabulary}`}
          data-action="create"
          type={`taxonomy_term--${vocabulary}`}
          attributes="name"
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

export default AppTagSelector;

import { useMemo } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";
import dayjs from "dayjs";

function addType(inputArray, type) {
  return inputArray.map((i) => ({ ...i, type }));
}

export function useClientFeedProvider(clientId, ts = 0) {
  const { data: data1, isLoading1 } = useZeusDataProvider(
    `client-feed-${clientId}-diet_assigned-${ts}`,
    []
  );
  const { data: data2, isLoading2 } = useZeusDataProvider(
    `client-feed-${clientId}-workout_assigned-${ts}`,
    []
  );
  const { data: data3, isLoading3 } = useZeusDataProvider(
    `client-feed-${clientId}-message_received-${ts}`,
    []
  );

  const isLoading = isLoading1 || isLoading2 || isLoading3;

  const entities = useMemo(() => {
    return [
      ...addType(data1, "diet"),
      ...addType(data2, "workout"),
      ...addType(data3, "message"),
    ].sort((a, b) => dayjs(b.created).unix() - dayjs(a.created).unix());
  }, [data1, data2, data3]);

  const Provider = useMemo(() => {
    const Component = () => {
      if (!clientId) {
        return null;
      }
      return (
        <>
          {["diet_assigned", "workout_assigned", "message_received"].map(
            (type) => {
              const providerId = `client-feed-${clientId}-${type}-${ts}`;
              const url = `${getSetting(
                "BACKEND"
              )}/api/v3/message/${type}?filter[field_group.id]=${clientId}&sort=-created&page[limit]=10&include=field_group_content.entity_id${
                type === "message_received"
                  ? `,field_group_content.entity_id.field_messsage_thread.field_message_thread_coach,field_group_content.entity_id.field_message_reference.node_type`
                  : ``
              }`;
              return (
                <zeus-data-provider
                  key={type}
                  id={`${providerId}`}
                  listen-tags="feed"
                >
                  <zeus-data-source-jsonapi
                    url={url}
                  ></zeus-data-source-jsonapi>
                  <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
                </zeus-data-provider>
              );
            }
          )}
        </>
      );
    };
    return Component;
  }, [clientId, ts]);

  return {
    Provider,
    entities,
    isLoading,
  };
}

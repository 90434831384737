import { useMemo, useState } from "react";
import { IonList, IonButton, IonIcon, IonModal } from "@ionic/react";
import dayjs from "dayjs";

import { useClientWorkoutLogProvider } from "../providers/client-workout-log-provider";
import AppCalendar from "../components/AppCalendar";
import AppContainer from "../components/AppContainer";
import AppContent from "../components/AppContent";
import AppNotesWithTitle from "../components/AppNotesWithTitle";
import AppWorkoutRating from "../components/AppWorkoutRating";
import AppNotificationListItem from "../compositions/AppNotificationListItem";
import BottomSpacer from "../components/BottomSpacer";
import AppPageWithHideableHeader from "../compositions/AppPageWithHideableHeader";
import AppHeading from "../components/AppHeading";
import WorkoutLogAdd from "../modals/WorkoutLogAdd";
import WorkoutLogEdit from "../modals/WorkoutLogEdit";

const WorkoutLog = ({ inTabs = false, groupId }) => {
  const [activeDay, setActiveDay] = useState(new Date());
  const [activeMonth, setActiveMonth] = useState(new Date());
  const [logModalIsOpen, setLogModalIsOpen] = useState(false);
  const [editLog, setEditLog] = useState("");
  const { Provider, entities, isLoading } = useClientWorkoutLogProvider(
    groupId,
    activeDay
  );
  const { Provider: MonthProvider, entities: eventsForActiveMonth } =
    useClientWorkoutLogProvider(groupId, activeMonth, "month");

  const activeDays = useMemo(() => {
    return eventsForActiveMonth
      .map((item) => dayjs(item.created).date())
      .filter((v, i, a) => a.indexOf(v) === i);
  }, [eventsForActiveMonth]);

  const doUpdateDate = (e) => {
    setActiveDay(e.detail.value);
  };

  const doUpdateMonth = (e) => {
    setActiveMonth(e.detail.value);
  };

  return (
    <AppPageWithHideableHeader
      title="Träningslogg"
      defaultHref="/c/menu"
      backButton={inTabs}
      toolbarButtons={
        <IonButton onClick={() => setLogModalIsOpen(true)}>
          <IonIcon slot="icon-only" src="assets/icons/add.svg" />
        </IonButton>
      }
    >
      <IonModal isOpen={logModalIsOpen}>
        <WorkoutLogAdd
          clientId={groupId}
          date={activeDay}
          onClose={() => setLogModalIsOpen(false)}
        />
      </IonModal>
      <IonModal isOpen={editLog !== ""}>
        <WorkoutLogEdit workoutLogId={editLog} onClose={() => setEditLog("")} />
      </IonModal>

      <Provider />
      <MonthProvider />
      <AppContent modifiers="padding">
        <AppHeading modifiers="center">Träningslogg</AppHeading>
      </AppContent>
      <AppContent modifiers="padding">
        <AppCalendar
          value={activeDay}
          onChange={doUpdateDate}
          highlightDays={activeDays}
          onViewChange={doUpdateMonth}
        />
      </AppContent>

      {!isLoading &&
        entities.map((item) => (
          <AppContainer key={item.id} modifiers="rounded padding-bottom">
            <IonList className="list-with-margin padding-top">
              <AppNotificationListItem
                title={item.field_workout_log_workout?.title ?? item.title}
              />
            </IonList>
            <AppWorkoutRating
              icon_one="assets/icons/timer.svg"
              icon_two="assets/icons/timer.svg"
              icon_three="assets/icons/timer.svg"
              label_one="Ansträngning"
              label_two="Utmaning"
              label_three="Roligt"
              amount_one={`${item.field_workout_log_effort}%`}
              amount_two={`${item.field_workout_log_challenge}%`}
              amount_three={`${item.field_workout_log_enjoyment}%`}
            />
            {item.field_workout_log_summary && (
              <AppNotesWithTitle
                title="Anteckning"
                notes={item.field_workout_log_summary}
              />
            )}
            <div className="button-container">
              <IonButton
                color="secondary"
                fill="outline"
                size="small"
                onClick={() => setEditLog(item.id)}
              >
                Redigera feedback
              </IonButton>
            </div>
          </AppContainer>
        ))}
      <BottomSpacer />
    </AppPageWithHideableHeader>
  );
};

export default WorkoutLog;

import { useMemo } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

export function useClientContentCreateProvider(type) {
  const providerId = `client-create-${type}-provider`;
  const { data, isLoading, setData } = useZeusDataProvider(providerId, []);
  const Provider = useMemo(() => {
    const Component = () => (
      <zeus-data-provider id={providerId} send-tags={type}>
        <zeus-data-source-drupal-group-member-content-create
          base-url={getSetting("BACKEND")}
          jsonapi-path="/api/v3"
          content-bundle={type}
          group-bundle="client"
          token-provider="auth"
        ></zeus-data-source-drupal-group-member-content-create>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    );
    return Component;
  }, [providerId, type]);

  return {
    Provider,
    providerId,
    setData,
    data,
    isLoading,
  };
}

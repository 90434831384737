import { getSetting } from "./settings";

const BODY_BOUNDARY = "\r\n\r\n";

function getToken() {
  const q = new Promise((resolve, reject) => {
    document.addEventListener(
      `zeusDataAvailable:auth`,
      (event) => {
        if (event.detail && event.detail.tokens) {
          resolve(event.detail.tokens);
        } else {
          reject("No token");
        }
      },
      { once: true }
    );
  });
  document.dispatchEvent(new Event(`zeusDataListener:auth`));
  return q;
}

/**
 *
 * @param {string} id
 * @param {*} thread
 * @param {string} message
 * @param {*} richContent
 * @param {"client" | "coach"} mode
 * @returns
 */
export async function createMessage(id, thread, message, richContent, mode) {
  const tokenData = await getToken();

  const token = tokenData.access_token;
  const headers = {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
    Authorization: `Bearer ${token}`,
  };

  const messageData = {
    data: {
      type: "node--message",
      attributes: {
        title: message.substring(0, 50),
        field_message_message: message,
        field_message_from_client: mode === "client",
      },
      relationships: {
        field_messsage_thread: {
          data: {
            id: thread?.id,
            type: "node--message_thread",
          },
        },
      },
    },
  };

  if (richContent) {
    messageData.data.attributes.field_message_reference_index = richContent.index ?? 0;
    messageData.data.relationships.field_message_reference = {
      data: {
        id: richContent.id,
        type: `node--${richContent.type}`,
      },
    };
  }

  const groupData = {
    data: {
      id,
      type: "group_content--client-group_node-message",
      relationships: {
        entity_id: {
          data: {
            type: "node--message",
            id: "{{entity_request.body@$.data.id}}",
          },
        },
        gid: {
          data: {
            type: "group--client",
            id: thread?.clientId,
          },
        },
      },
    },
  };

  const subrequests = [
    {
      action: "create",
      headers,
      requestId: "entity_request",
      body: JSON.stringify(messageData),
      uri: "/api/v3/node/message",
    },
    {
      action: "create",
      headers,
      requestId: "group_memberhsip",
      waitFor: ["entity_request"],
      body: JSON.stringify(groupData),
      uri: "/api/v3/group_content/client-group_node-message",
    },
  ];

  const url = getSetting("BACKEND") + "/subrequests";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(subrequests),
  });

  if (response.status !== 207) {
    throw new Error("Subrequest failed");
  }

  const boundary = response.headers?.get("Content-Type")?.match(/boundary="(.*?)"/)?.[1];

  if (boundary === undefined) {
    throw new Error("No boundry in multipart response");
  }

  const body = await response.text();
  const parts = body
    .split(`--${boundary}`)
    .filter((x) => Boolean(x.trim()))
    .filter((x) => x.indexOf(BODY_BOUNDARY) !== -1)
    .map((part) => {
      const [headers, body] = part.split(BODY_BOUNDARY);
      return {
        headers,
        body: JSON.parse(body),
      };
    });

  return parts;
}

import { useState, useEffect } from "react";
import {
  IonList,
  IonItem,
  IonAvatar,
  IonButton,
  IonLabel,
  IonTitle,
  IonButtons,
  IonRow,
  IonCol,
  IonCheckbox,
  IonLoading,
  IonModal,
  IonHeader,
  IonToolbar,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonContent,
  IonSpinner,
} from "@ionic/react";
import {
  useZeusDataProvider,
  useZeusDataStatusSubscriber,
} from "@understandit/dynamic-data-react";
import {
  addCircleOutline,
  chatbubblesOutline,
  checkboxOutline as selectIcon,
  closeCircleOutline,
} from "ionicons/icons";

import { getSetting } from "../../settings";
import { useProfileProvider } from "../../providers/profile-provider";
import {
  AppPageWithSheet,
  AppTopOfSheet,
  AppSheet,
} from "../../compositions/AppPageWithSheet";
import { AppTabsHeader, AppTabsButton } from "../../components/AppTabs";
import AppContent from "../../components/AppContent";
import AppHeading from "../../components/AppHeading";
import AppSearchbar from "../../components/AppSearchbar";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import PrivateImage from "../../components/PrivateImage";
import AppTagFilter from "../../form/AppTagFilter";
import BottomSpacer from "../../components/BottomSpacer";

const ProfilesClients = ({
  setSelectedTab,
  selectMode = false,
  showArchived = false,
  showSendMessage,
  setShowSendMessage,
  selectedRows,
  setSelectedRows,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchString, setSearchString] = useState("");
  const [searchTags, setSearchTags] = useState({});
  const { Provider, profiles, isLoading } = useProfileProvider(
    "client",
    searchString,
    searchTags,
    showArchived
  );
  const doSearch = (e) => {
    e?.preventDefault();
    setSearchString(searchValue);
  };
  const doResetFilter = () => {
    setSearchString("");
    setSearchValue("");
    setSearchTags({});
  };

  const { Provider: AllCoachesProvider, profiles: coaches } =
    useProfileProvider("coach");

  const [message, setMessage] = useState("");
  const [closeMessageThreads, setCloseMessageThreads] = useState(false);
  const [messageInTransit, setMessageInTransit] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [messageCoach, setMessageCoach] = useState(null);
  const { setData: sendMessages } = useZeusDataProvider("send-messages");

  useEffect(() => {
    if (!selectMode) {
      setSelectedRows({});
    }
  }, [selectMode, setSelectedRows]);

  const onRowSelectChange = (item) => {
    const newSelectedRows = { ...selectedRows };
    if (item.id in newSelectedRows) {
      delete newSelectedRows[item.id];
    } else {
      newSelectedRows[item.id] = item.id;
    }
    setSelectedRows(newSelectedRows);
  };

  const onMessageChange = (e) => {
    setMessage(e.target.value);
    e.target.style.height = "inherit";
    e.target.style.height = `${e.target.scrollHeight}px`;
    e.target.style.height = `${Math.min(e.target.scrollHeight, 160)}px`;
  };

  const onMessageSend = (message) => {
    setMessageInTransit(true);
    sendMessages({
      coach: messageCoach,
      clients: Object.values(selectedRows),
      message: message,
      close_threads: closeMessageThreads,
    });
  };

  useZeusDataStatusSubscriber("send-messages", (status) => {
    if (status === "updated") {
      setMessageInTransit(false);
      setMessage("");
      setMessageSent(true);
    }
  });

  return (
    <AppPageWithSheet>
      <Provider />
      <AllCoachesProvider />
      <AppTopOfSheet>
        <AppContent modifiers="padding margin-bottom">
          <AppHeading modifiers="center">Profiler</AppHeading>
          <AppTabsHeader>
            <AppTabsButton
              selected={!showArchived}
              onClick={() => setSelectedTab("client")}
            >
              Klienter
            </AppTabsButton>
            <AppTabsButton onClick={() => setSelectedTab("coach")}>
              Coacher
            </AppTabsButton>
            <AppTabsButton
              selected={showArchived}
              onClick={() => setSelectedTab("archive")}
            >
              Klientarkiv
            </AppTabsButton>
          </AppTabsHeader>

          <form onSubmit={doSearch} action="/">
            <AppSearchbar
              placeholder="Sök"
              inputmode="search"
              value={searchValue}
              debounce={0}
              onIonChange={(e) => setSearchValue(e.target.value)}
            />
          </form>

          <IonList>
            <AppTagFilter
              label="Taggar"
              provider="profile-tags"
              value={searchTags.field_profile_tags ?? ""}
              onChange={(value) =>
                setSearchTags((current) => ({
                  ...current,
                  field_profile_tags: value,
                }))
              }
            />
          </IonList>

          <div className="ion-padding-top ion-text-right">
            <IonButton
              onClick={doResetFilter}
              fill="outline"
              color="secondary"
              size="small"
            >
              Återställ
            </IonButton>
          </div>
        </AppContent>
      </AppTopOfSheet>
      <AppSheet>
        {selectMode && (
          <IonItem>
            <IonLabel slot="end">
              <span style={{ color: "black" }}>Välj alla</span>
            </IonLabel>
            <IonCheckbox
              slot="end"
              indeterminate={
                Object.values(selectedRows).filter((item) => item).length > 0 &&
                Object.values(selectedRows).filter((item) => item).length !==
                  profiles.length
              }
              checked={
                Object.values(selectedRows).filter((item) => item).length ===
                profiles.length
              }
              onClick={() => {
                if (
                  Object.values(selectedRows).filter((item) => item).length ===
                  profiles.length
                ) {
                  setSelectedRows({});
                } else {
                  setSelectedRows(
                    profiles.reduce((acc, cur) => {
                      acc[cur.id] = true;
                      return acc;
                    }, {})
                  );
                }
              }}
            ></IonCheckbox>
          </IonItem>
        )}
        <IonList class="light ion-no-padding">
          {isLoading && (
            <div className="ion-text-center">
              <IonSpinner color="dark" />
            </div>
          )}

          {profiles.map((item) => (
            <IonItem
              key={item.id}
              detail={selectMode ? false : undefined}
              routerLink={
                selectMode
                  ? undefined
                  : `/c/menu/manage/profile/client/${item.id}`
              }
            >
              {selectMode && (
                <IonCheckbox
                  slot="end"
                  checked={selectedRows[item.id] ? true : false}
                  onClick={() => onRowSelectChange(item)}
                ></IonCheckbox>
              )}
              <IonAvatar slot="start">
                <PrivateImage
                  defaultImage="assets/images/default-avatar.png"
                  auth="auth"
                  src={item.field_avatar?.image_style_uri?.medium}
                />
              </IonAvatar>
              <IonLabel>
                {item.field_first_name} {item.field_last_name}
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
        <BottomSpacer />
      </AppSheet>
      <IonModal isOpen={showSendMessage} backdropDismiss={false}>
        <IonHeader className="app-header">
          <IonToolbar>
            <IonTitle>Skicka meddelande</IonTitle>
            <IonButtons slot="start">
              <IonButton onClick={() => setShowSendMessage(false)}>
                <IonIcon icon={closeCircleOutline} slot="icon-only" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            <IonItem>
              <IonLabel>Från Coach</IonLabel>
              <IonSelect
                interface="popover"
                value={messageCoach}
                onIonChange={(e) => setMessageCoach(e.detail.value)}
              >
                {coaches?.map((profile) => (
                  <IonSelectOption key={profile.id} value={profile.id}>
                    {profile.field_first_name} {profile.field_last_name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem lines="none">
              <textarea
                style={{
                  border: 0,
                  width: "100%",
                  resize: "none",
                  focus: "none",
                }}
                placeholder="Meddelande"
                value={message}
                rows={1}
                onChange={onMessageChange}
              ></textarea>
            </IonItem>
          </IonList>
          <IonRow>
            <IonCol class="message_columns" size="6">
              <IonItem>
                <IonCheckbox
                  onClick={() => setCloseMessageThreads(!closeMessageThreads)}
                  checked={closeMessageThreads}
                  slot="start"
                  color="light"
                  style={{ "--border-color": "white" }}
                />
                <IonLabel>Stäng trådar</IonLabel>
              </IonItem>
            </IonCol>
            <IonCol class="message_columns" size="6">
              <IonButton
                clear
                color="primary"
                disabled={
                  message === "" || messageCoach === null || messageInTransit
                }
                onClick={(e) => onMessageSend(message)}
              >
                Skicka
              </IonButton>
            </IonCol>
          </IonRow>
        </IonContent>
        <IonLoading
          isOpen={messageInTransit}
          message={"Skickar..."}
          duration={30000}
          onDidDismiss={() => {
            setMessageInTransit(false);
          }}
        />
        <IonLoading
          isOpen={messageSent}
          message={"Skickat!"}
          duration={2000}
          onDidDismiss={() => setMessageSent(false)}
          spinner={""}
        />
      </IonModal>
      <zeus-data-provider id="send-messages" write-only={true}>
        <zeus-data-source-json
          url={`${getSetting("BACKEND")}/api/send-messages`}
          data-action="create"
        ></zeus-data-source-json>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </AppPageWithSheet>
  );
};

const ProfilesCoaches = ({ setSelectedTab }) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchString, setSearchString] = useState("");
  const { Provider, profiles, isLoading } = useProfileProvider(
    "coach",
    searchString
  );

  const doSearch = (e) => {
    e?.preventDefault();
    setSearchString(searchValue);
  };

  return (
    <AppPageWithSheet>
      <Provider />
      <AppTopOfSheet>
        <AppContent modifiers="padding margin-bottom">
          <AppHeading modifiers="center">Profiler</AppHeading>

          <AppTabsHeader>
            <AppTabsButton onClick={() => setSelectedTab("client")}>
              Klienter
            </AppTabsButton>
            <AppTabsButton selected>Coacher</AppTabsButton>
            <AppTabsButton onClick={() => setSelectedTab("archive")}>
              Klientarkiv
            </AppTabsButton>
          </AppTabsHeader>
          <form onSubmit={doSearch} action="/">
            <AppSearchbar
              placeholder="Sök"
              inputmode="search"
              value={searchValue}
              debounce={0}
              onIonChange={(e) => setSearchValue(e.target.value)}
            />
          </form>
        </AppContent>
      </AppTopOfSheet>
      <AppSheet>
        <IonList class="light ion-no-padding">
          {isLoading && (
            <div className="ion-text-center">
              <IonSpinner color="dark" />
            </div>
          )}
          {profiles
            .filter((x) => x.id !== "69e35154-bc98-41c4-8aa7-c2809464ecdd")
            .map((item) => (
              <IonItem
                routerLink={`/c/menu/manage/profile/coach/${item.id}`}
                key={item.id}
              >
                <IonAvatar slot="start">
                  <PrivateImage
                    defaultImage="assets/images/default-avatar.png"
                    auth="auth"
                    src={item.field_avatar?.image_style_uri?.medium}
                  />
                </IonAvatar>
                <IonLabel>
                  {item.field_first_name} {item.field_last_name}
                </IonLabel>
              </IonItem>
            ))}
        </IonList>
        <BottomSpacer />
      </AppSheet>
    </AppPageWithSheet>
  );
};

const Profiles = ({ defaultHref = "/c/menu" }) => {
  const [selectedTab, setSelectedTab] = useState("client");
  const [selectMode, setSelectMode] = useState(false);
  const [showSendMessage, setShowSendMessage] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});

  const showMessageButton =
    selectMode && Object.values(selectedRows).length > 0;

  return (
    <AppPageWithHideableHeader
      title="Profiler"
      backButton
      defaultHref={defaultHref}
      toolbarButtons={
        <>
          {!selectMode && (
            <IonButton routerLink={`/c/menu/manage/profile/add/${selectedTab}`}>
              <IonIcon slot="icon-only" icon={addCircleOutline} />
            </IonButton>
          )}
          {showMessageButton && (
            <IonButton onClick={() => setShowSendMessage(true)}>
              <IonIcon slot="icon-only" icon={chatbubblesOutline} />
            </IonButton>
          )}
          {selectedTab === "client" && (
            <IonButton onClick={() => setSelectMode(!selectMode)}>
              <IonIcon slot="icon-only" icon={selectIcon} />
            </IonButton>
          )}
        </>
      }
    >
      {selectedTab === "client" && (
        <ProfilesClients
          setSelectedTab={setSelectedTab}
          selectMode={selectMode}
          showSendMessage={showSendMessage}
          setShowSendMessage={setShowSendMessage}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      )}
      {selectedTab === "coach" && (
        <ProfilesCoaches setSelectedTab={setSelectedTab} />
      )}
      {selectedTab === "archive" && (
        <ProfilesClients
          setSelectedTab={setSelectedTab}
          showArchived={true}
          selectMode={selectMode}
          showSendMessage={showSendMessage}
          setShowSendMessage={setShowSendMessage}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      )}
    </AppPageWithHideableHeader>
  );
};

export default Profiles;

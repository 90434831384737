import { processModifiers } from "../util";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-notes-with-title/pepalexa-notes-with-title.scss";

const AppNotesWithTitle = ({ modifiers, title, notes, ...props }) => (
  <div
    {...props}
    className={processModifiers("pepalexa-notes-with-title", modifiers)}
  >
    <span className="pepalexa-notes-with-title__title">{title}</span>
    <span className="pepalexa-notes-with-title__notes">{notes}</span>
  </div>
);

export default AppNotesWithTitle;

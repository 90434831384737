import dayjs from "dayjs";

/**
 * Given a list of strings or a class list. Normalize modifiers using modifierBase.
 *
 * @param {string} modifierBase  Base class for component.
 * @returns A string with space separated class names.
 */
export function processModifiers(modifierBase, ...modifiers) {
  if (!modifiers) {
    return modifierBase;
  }

  const unprocessedModifiers = modifiers.filter(Boolean).reduce((acc, item) => {
    const p = Array.isArray(item) ? item : item.split(" ");
    return [...acc, ...p];
  }, []);

  const modifierArray = unprocessedModifiers
    .filter(Boolean)
    .map((mod) => (mod.startsWith(modifierBase) ? mod : `${modifierBase}--${mod}`));
  return `${modifierBase} ${modifierArray.join(" ")}`.trim();
}

/**
 * Selects a placeholder image.
 *
 * Returns a path to a placeholder image based on the id given. The id can be
 * any integer and will always return the same result for the same id.
 *
 * @param {number} id Placeholder id.
 * @returns {string} Path to selected default image.
 */
export function fileSelectPlaceholderImage(id) {
  const images = [
    "assets/default-images/d1.jpg",
    "assets/default-images/d2.jpg",
    "assets/default-images/d3.jpg",
    "assets/default-images/d4.jpg",
  ];
  return images[id % images.length];
}

export function hasModifier(search, modifiers) {
  modifiers = Array.isArray(modifiers) ? modifiers : modifiers.split(" ");
  return modifiers.some((item) => item.endsWith(`--${search}`)) ?? false;
}

/**
 * Check if user has one of the roles given in arguments.
 *
 * @param {*} user User object.
 * @param {string[]} roles List of roles to match.
 * @returns {boolean}
 */
export function hasRole(user, roles) {
  if (!Array.isArray(roles)) {
    return true;
  }
  const role = (user?.mail ?? "").indexOf("coach") !== -1 ? "coach" : "client";
  return roles.includes(role);
}

export function formatMediumDate(dateString) {
  return dayjs(dateString).format("D MMM YYYY");
}

export function formatMediumDateTime(dateString) {
  return dayjs(dateString).format("D MMM YYYY - HH:mm");
}

export function formatLongDate(dateString) {
  return dayjs(dateString).format("dddd D MMMM YYYY");
}

export function formatLongDateTime(dateString) {
  return dayjs(dateString).format("dddd D MMMM YYYY - HH:mm");
}

export function getDate(dateString) {
  const date = new Date(dateString);
  const convertedDate = date.toLocaleString("sv-SE", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  return `${convertedDate} - ${date.getHours()}:${date.getMinutes()}`;
}

export function getLongDate(dateString) {
  const date = new Date(dateString);
  const convertedDate = date.toLocaleString("sv-SE", {
    weekday: "long",
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
  const arr = convertedDate.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const realConvertedDate = arr.join(" ");

  return `${realConvertedDate}`;
}

export function classList() {
  let classes = [];
  for (const item of arguments) {
    if (typeof item === "string") {
      classes = [...classes, ...item.trim().split(" ")];
    } else if (Array.isArray(item)) {
      classes = [...classes, ...item];
    }
  }
  return classes.filter((item) => Boolean(item)).join(" ");
}

/**
 * Generates random alphanumeric string of given length.
 * @param {number} length Number of characters in string.
 * @returns {string}
 */
export function makeid(length) {
  let result = "";
  const chars = "abcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}

/**
 *
 * @param {string} file
 * @param {string} style
 * @returns {string}
 */
export function privateFileUrl(file, style = "medium") {
  return `https://delicate-butterfly-1457.a.udev.se${file}`;
}

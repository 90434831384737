const defaultSettings = {
  APP_NAME: "Lofsangruppen",
  BACKEND: process.env.REACT_APP_SETTINGS_BACKEND ?? "http://localhost:3000",
  DRUPAL_SERVER: "https://delicate-butterfly-1457.a.udev.se",
  COMMON_COLLECTION: "69e35154-bc98-41c4-8aa7-c2809464ecdd",
  ONESIGNAL_APP: "e82fdca8-c5f7-43a4-a1a6-d1442f576e9c",
};

let settings = {};

export async function loadSettings() {
  try {
    const data = JSON.parse(document.getElementById("root")?.dataset?.settings);
    if (data) {
      settings = {
        ...defaultSettings,
        ...data,
      };
    } else {
      throw new Error("Could not parse settings.");
    }
  } catch (e) {
    console.warn("No settings found, using defaults.");
    settings = { ...defaultSettings };
  }
}

export function getSetting(name) {
  return settings?.[name];
}

export const pageConfig = {
  workout: {
    listTitle: "Träningsprogram",
    editTitle: "Redigera träningsprogram",
    copyTitle: "Kopiera träningsprogram",
    addTitle: "Skapa träningsprogram",
    fields: [
      {
        field: "title",
        label: "Titel",
        type: "text",
        required: true,
      },
      {
        field: "field_workout_tags",
        label: "Taggar",
        type: "term",
        vocabulary: "workout_tags",
      },
      {
        field: "field_workout_feedback_text",
        label: "Önskad feedback",
        type: "text",
      },
      { field: "body", label: "Beskrivning", type: "body" },
      {
        field: "field_workout_blocks",
        label: "Övningar",
        type: "workout-blocks",
      },
    ],
    searchTags: [
      {
        field: "field_workout_tags",
        label: "Taggar",
        provider: "workout-tags",
      },
    ],
  },

  exercise: {
    listTitle: "Övningar",
    editTitle: "Redigera övning",
    copyTitle: "Kopiera övning",
    addTitle: "Skapa övning",
    fields: [
      {
        field: "title",
        label: "Administrativ titel",
        type: "text",
        required: true,
      },
      { field: "field_public_title", label: "Publik titel", type: "text" },
      {
        field: "field_exercise_tags",
        label: "Taggar",
        type: "term",
        vocabulary: "exercise_tags",
      },
      {
        field: "field_exercise_tools",
        label: "Redskap",
        type: "term",
        vocabulary: "exercise_tools",
      },
      { field: "body", label: "Beskrivning", type: "body" },
      { field: "field_media", type: "media" },
    ],
    searchTags: [
      {
        field: "field_exercise_tags",
        label: "Taggar",
        provider: "exercise-tags",
      },
      {
        field: "field_exercise_tools",
        label: "Redskap",
        provider: "exercise-tools",
      },
    ],
  },
  diet: {
    listTitle: "Kostråd",
    editTitle: "Redigera kostråd",
    copyTitle: "Kopiera kostråd",
    addTitle: "Skapa kostråd",
    fields: [
      {
        field: "title",
        label: "Administrativ titel",
        type: "text",
        required: true,
      },
      { field: "field_public_title", label: "Publik titel", type: "text" },
      {
        field: "field_diet_tags",
        label: "Taggar",
        type: "term",
        vocabulary: "diet_tags",
      },
      { field: "body", label: "Beskrivning", type: "body" },
      { field: "field_media", type: "media" },
    ],
    searchTags: [
      {
        field: "field_diet_tags",
        label: "Taggar",
        provider: "diet-tags",
      },
    ],
  },
};

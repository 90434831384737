import { useMemo } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

export function useClientGroupContentProvider(clientId, type, settings) {
  const include = settings?.include ?? "entity_id";
  const sort = settings?.sort ?? "-created";
  const providerId = `client-${type}-provider-${clientId}`;
  const url = `${getSetting(
    "BACKEND"
  )}/api/v3/group_content/client-group_node-${type}?filter[gid.id]=${clientId}&include=${include}&sort=${sort}`;
  const { data, isLoading } = useZeusDataProvider(providerId, []);

  const Provider = useMemo(() => {
    const Component = () => {
      if (!clientId) {
        return null;
      }
      return (
        <zeus-data-provider id={providerId} listen-tags={type}>
          <zeus-data-source-jsonapi url={url}></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      );
    };
    return Component;
  }, [clientId, providerId, type, url]);

  return {
    Provider,
    entities: data,
    isLoading,
  };
}

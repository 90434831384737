import { useParams } from "react-router";
import { IonItem, IonList, IonLabel, IonSpinner } from "@ionic/react";

import { useClientContentProvider } from "../../providers/client-content-provider";
import { formatMediumDateTime } from "../../util";
import {
  AppPageWithSheet,
  AppSheet,
  AppTopOfSheet,
} from "../../compositions/AppPageWithSheet";
import AppPageWithHideableHeader from "../../compositions/AppPageWithHideableHeader";
import AppContent from "../../components/AppContent";
import AppHeading from "../../components/AppHeading";
import BottomSpacer from "../../components/BottomSpacer";

const ClientWorkoutLog = () => {
  const { id } = useParams();
  const { Provider, entities, isLoading } = useClientContentProvider(
    id,
    "workout_log",
    { include: "entity_id.field_workout_log_workout" }
  );

  return (
    <AppPageWithHideableHeader
      title="Träningslogg"
      defaultHref={`/c/menu/manage/client/${id}`}
      backButton
    >
      <AppPageWithSheet>
        <AppTopOfSheet>
          <AppContent modifiers="pepalexa-content--padding pepalexa-content--margin-bottom">
            <AppHeading modifiers="center">Träningslogg</AppHeading>
          </AppContent>
        </AppTopOfSheet>
        <AppSheet>
          <IonList className="light ion-no-padding">
            {isLoading && (
              <div className="ion-text-center">
                <IonSpinner color="dark" />
              </div>
            )}
            {entities.map((item) => (
              <IonItem
                key={item.id}
                routerLink={`/c/menu/manage/client/${id}/workout-log/${item.id}`}
              >
                <IonLabel>
                  <h2>{item.field_workout_log_workout?.title ?? item.title}</h2>
                  <p>{formatMediumDateTime(item.created)}</p>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
          <BottomSpacer />
        </AppSheet>
      </AppPageWithSheet>
      <Provider />
    </AppPageWithHideableHeader>
  );
};

export default ClientWorkoutLog;

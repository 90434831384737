import { IonCol, IonGrid, IonRow } from "@ionic/react";

import "@understandit/lofsangruppen-storybook-22173948/components/pepalexa-workout-rating/pepalexa-workout-rating.scss";

const AppWorkoutRating = (props) => {
  return (
    <div className={`pepalexa-workout-rating ${props.modifiers?.join(" ")}`}>
      <IonGrid>
        <IonRow className="ion-align-items-center">
          <IonCol size="4">
            <div className="pepalexa-workout-rating--container">
              {/* <img
                className="pepalexa-workout-rating--icon"
                src={props.icon_one}
                alt=""
              /> */}
              <span className="pepalexa-workout-rating--label">
                {props.label_one}
              </span>
              <span className="pepalexa-workout-rating--amount">
                {props.amount_one}
              </span>
            </div>
          </IonCol>
          <IonCol size="4">
            <div className="pepalexa-workout-rating--container">
              {/* <img
                className="pepalexa-workout-rating--icon"
                src={props.icon_two}
                alt=""
              /> */}
              <span className="pepalexa-workout-rating--label">
                {props.label_two}
              </span>
              <span className="pepalexa-workout-rating--amount">
                {props.amount_two}
              </span>
            </div>
          </IonCol>
          <IonCol size="4">
            <div className="pepalexa-workout-rating--container">
              {/* <img
                className="pepalexa-workout-rating--icon"
                src={props.icon_three}
                alt=""
              /> */}
              <span className="pepalexa-workout-rating--label">
                {props.label_three}
              </span>
              <span className="pepalexa-workout-rating--amount">
                {props.amount_three}
              </span>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default AppWorkoutRating;

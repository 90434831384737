import { Link } from "react-router-dom";

import AppAvatar from "../components/AppAvatar";
import AppCard from "../components/AppCard";
import AppFlag from "../components/AppFlag";

import "@understandit/lofsangruppen-storybook-22173948/compositions/gallery-card/gallery-card.scss";

const AppGalleryCard = (props) => {
  return (
    <Link to={props.routerLink}>
      <AppCard
        modifiers={props.modifiers}
        media={props.media}
        topLeft={props.avatar ? <AppAvatar src={props.avatar} /> : <></>}
        topRight={props.flag ? <AppFlag>{props.flag}</AppFlag> : <></>}
      >
        {props.children}
      </AppCard>
    </Link>
  );
};

export default AppGalleryCard;
